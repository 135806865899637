define("comunidades-nafin/components/human-talent-components/charts/grouped-sorted-columns-chart", ["exports", "comunidades-nafin/components/human-talent-components/h-t-chart"], function (_exports, _hTChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTChart.default.extend({
    getTypeChart: function getTypeChart() {
      return am4charts.XYChart;
    },
    createChart: function createChart() {
      this.chart.paddingBottom = 50;
      this.chart.cursor = new am4charts.XYCursor();
      this.chart.scrollbarX = new am4core.Scrollbar();
      var data = this.data; // will use this to store colors of the same items

      console.log("Colors ", this.colors);
      var colors = this.colors;
      var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 60;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataItems.template.text = "{realName}";
      categoryAxis.adapter.add("tooltipText", function (tooltipText, target) {
        return categoryAxis.tooltipDataItem.dataContext.realName;
      });
      var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.min = 0; // single column series for all data

      var columnSeries = this.chart.series.push(new am4charts.ColumnSeries());
      columnSeries.columns.template.width = am4core.percent(80);
      columnSeries.tooltipText = "{provider}: {realName}, {valueY}";
      columnSeries.dataFields.categoryX = "category";
      columnSeries.dataFields.valueY = "value"; // second value axis for quantity

      var valueAxis2 = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.renderer.opposite = true;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.tooltip.disabled = true; // fill adapter, here we save color value to colors object so that each time the item has the same name, the same color is used

      var temporalChart = this.chart;
      columnSeries.columns.template.adapter.add("fill", function (fill, target) {
        var name = target.dataItem.dataContext.realName;

        if (!colors[name]) {
          colors[name] = temporalChart.colors.next();
        }

        target.stroke = colors[name];
        return colors[name];
      });
      var rangeTemplate = categoryAxis.axisRanges.template;
      rangeTemplate.tick.disabled = false;
      rangeTemplate.tick.location = 0;
      rangeTemplate.tick.strokeOpacity = 0.6;
      rangeTemplate.tick.length = 60;
      rangeTemplate.grid.strokeOpacity = 0.5;
      rangeTemplate.label.tooltip = new am4core.Tooltip();
      rangeTemplate.label.tooltip.dy = -10;
      rangeTemplate.label.cloneTooltip = false; ///// DATA

      var chartData = [];
      var lineSeriesData = []; // process data ant prepare it for the chart

      for (var providerName in data) {
        var providerData = data[providerName]; // add data of one provider to temp array

        var tempArray = [];
        var count = 0; // add items

        for (var itemName in providerData) {
          if (itemName != "quantity") {
            count++; // we generate unique category for each column (providerName + "_" + itemName) and store realName

            tempArray.push({
              category: providerName + "_" + itemName,
              realName: itemName,
              value: providerData[itemName],
              provider: providerName
            });
          }
        } // sort temp array


        tempArray.sort(function (a, b) {
          if (a.value > b.value) {
            return 1;
          } else if (a.value < b.value) {
            return -1;
          } else {
            return 0;
          }
        }); // add quantity and count to middle data item (line series uses it)

        var lineSeriesDataIndex = Math.floor(count / 2);
        tempArray[lineSeriesDataIndex].quantity = providerData.quantity;
        tempArray[lineSeriesDataIndex].count = count; // push to the final data

        am4core.array.each(tempArray, function (item) {
          chartData.push(item);
        }); // create range (the additional label at the bottom)

        var range = categoryAxis.axisRanges.create();
        range.category = tempArray[0].category;
        range.endCategory = tempArray[tempArray.length - 1].category;
        range.label.text = tempArray[0].provider;
        range.label.dy = 30;
        range.label.truncate = true;
        range.label.fontWeight = "bold";
        range.label.tooltipText = tempArray[0].provider;
        range.label.adapter.add("maxWidth", function (maxWidth, target) {
          var range = target.dataItem;
          var startPosition = categoryAxis.categoryToPosition(range.category, 0);
          var endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
          var startX = categoryAxis.positionToCoordinate(startPosition);
          var endX = categoryAxis.positionToCoordinate(endPosition);
          return endX - startX;
        });
      }

      this.chart.data = chartData; // last tick

      var range = categoryAxis.axisRanges.create();
      range.category = this.chart.data[this.chart.data.length - 1].category;
      range.label.disabled = true;
      range.tick.location = 1;
      range.grid.location = 1;
    }
  });

  _exports.default = _default;
});