define("comunidades-nafin/templates/components/side-menu-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RYMpi0yk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/side-menu-admin.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"ul\"],[10,\"class\",\"no_list_style panel_style no_padding\"],[8],[0,\"\\n\\t\"],[6,\"li\"],[8],[0,\"Categoría del reporte \"],[1,[26,\"fa-icon\",[\"angle-up\"],[[\"prefix\",\"class\"],[\"fas\",\"red_angle\"]]],false],[0,\" \"],[9],[0,\"\\n\\t\"],[6,\"br\"],[8],[9],[0,\"\\n\\t\"],[6,\"li\"],[8],[0,\" \"],[6,\"button\"],[10,\"class\",\"btn full_width btn_side_menu text_left\"],[10,\"type\",\"button\"],[8],[0,\"Admin report\"],[9],[0,\" \"],[9],[0,\"\\n\\t\"],[6,\"li\"],[8],[0,\" \"],[6,\"button\"],[10,\"class\",\"btn btn_side_menu full_width text_left\"],[10,\"type\",\"button\"],[8],[0,\"Personal blog theme\"],[9],[0,\" \"],[9],[0,\"\\n\\t\"],[6,\"li\"],[8],[0,\" \"],[6,\"button\"],[10,\"class\",\"btn btn_side_menu full_width text_left\"],[10,\"type\",\"button\"],[8],[0,\"Marketing theme\"],[9],[0,\" \"],[9],[0,\"\\n\\t\"],[6,\"li\"],[8],[0,\" \"],[6,\"button\"],[10,\"class\",\"btn btn_side_menu full_width text_left\"],[10,\"type\",\"button\"],[8],[0,\"Personal blog theme\"],[9],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/side-menu-admin.hbs"
    }
  });

  _exports.default = _default;
});