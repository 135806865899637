define("comunidades-nafin/routes/faqs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * setupController -
     * @param controller
     */
    setupController: function setupController(controller) {
      controller.set('isAdmin', this.session.get('isAdmin'));
      controller.set('theme', window.constant.THEME);
    }
  });

  _exports.default = _default;
});