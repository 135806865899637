define("comunidades-nafin/templates/notificacion/envio-general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mOiDWaEd",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/cursos.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/notificacion/general.css\"],[8],[9],[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[14,\"loading\",[]],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[1,[20,\"notificacion/general/historico-envio\"],false],[0,\"\\n    \"],[1,[20,\"notificacion/general/form-envio\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/notificacion/envio-general.hbs"
    }
  });

  _exports.default = _default;
});