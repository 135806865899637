define("comunidades-nafin/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend({
    init: function init() {
      //let self = this._super(...arguments);
      var self = this;
      $.ajax({
        url: constant.APIURL + 'usuario/datospersonales/',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        async: false,
        success: function success(r) {
          var data = JSON.parse(r);
          var sesionLocal = {};

          if (!data.fatal) {
            self.session.set('isAuthenticated', true);

            if (data.foto.indexOf('http') != 0) {
              data.foto = constant.BASEURL + data.foto;
            }

            self.session.set('userData', data); // Almacenamos en localStorage solo si es app

            var existUserData = localStorage.getItem("user");

            if (existUserData != null) {
              var userData = JSON.parse(existUserData);

              if (userData.usuario_k != data.usuario_k) {
                var temporalUser = localStorage.getItem("userlogin");
                var temporalPwd = localStorage.getItem("pwdlogin");
                localStorage.clear();

                if (temporalPwd != null && temporalUser != null) {
                  localStorage.setItem("userlogin", temporalUser);
                  localStorage.setItem("pwdlogin", temporalPwd);
                }
              }
            }

            if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
              sesionLocal.usuario = data.login;
              sesionLocal.usuario_k = data.usuario_k;
              sesionLocal.nombre = data.nombre;
              sesionLocal.email = data.email;
              localStorage.setItem("user", JSON.stringify(sesionLocal));
            }

            if (data.rol_k != 3 && data.rol_k != 5) {
              self.session.set('isAdmin', true);
            } else if (data.rol_k == 7) {
              self.session.set('isTutor', true);
            }
          } else {
            var _sesionLocal = localStorage.getItem("user");

            if (_sesionLocal != null) {} else {
              self.iniciarSesion();
            }
          }
        },
        error: function error(e) {
          self.iniciarSesion();
          console.log(e);
        }
      });

      if (this.socket != null && this.socket != undefined) {
        if (!this.socket.socket.enabled && window.constant.HABILITAR_SOCKET) {
          this.socket.socket = io.connect(constant.SOCKET, {
            "forceNew": true
          });
          this.socket.socket.connect();
          this.socket.socket.nuevo = true;
          this.socket.socket.chat_room = '';
          this.socket.socket.community_room = '';
        }

        this.socket.chat = {};

        if (!this.socket.chat.enabled) {
          this.socket.chat = io.connect(constant.SOCKET, {
            "forceNew": true
          });
          this.socket.chat.connect();
          this.socket.chat.nuevo = true;
          this.socket.chat.chat_room = '';
          this.socket.chat.community_room = '';
        }
      }
    },
    iniciarSesion: function iniciarSesion() {
      if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
        setTimeout(function () {
          var secciones = window.location.href.split("#");

          if (!window.constant.APPURLSOFFLINE.includes(secciones[1]) || secciones.length == 1) {
            $("#modal_login").modal('show');
            $('#loading').hide();
            $(".modal-backdrop.show").css("opacity", "1");
            $(".modal-backdrop").addClass("backgroundLogin");
            $(".modal").addClass("backgroundLogin");
            $("#logoLogin").attr("src", $("#logoHeader").attr("src"));
          }
        }, 5000);
      } else {
        $('#modal-avisos').modal('show');
        $('.modal-backdrop').css({
          'opacity': '1'
        });
        $(".modal").addClass("backgroundLogin");
        $('#modal-avisos .modal-body').html('No ha iniciado sesión o esta ya ha finalizado, deberá iniciar sesión desde el entorno público en el menú "Iniciar Sesión"');
        $("#modal-avisos").on("hidden.bs.modal", function () {
          document.location.href = constant.LANDINGURL;
        });
        setTimeout(function () {
          document.location.href = constant.LANDINGURL;
        }, 2000);
      }
    }
  });

  _exports.default = _default;
});