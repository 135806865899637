define("comunidades-nafin/templates/examples", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "thYr7UQT",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,\"examples-components/ember-example\"],false],[0,\"\\n\"],[1,[20,\"examples-components/react-example\"],false],[0,\"\\n\"],[1,[20,\"examples-components/react-imported-example\"],false],[0,\"\\n\\n\"],[6,\"br\"],[8],[9],[6,\"br\"],[8],[9],[0,\"\\n\"],[6,\"hr\"],[8],[9],[0,\"\\n\"],[6,\"br\"],[8],[9],[6,\"br\"],[8],[9],[0,\"\\n\\n\"],[1,[20,\"examples-components/file-uploader\"],false],[0,\"\\n\\n\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/examples.hbs"
    }
  });

  _exports.default = _default;
});