define("comunidades-nafin/components/nom-035-stps/reportes/reports/ats-detalle-de-trabajadores-con-ats", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Detalle de trabajadores con ATS',
    clase: 'nom035stps/reporteadministrador',
    metodo: 'atsdetalletrabajadoresconats',
    reporte: 'nom035stps/export/detalletrabajadoresconats',
    headers: [{
      dataIndex: 'datos_trabajador',
      title: 'Datos del trabajador'
    }, {
      dataIndex: 'centro_trabajo',
      title: 'Centro de trabajo'
    }, {
      dataIndex: 'acontecimientos',
      title: 'Acontecimientos'
    }]
  });

  _exports.default = _default;
});