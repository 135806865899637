define("comunidades-nafin/initializers/in-app-livereload", ["exports", "comunidades-nafin/config/environment", "ember-cli-cordova/initializers/in-app-livereload"], function (_exports, _environment, _inAppLivereload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;

  /* globals cordova */
  var inAppReload = _inAppLivereload.default.initialize;

  var initialize = function initialize(app) {
    if (typeof cordova === 'undefined' || _environment.default.environment !== 'development' || _environment.default.cordova && (!_environment.default.cordova.liveReload || !_environment.default.cordova.liveReload.enabled)) {
      return;
    }

    return inAppReload(app, _environment.default);
  };

  _exports.initialize = initialize;
  var _default = {
    name: 'cordova:in-app-livereload',
    initialize: initialize
  };
  _exports.default = _default;
});