define("comunidades-nafin/templates/components/course-survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AEnoEYx/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"row margin_ud_10px\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-12 col-xl\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12 col-xl margin_top_10\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12 col-sm-6 col-md-6 col-lg-2\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[6,\"img\"],[10,\"class\",\"img_circle\"],[10,\"src\",\"https://dummyimage.com/400x400/000/fff&text=Icono\"],[10,\"alt\",\"Card image\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"ccol-12 col-sm-6 col-md-6 col-lg-6 margin_ud_10px\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[6,\"ul\"],[10,\"class\",\"no_padding none_list_style\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[6,\"li\"],[8],[6,\"h2\"],[8],[0,\"Encuesta de satisfacción\"],[9],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[6,\"li\"],[8],[0,\"Encuestas - Encuesta\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[6,\"li\"],[8],[1,[26,\"fa-icon\",[\"user\"],[[\"size\",\"prefix\"],[1,\"far\"]]],false],[0,\" En espera\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[6,\"li\"],[8],[1,[26,\"fa-icon\",[\"lock\"],[[\"size\",\"prefix\"],[\"1\",\"fas\"]]],false],[0,\" El desbloqueo de este recurso depende de: 'recurso' \"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12 margin_ud_10px\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[6,\"hr\"],[10,\"class\",\"hr_divider_line\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/course-survey.hbs"
    }
  });

  _exports.default = _default;
});