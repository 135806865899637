define("comunidades-nafin/templates/catalogo-evaluaciones-especifico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "frubnudJ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/catalogo-cursos.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\"],[6,\"center\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"h2\"],[8],[0,\"Catálogo evaluaciones\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"button\"],[10,\"class\",\"btn btn-light\"],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"goBack\"]],[8],[1,[26,\"fa-icon\",[\"angle-left\"],[[\"prefix\"],[\"fas\"]]],false],[9],[0,\" Regresar\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-8\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"p\"],[8],[0,\"Preguntas de la evaluación - 20 pasos para iniciar o mejorar tu restaurante\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-4 text_right\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"button\"],[10,\"class\",\"btn btn_success_nafin\"],[10,\"type\",\"button\"],[8],[0,\"Agregar pregunta\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[6,\"br\"],[8],[9],[0,\"\\n\\t\"],[1,[20,\"table-preguntas\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/catalogo-evaluaciones-especifico.hbs"
    }
  });

  _exports.default = _default;
});