define("comunidades-nafin/components/human-talent-components/charts/clustered-column-chart", ["exports", "comunidades-nafin/components/human-talent-components/h-t-chart"], function (_exports, _hTChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTChart.default.extend({
    pieSeries: null,

    /**
     *
     */
    getTypeChart: function getTypeChart() {
      return am4charts.XYChart;
    },

    /**
     * setupChart -
     *
     *
     */
    setupChart: function setupChart() {
      this._super.apply(this, arguments);

      this.setSeries();
    },

    /**
     * setData - Función utilitaria
     *
     *
     *
     */
    parseData: function parseData() {
      this.data;
    },
    setData: function setData() {
      var chartData = [];
      am4core.array.each(this.data, function (item) {
        chartData.push(item);
      });
      this.chart.data = chartData;
    },

    /**
     *
     */
    setSeries: function setSeries() {
      var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.dataItems.template.text = "{category}";
      categoryAxis.renderer.grid.template.location = 1;
      var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = 'Trabajadores reportados'; // will use this to store colors of the same items

      var colors = {};
      var self = this; // single column series for all data

      var columnSeriesHombres = this.chart.series.push(new am4charts.ColumnSeries());
      columnSeriesHombres.dataFields.categoryX = "category";
      columnSeriesHombres.dataFields.valueY = "hombres";
      columnSeriesHombres.name = "Hombres";
      columnSeriesHombres.columns.template.tooltipText = "{hombres} ATS reportados";
      columnSeriesHombres.columns.template.tooltipY = 0;
      columnSeriesHombres.columns.template.strokeOpacity = 0;
      var columnSeriesMujeres = this.chart.series.push(new am4charts.ColumnSeries());
      columnSeriesMujeres.dataFields.categoryX = "category";
      columnSeriesMujeres.dataFields.valueY = "mujeres";
      columnSeriesMujeres.name = "Mujeres";
      columnSeriesMujeres.columns.template.tooltipText = "{mujeres} ATS reportados";
      columnSeriesMujeres.columns.template.tooltipY = 0;
      columnSeriesMujeres.columns.template.strokeOpacity = 0; // Add legend

      this.chart.legend = new am4charts.Legend();
    }
  });

  _exports.default = _default;
});