define("comunidades-nafin/components/admin-components/administracion-cursos-objetos-aprendizaje", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
     * didReceiveAttrs - Runs after init, and it also runs on subsequent re-renders, which is useful for logic that is the same on all renders.
     *
     *
     * Se sobe-escribe el metodo didReceiveAttrs, para implementa recepción y guardado de propiedades enviadas al instanciar el compomnente. y 
     * ejecutar metodos de cargado de Store.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('storeCurso', this.storeCurso);
      this.loadStoreObjetosAprendizaje();
      this.bindScopeToParent(this);
    },

    /*
     * loadStoreObjetosAprendizaje - Carga/Recarga en su totalidad el Store de objetos de aprencdizaje.
     *
     *
     * Carga/Recarga en su totalidad el Store de objetos de aprencdizaje.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    loadStoreObjetosAprendizaje: function loadStoreObjetosAprendizaje(callback) {
      var storeCurso = this.get('storeCurso') ? this.get('storeCurso') : this.storeCurso;
      var data_string = 'params={"propietario_k":"' + storeCurso.ra_k + '","tabla_propietario":"lms_ra"}';

      var _self = this;

      Ember.$.ajax({
        url: constant.APIURL + 'lms/recursocontroller/listar',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: data_string,
        processData: false,
        success: function success(response_text) {
          var data = JSON.parse(response_text);

          if (data.data.length == 0) {
            _self.set('hasLerningObjects', false);

            _self.set('storeObjetosAprendizaje', []);
          } else {
            var objectos_aprendizaje = data.data;

            _self.set('hasLerningObjects', true);

            _self.set('storeObjetosAprendizaje', objectos_aprendizaje);

            console.log(objectos_aprendizaje);

            if (callback) {
              callback();
            }
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('initialCurseSelection', '');
      this.set('initialCertSelection', '');
      this.set('cursos', []);
    },
    didRender: function didRender() {
      var self = this;

      if (self.storeObjetosAprendizaje) {
        Ember.$('.dependencias').each(function () {
          var dependencia = Ember.$(this).text().trim();
          var elem = Ember.$(this);
          self.storeObjetosAprendizaje.forEach(function (obj) {
            console.log(obj);

            if (obj.propietario_recurso_k == dependencia) {
              Ember.$(elem).text(obj.nombre);
              console.log(Ember.$(elem).text(obj.nombre));
            }
          });
        });
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.$('#objeto-ponderacion').bind('keypress', function (e) {
        var k = e.which;
        var ok = k >= 48 && k <= 57;

        if (!ok) {
          e.preventDefault();
        }
      });
      Ember.$('#objeto-ponderacion').keyup(function () {
        if (parseInt(Ember.$(this).val()) > 100) {
          Ember.$(this).val('');
        }
      });
      Ember.$('#objeto-minimo').bind('keypress', function (e) {
        var k = e.which;
        var ok = k >= 48 && k <= 57;

        if (!ok) {
          e.preventDefault();
        }
      });
      Ember.$('#objeto-minimo').keyup(function () {
        if (parseInt(Ember.$(this).val()) > 10) {
          Ember.$(this).val('');
        }
      });
      Ember.$('#objeto-intentos').bind('keypress', function (e) {
        var k = e.which;
        var ok = k >= 48 && k <= 57;

        if (!ok) {
          e.preventDefault();
        }
      });
      this.set("modalFormUpdate", false);
    },
    cleanModal: function cleanModal() {
      Ember.$('#modalFormNewObject .modal-body label[for="objeto-id"]').text('');
      Ember.$('#modalFormNewObject .modal-body input,#modalFormNewObject .modal-body textarea').val('');
      Ember.$('#modalFormNewObject .modal-body select').val('');
      Ember.$('#modalFormNewObject').data('i', '');
      this.set('cursos', []);
      this.set('certificados', []);
      Ember.$('#modalFormNewObject').modal('hide');
      this.set('initialCertSelection', {});
      this.set('initialCurseSelection', {});
    },
    updateModal: function updateModal(kind, cursos) {
      if (kind == 'scorm') {
        Ember.$('#objeto-diagnostico').hide();
        Ember.$('#modalFormNewObject').data('kind', 'scorm');
        Ember.$('#modalFormNewObject .modal-body label[for="objeto-id"]').text('Curso');
      } else if (kind == 'evaluacion') {
        Ember.$('#objeto-diagnostico').show();
        Ember.$('#modalFormNewObject').data('kind', 'evaluacion');
        Ember.$('#modalFormNewObject .modal-body label[for="objeto-id"]').text('Evaluación');
      } else if (kind == 'encuesta') {
        Ember.$('#objeto-diagnostico').hide();
        Ember.$('#modalFormNewObject').data('kind', 'encuesta');
        Ember.$('#modalFormNewObject .modal-body label[for="objeto-id"]').text('Encuesta');
      }

      Ember.$('#modalFormNewObject').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    refrescaryguardarorden: function refrescaryguardarorden() {
      var self = this;
      self.loadStoreObjetosAprendizaje(function () {
        var serializeddata = [];
        self.get('storeObjetosAprendizaje').forEach(function (item, idx) {
          serializeddata.push({
            'propietario_recurso_k': item.propietario_recurso_k,
            'orden': idx + 1
          });
        });
        Ember.$.ajax({
          url: constant.APIURL + 'lms/recursocontroller/guardaorden',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          data: 'store=' + JSON.stringify(serializeddata),
          success: function success(r) {
            self.cleanModal();
            Ember.$('#modal-avisos .modal-header').text('Operación exitosa');
            Ember.$('#modal-avisos .modal-body').text('Se realizó correctamente la operación.');
            Ember.$('#modal-avisos').modal('show');
          }
        });
      });
    },
    actions: {
      seleccionCertificado: function seleccionCertificado(selection) {
        Ember.$('#objeto-certificado').data('certificado', selection.certificado_k);
      },
      seleccionCurso: function seleccionCurso(selection) {
        console.log(selection);
        Ember.$('#objeto-nombre').val(selection.nombre);
        Ember.$('#objeto-descripcion').val(selection.nombre);
        Ember.$('#objeto-id').data('id', selection.curso_k);
      },
      showObject: function showObject(objeto) {
        console.log(objeto);
        var kind = '';
        var llave = '';
        var ra_k = this.get('storeCurso').ra_k;
        var self = this;

        if (objeto.tabla_recurso == 'lms_cursos_online') {
          kind = 'scorm';
          llave = 'curso_k';
        } else if (objeto.tabla_recurso == 'lms_encuestas') {
          kind = 'encuesta';
          llave = 'llave_recurso_k';
        } else if (objeto.tabla_recurso == 'lms_evaluaciones') {
          kind = 'evaluacion';
          llave = 'llave_recurso_k';
        }

        this.send('showNewObject', kind, ra_k, function () {
          //searchable-select
          var cursos = self.get('cursos');
          var curso = {};
          cursos.forEach(function (item) {
            if (item[llave] == objeto['llave_recurso_k']) {
              curso = item;
            }
          });

          if (Object.keys(curso).length > 0) {
            console.log('Curso:', curso);
            self.set('initialCurseSelection', curso);
          } else {
            self.set('initialCurseSelection', {});
          }
        }, function () {
          //searchable-select
          var certificados = self.get('certificados');
          var certificado = {};
          certificados.forEach(function (cert) {
            if (cert.certificado_k == objeto.certificado_k) {
              certificado = cert;
            }
          });

          if (Object.keys(certificado).length > 0) {
            console.log('Certificado:', certificado);
            self.set('initialCertSelection', certificado);
          } else {
            self.set('initialCertSelection', {});
          }
        });
        Ember.$('#objeto-id').data('id', objeto[llave]);
        Ember.$('#objeto-nombre').val(objeto.nombre);
        Ember.$('#objeto-descripcion').val(objeto.descripcion);
        Ember.$('#objeto-obligatorio').val(objeto.requisito);
        Ember.$('#objeto-ponderacion').val(objeto.ponderacion);
        Ember.$('#objeto-minimo').val(objeto.minimo_aprobatorio);
        Ember.$('#objeto-intentos').val(objeto.limite_intentos);

        if (kind == 'evaluacion') {
          Ember.$('#objeto-diagnostico').val(objeto.evaluacion_autodiagnostica);
        }

        Ember.$('#modalFormNewObject').data('i', objeto.propietario_recurso_k);
      },
      deleteOject: function deleteOject(objeto) {
        console.log(objeto);
        Ember.$('#modalObjetoEliminar').modal({
          'backdrop': 'static',
          'keyboard': false
        });
        Ember.$('#modalObjetoEliminar').data('i', objeto.propietario_recurso_k);
      },
      confirmDeleteObjeto: function confirmDeleteObjeto() {
        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'lms/recursocontroller/eliminar',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          data: 'i=' + Ember.$('#modalObjetoEliminar').data('i'),
          success: function success(r) {
            self.refrescaryguardarorden();
          }
        });
      },
      showNewObject: function showNewObject(kind, ra_k, callback1, callback2) {
        var self = this;
        self.set('hasError', false);

        if (kind == 'scorm') {
          Ember.$.ajax({
            url: constant.APIURL + 'lms/curso/listar',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            data: 'user=false&start=0&limit=1000',
            success: function success(r) {
              var data = JSON.parse(r).data;
              self.set('cursos', data);

              if (callback1) {
                callback1();
              }
            }
          });
        } else if (kind == 'evaluacion') {
          Ember.$.ajax({
            url: constant.APIURL + 'lms/evaluacion/listar',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            data: '_dc=',
            success: function success(r) {
              var data = JSON.parse(r).data;
              self.set('cursos', data);

              if (callback1) {
                callback1();
              }
            }
          });
        } else if (kind == 'encuesta') {
          Ember.$.ajax({
            url: constant.APIURL + 'lms/encuesta/listar',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            data: '_dc=',
            success: function success(r) {
              var data = JSON.parse(r).data;
              self.set('cursos', data);

              if (callback1) {
                callback1();
              }
            }
          });
        }

        Ember.$.ajax({
          url: constant.APIURL + 'lms/certificado/listar',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          data: 'query=',
          success: function success(r) {
            var data = JSON.parse(r).data;
            self.set('certificados', data);

            if (callback2) {
              callback2();
            }
          }
        });
        self.updateModal(kind);
        Ember.$('#modalFormNewObject').data('ra_k', ra_k);
      },
      enviarDatos: function enviarDatos() {
        var self = this;
        var kind = Ember.$('#modalFormNewObject').data('kind');
        var tabla_recurso = '';
        var autodiagnostica = '';

        if (kind == 'scorm') {
          tabla_recurso = 'lms_cursos_online';
        } else if (kind == 'evaluacion') {
          tabla_recurso = 'lms_evaluaciones';
          autodiagnostica = Ember.$('#objeto-diagnostico select').val() == '1' ? 1 : 2;
        } else if (kind == 'encuesta') {
          tabla_recurso = 'lms_encuestas';
        }

        var objeto = {};

        try {
          objeto.propietario_k = Ember.$('#modalFormNewObject').data('ra_k');
          objeto.tabla_propietario = 'lms_ra';
          objeto.recurso_k = Ember.$('#objeto-id').data('id');
          objeto.tabla_recurso = tabla_recurso;
          objeto.orden = this.get('storeObjetosAprendizaje').length + 1;
          objeto.txt_descripcion = Ember.$('#objeto-descripcion').val();
          objeto.txt_recurso = Ember.$('#objeto-descripcion').val();
          objeto.txt_nombre = Ember.$('#objeto-nombre').val();
          objeto.txt_obligatorio = parseInt(Ember.$('#objeto-obligatorio').val()) == 1 ? 1 : '';
          objeto.txt_peso = parseInt(Ember.$('#objeto-ponderacion').val());
          objeto.evaluacion_autodiagnostica = autodiagnostica;
          objeto.txt_min_aprobatorio = parseInt(Ember.$('#objeto-minimo').val());
          objeto.txt_intentos = parseInt(Ember.$('#objeto-intentos').val());
          objeto.certificado_k = parseInt(Ember.$('#objeto-certificado').data('certificado'));
        } catch (e) {
          console.log(e);
          self.set('errorMessage', 'Revisa la información proporcionada.');
          self.set('hasError', true);
          return;
        } //Agregar Validaciones aqui


        var form_data = '';

        for (var key in objeto) {
          form_data += key + '=' + objeto[key] + '&';
        }

        form_data = form_data.slice(0, form_data.length - 1);
        form_data = encodeURI(form_data);
        Ember.$.ajax({
          url: constant.APIURL + 'lms/recursocontroller/agregar',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          data: form_data,
          success: function success(r) {
            var data = JSON.parse(r);

            if (data.success) {
              self.refrescaryguardarorden();
            } else {
              self.set('errorMessage', 'Error en el servicio lms/recursocontroller/agregar.');
              self.set('hasError', true);
            }
          }
        }); //EDitar propietario_recurso_k: "689" como i:
        //CURSO   lms/recursocontroller/agregar
        //        Luego Listar
        //        Luego Guardar Orden lms/recursocontroller/guardaorden
        //        store: [{"propietario_recurso_k":"683","orden":"1"},{"propietario_recurso_k":"684","orden":"2"},{"propietario_recurso_k":"686","orden":3},{"propietario_recurso_k":"685","orden":4}]
        // propietario_k: 140
        // tabla_propietario: lms_ra
        // recurso_k: 56
        // tabla_recurso: lms_cursos_online
        // orden: 2
        // txt_descripcion: Aprende a endeudarte
        // txt_recurso: Aprende a endeudarte
        // txt_nombre: Aprende a endeudarte
        // txt_descripcionext-comp-1526: Aprende a endeudarte
        // txt_obligatorio: 
        // txt_peso: 1
        // evaluacion_autodiagnostica: 
        // txt_min_aprobatorio: 1
        // txt_intentos: 1
        // certificado_k: 16
        // Evaluacion
        // propietario_k: 140
        // tabla_propietario: lms_ra
        // recurso_k: 19
        // tabla_recurso: lms_evaluaciones
        // orden: 5
        // txt_descripcion: NAFIN011
        // txt_recurso: Evaluación de Autodiagnóstico de curso "Mujeres emprendedoras y empresarias"
        // txt_nombre: Evaluación de Autodiagnóstico de curso MMM
        // txt_descripcionext-comp-1571: NAFIN011
        // txt_obligatorio: 
        // txt_peso: 1
        // evaluacion_autodiagnostica: 
        // txt_min_aprobatorio: 1
        // txt_intentos: 1
        // certificado_k: 17
        //Encuesta
        // propietario_k: 140
        // tabla_propietario: lms_ra
        // recurso_k: 320
        // tabla_recurso: lms_encuestas
        // orden: 6
        // txt_descripcion: pruebaencuesta
        // txt_recurso: pruebaencuesta
        // txt_nombre: pruebaencuesta
        // txt_descripcionext-comp-1619: pruebaencuesta
        // txt_obligatorio: 
        // txt_peso: 1
        // evaluacion_autodiagnostica: 
        // txt_min_aprobatorio: 1
        // txt_intentos: 1
        // certificado_k: 16
      },
      editDatos: function editDatos() {
        var self = this;
        var kind = Ember.$('#modalFormNewObject').data('kind');
        var tabla_recurso = '';
        var autodiagnostica = '';
        var i = Ember.$('#modalFormNewObject').data('i');

        if (kind == 'scorm') {
          tabla_recurso = 'lms_cursos_online';
        } else if (kind == 'evaluacion') {
          tabla_recurso = 'lms_evaluaciones';
          autodiagnostica = Ember.$('#objeto-diagnostico select').val() == '1' ? 1 : 2;
        } else if (kind == 'encuesta') {
          tabla_recurso = 'lms_encuestas';
        }

        var objeto = {};

        try {
          objeto.i = i;
          objeto.propietario_k = Ember.$('#modalFormNewObject').data('ra_k');
          objeto.tabla_propietario = 'lms_ra';
          objeto.recurso_k = Ember.$('#objeto-id').data('id');
          objeto.tabla_recurso = tabla_recurso;
          objeto.orden = this.get('storeObjetosAprendizaje').length + 1;
          objeto.txt_descripcion = Ember.$('#objeto-descripcion').val();
          objeto.txt_recurso = Ember.$('#objeto-descripcion').val();
          objeto.txt_nombre = Ember.$('#objeto-nombre').val();
          objeto.txt_obligatorio = parseInt(Ember.$('#objeto-obligatorio').val()) == 1 ? 1 : 0;
          objeto.txt_peso = parseInt(Ember.$('#objeto-ponderacion').val());
          objeto.evaluacion_autodiagnostica = autodiagnostica;
          objeto.txt_min_aprobatorio = parseInt(Ember.$('#objeto-minimo').val());
          objeto.txt_intentos = parseInt(Ember.$('#objeto-intentos').val());
          objeto.certificado_k = parseInt(Ember.$('#objeto-certificado').data('certificado'));
        } catch (e) {
          console.log(e);
          self.set('errorMessage', 'Revisa la información proporcionada.');
          self.set('hasError', true);
          return;
        } //Agregar Validaciones aqui


        var form_data = '';

        for (var key in objeto) {
          form_data += key + '=' + objeto[key] + '&';
        }

        form_data = form_data.slice(0, form_data.length - 1);
        form_data = encodeURI(form_data);
        Ember.$.ajax({
          url: constant.APIURL + 'lms/recursocontroller/editar',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          data: form_data,
          success: function success(r) {
            var data = JSON.parse(r);

            if (data.success) {
              self.refrescaryguardarorden();
            } else {
              self.set('errorMessage', 'Error en el servicio lms/recursocontroller/agregar.');
              self.set('hasError', true);
            }
          }
        }); //EDitar propietario_recurso_k: "689" como i:
      }
    },
    bindScopeToParent: function bindScopeToParent() {}
  });

  _exports.default = _default;
});