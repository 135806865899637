define("comunidades-nafin/templates/diagnostico-empresarial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T4/1t/zQ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/diagnostico-empresarial.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\" \\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[14,\"loading\",[]],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n   \\n    \"],[1,[26,\"diagnostic-card\",null,[[\"mis_diagnosticos\"],[[22,[\"mis_diagnosticos\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/diagnostico-empresarial.hbs"
    }
  });

  _exports.default = _default;
});