define("comunidades-nafin/components/human-talent-components/h-t-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    self: void 0,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('windowInnerHeight', window.outerHeight -
      /* Restamos el aldo de elementos visuales de*/
      305);
    }
  });

  _exports.default = _default;
});