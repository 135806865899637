define("comunidades-nafin/components/nom-035-stps/reportes/reports/carpeta-evidencias-consultas", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Listado de las consultas generadas a través de la plataforma.',
    clase: 'consulta',
    metodo: 'listartodaslasconsultasporempresa',
    reporte: 'nom035stps/export/consultasporempresa',
    headers: [{
      title: 'Fecha creación',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Usuario Creación',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Titulo',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Descripción',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Tipo',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Trabajadores involucrados',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Fecha ocurrencia',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }, {
      title: 'Visualizar',
      dataIndex: null
      /*definido en nom-035-stps/reportes/tables/carpeta-evidencias-consultas.hbs*/

    }],
    action: function action() {}
  });

  _exports.default = _default;
});