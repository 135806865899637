define("comunidades-nafin/components/nom-035-stps/acontecimientos-traumaticos-severos/delete-form-evidencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDeleting: false,
    isFinished: false,
    hasError: false,
    didReceiveAttr: function didReceiveAttr() {
      this._super.apply(this, arguments);

      this.set('deleteStore', this.deleteStore);
      this.set('isDeleting', this.isDeleting);
      this.set('isFinished', this.isFinished);
      this.set('hasError', this.hasError);
    },
    actions: {
      showModal: function showModal() {
        this.set('isVisibleDeleteForm', true);
        this.set('isDeleting', false);
        this.set('isFinished', false);
        this.set('hasError', false);
      },
      hideModal: function hideModal() {
        this.set('isVisibleDeleteForm', false);
        this.set('isDeleting', false);
        this.set('isFinished', false);
        this.set('hasError', false);
      },
      delete: function _delete(deleteStore) {
        var self = this;
        var evidencia_k = Ember.get(deleteStore, 'evidencia_k');
        this.set('isDeleting', true);
        $.ajax({
          url: constant.APIURL + 'nom035stps/acontecimientostraumaticosseveros/eliminarevidenciadeseguimiento',
          type: "post",
          xhrFields: {
            withCredentials: true
          },
          data: 'evidencia_k=' + evidencia_k,
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          dataType: "json",
          success: function success(response) {
            if (response.success) {
              /*
               * El registro se pudo eliminar....
               */
              Ember.set(deleteStore, 'activo', 0);
              self.set('isDeleting', false);
              self.set('isFinished', true);
              self.set('hasError', false);
              self.send('hideModal');
              $('#deleteFormEvidencia').modal('hide');
            } else {
              /*
               * no se pudo eliminar el registro....
               */
              self.set('isDeleting', false);
              self.set('isFinished', true);
              self.set('hasError', true);
            }
          },
          error: function error(_error) {
            /*
             * no se pudo eliminar el registro....
             */
            self.set('isDeleting', false);
            self.set('isFinished', true);
            self.set('hasError', true);
          }
        });
      }
    }
  });

  _exports.default = _default;
});