define("comunidades-nafin/components/nom-035-stps/reportes/charts/grouped-sorted-columns-chart", ["exports", "comunidades-nafin/components/human-talent-components/charts/grouped-sorted-columns-chart"], function (_exports, _groupedSortedColumnsChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _groupedSortedColumnsChart.default.extend({});

  _exports.default = _default;
});