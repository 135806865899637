define("comunidades-nafin/routes/catalogo-encuestas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },
    actions: {
      new_question: function new_question() {
        this.transitionTo('catalogo-encuestas-especifico');
      }
    }
  });

  _exports.default = _default;
});