define("comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/acciones-internas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gNGRUDIk",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"alert alert-info\"],[10,\"role\",\"alert\"],[8],[0,\"\\n            \"],[1,[26,\"translate\",[\"nom_035_stps.carpeta_evidencias.msg.msg_descripcion_acciones_internas.valor\",\"En esta sección podra consultar las Acciones Internas que se haya realizado y su evidencia correspondiente\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"accordion\"],[8],[0,\"\\n    \"],[1,[26,\"nom-035-stps/reportes/reports/ats-generacion-masiva-acciones-internas\",null,[[\"onShowFormRequestGenerateZip\",\"onBindScopeToParent\"],[[26,\"route-action\",[\"showFormRequestGenerateZip\"],null],[26,\"route-action\",[\"bindScopeToReportAtsGeneracionMasivaAccionesInternasComponent\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"nom-035-stps/acontecimientos-traumaticos-severos/request-form-massive-acciones-internas\",null,[[\"updateStore\",\"isVisibleCreateUpdateForm\",\"command\",\"isCommandUpdate\",\"onAfterCreate\",\"onAfterUpdate\"],[[22,[\"updateStore\"]],[22,[\"isVisibleCreateUpdateForm\"]],[22,[\"createDeleteCommand\"]],[22,[\"isCommandUpdate\"]],[26,\"route-action\",[\"afterCreate\"],null],[26,\"route-action\",[\"afterUpdate\"],null]]]],false],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row mb-2\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/acciones-internas.hbs"
    }
  });

  _exports.default = _default;
});