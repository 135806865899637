define("comunidades-nafin/components/nom-035-stps/reportes/charts/simple-pie-chart", ["exports", "comunidades-nafin/components/human-talent-components/charts/simple-pie-chart"], function (_exports, _simplePieChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simplePieChart.default.extend({});

  _exports.default = _default;
});