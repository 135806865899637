define("comunidades-nafin/routes/foros/foro-especifico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    bus: Ember.inject.service('event-foros'),
    listen: function () {
      this.get('bus').on('event-foro', this, 'obtenerComunidad');
    }.on('init'),
    cleanup: function () {
      this.get('bus').off('event-foro', this, 'obtenerComunidad');
    }.on('willDestroyElement'),
    obtenerComunidad: function obtenerComunidad(data) {
      var self = this;
      var comunidades = self.controller.get("comunidades").toArray();
      comunidades.forEach(function (comunidad) {
        if (comunidad.comunidad_k == data.comunidad_k) Ember.set(comunidad, "active", true);
      });
    },
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },
    arrayColor: ['000', '9C27B0', 'b0272b', 'ff9800', '009688', 'ff5722'],
    setupController: function setupController(controller) {
      var self = this;
      var endpoint = 'comunidad/listarMisComunidades';

      if (this.session.isAdmin) {
        endpoint = 'comunidad/listar';
      }

      Ember.$.ajax({
        url: constant.APIURL + endpoint,
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        success: function success(response) {
          var data = JSON.parse(response).data;
          controller.set('comunidades', data);
          self.agregar_n_preguntas();
          Ember.$('#loading').fadeOut('slow');
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    iniciar_socket: function iniciar_socket() {
      var self = this._super.apply(this, arguments);

      self.socket.socket.emit('switchRoom', "ForosListadoGenerales"); // Detectamos la desconexion

      self.socket.socket.on('disconnect', function () {}); // Detectamos la conexion

      self.socket.socket.on('connect', function () {
        self.socket.socket.emit('switchRoom', "ForosListadoGenerales");
      });
      self.socket.socket.on('nuevaPregunta', function (data, success, parametrosAdicionales, updateforoPreguntaAgregar) {
        if (success) {
          var comunidades = self.get("comunidades").toArray();

          for (var i = 0; i < comunidades.length; i++) {
            if (comunidades[i].comunidad_k == parametrosAdicionales.comunidad_k) Ember.set(comunidades[i], "numero_preguntas_foro", parseInt(comunidades[i].numero_preguntas_foro) + 1);
          }
        }
      });
    },
    agregar_n_preguntas: function agregar_n_preguntas() {},
    actions: {
      abrirForo: function abrirForo(route) {
        var url_page = window.location.href;
        var foro_id = url_page.substring(url_page.lastIndexOf('/') + 1);
        if (foro_id == route) window.location.reload();else window.location.href = '/#/foros/' + route;
      },
      goBackForum: function goBackForum() {
        history.back();
      },
      recent_foro: function recent_foro(listaComunidad) {
        var self = this;
        Ember.$("#seeMoreForum").removeClass("d-none");
        listaComunidad.forEach(function (comunidadEmber) {
          Ember.set(comunidadEmber, "active", false);
        });
        self.controller.set("activarRecientes", true);
        var endpoint = 'comunidad/listarMisComunidades';

        if (self.session.isAdmin) {
          endpoint = 'comunidad/listar';
        }

        Ember.$.ajax({
          url: constant.APIURL + endpoint,
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(response) {
            var r = JSON.parse(response);
            var requests = []; //Preparamos las multiples peticiones AJAX

            Object.keys(r.data).forEach(function (idx) {
              requests.push(Ember.$.ajax({
                url: constant.APIURL + 'foro/listarPreguntasRecientes',
                type: 'POST',
                xhrFields: {
                  withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'params={"comunidad_k":"' + r.data[idx].comunidad_k + '"}'
              }));
            }); //Atenderemos la respuesta de todas las peticiones solo hasta que se ejecuten todas

            var defCalls = function defCalls() {
              var def = Ember.$.Deferred();
              Ember.$.when.apply(Ember.$, requests).then(function () {
                def.resolve(arguments);
              });
              return def.promise();
            };

            defCalls().done(function (arr) {
              var questions = [];

              var _loop = function _loop() {
                var tmp = arr[i][0];

                try {
                  tmp = JSON.parse(tmp).data;
                  Object.keys(tmp).forEach(function (idx) {
                    questions.push(tmp[idx]);
                  });
                } catch (ex) {
                  console.log(ex);
                }
              };

              for (var i = 0; i < arr.length; i++) {
                _loop();
              } //Formato de fechas


              Object.keys(questions).forEach(function (idx) {
                var date_time = questions[idx].timestamp_creacion.split(' ');
                var date = date_time[0].split('-');

                if (questions[idx].pregunta == "undefined") {
                  questions[idx].pregunta = 'No hay pregunta asociada';
                }

                questions[idx].dayFormath = date[2];
                questions[idx].monthFormath = date[1];
                questions[idx].yearFormath = date[0];
                var secondColor = Math.floor(Math.random() * 6);

                if (!questions[idx].foto) {
                  var nombre = questions[idx].full_name.split('');
                  var img = nombre[0].toUpperCase();
                  questions[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
                }
              }); //Ordenamiento de fechas                            

              var arr_fecha = [];
              Object.keys(questions).forEach(function (idx) {
                var fecha_completa = questions[idx].fecha_orden.split(' ');
                questions[idx].fecha_completa = fecha_completa[0];
                arr_fecha.push(questions[idx]);
              });
              arr_fecha.sort(function (a, b) {
                return a.fecha_completa < b.fecha_completa ? 1 : b.fecha_completa < a.fecha_completa ? -1 : 0;
              });
              self.controller.set('foro_reciente', arr_fecha);
              Ember.$('#loading').fadeOut('slow');
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      change_foro: function change_foro(comunidad_k, foro_k, comunidad) {
        var self = this;
        var comunidades = self.controller.get('comunidades').toArray();
        self.controller.set("activarRecientes", false);
        comunidades.forEach(function (v, i) {
          Ember.set(v, "active", false);
        });
        Ember.set(comunidad, "active", true);
        Ember.$('#foro_recientes').fadeIn('slow').removeAttr('hidden');
        Ember.$('#foro_muro').fadeOut('slow');

        this._super.apply(this, arguments);

        Ember.$.ajax({
          url: constant.APIURL + 'foro/listarPreguntasRecientes',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params={"comunidad_k":"' + comunidad_k + '"}',
          success: function success(r) {
            r = JSON.parse(r);
            Object.keys(r.data).forEach(function (idx) {
              var date_time = r.data[idx].timestamp_creacion.split(' ');
              var date = date_time[0].split('-');
              var month = date[1];
              r.data[idx].dayFormath = date[2];
              r.data[idx].monthFormath = date[1];
              r.data[idx].yearFormath = date[0];
              var secondColor = Math.floor(Math.random() * 6);

              if (r.data[idx] != 'foto') {
                var nombre = r.data[idx].full_name.split('');
                var img = nombre[0].toUpperCase();
                r.data[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
              }
            });
            self.controller.set('foro_reciente', r.data);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});