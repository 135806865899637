define("comunidades-nafin/templates/nom-035-stps/reportes/administrador/entorno-organizacional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NpUfokP2",
    "block": "{\"symbols\":[],\"statements\":[[6,\"iframe\"],[10,\"id\",\"reporte-guia-3-datastudio\"],[10,\"src\",\"\"],[10,\"frameborder\",\"0\"],[10,\"width\",\"100%\"],[10,\"height\",\"1200px\"],[10,\"style\",\"overflow:hidden\"],[8],[9],[0,\"\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/reportes/administrador/entorno-organizacional.hbs"
    }
  });

  _exports.default = _default;
});