define("comunidades-nafin/components/nom-035-stps/reportes/reports/audiencia-distribucion-cuestionarios-reporte", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Distribución de los cuestionarios por centro de trabajo',
    clase: 'nom035stps/reporteadministrador',
    metodo: 'audienciaresumenporcentrodetrabajo',
    reporte: 'nom035stps/export/distribucioncuestionariosporcentrotrabajo',
    headers: [{
      dataIndex: 'centro_trabajo',
      title: window.translate('nom_035_stps.reportes_distribucion_de_guias.tlt.tlt_table_audiencia_cuestionarios_header_centro_trabajo.valor', 'Centro de Trabajo')
    }, {
      dataIndex: 'total_colaboradores',
      title: window.translate('nom_035_stps.reportes_distribucion_de_guias.tlt.tlt_table_audiencia_cuestionarios_header_total_colaboradores.valor', 'Trabajadores')
    }, {
      dataIndex: 'evaluaciones_aplicadas',
      title: window.translate('nom_035_stps.reportes_distribucion_de_guias.tlt.tlt_table_audiencia_cuestionarios_header_evaluaciones_aplicadas.valor', 'Cuestionarios enviados')
    }]
  });

  _exports.default = _default;
});