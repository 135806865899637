define("comunidades-nafin/templates/components/human-talent-components/h-t-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k9h2AUOI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"modal fade\"],[10,\"id\",\"previewDocumentModal\"],[10,\"tabindex\",\"-1\"],[10,\"role\",\"dialog\"],[10,\"aria-labelledby\",\"exampleModalLongTitle\"],[10,\"aria-hidden\",\"true\"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"modal-dialog modal-lg\"],[10,\"role\",\"document\"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\n            \"],[6,\"div\"],[10,\"class\",\"modal-header\"],[8],[0,\"\\n\\n                \"],[6,\"h2\"],[10,\"class\",\"modal-title text-success\"],[8],[1,[26,\"fa-icon\",[\"exclamation-circle\"],null],false],[0,\" Visualizar documento \"],[9],[0,\"\\n\\n                \"],[6,\"button\"],[10,\"class\",\"close\"],[10,\"data-dismiss\",\"modal\"],[10,\"aria-label\",\"Close\"],[10,\"type\",\"button\"],[8],[0,\"\\n                    \"],[6,\"span\"],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n                \"],[9],[0,\"\\n\\n            \"],[9],[0,\"\\n\\n            \"],[6,\"div\"],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\\n                \"],[6,\"iframe\"],[10,\"id\",\"previewDocumentIFrame\"],[10,\"src\",\"\"],[10,\"frameborder\",\"0\"],[10,\"width\",\"100%\"],[11,\"height\",[27,[[20,\"windowInnerHeight\"],\"px\"]]],[10,\"style\",\"overflow:hidden\"],[8],[9],[0,\"\\n\\n            \"],[9],[0,\"\\n\\n            \"],[6,\"div\"],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\\n                \"],[6,\"button\"],[10,\"class\",\"btn btn-secondary\"],[10,\"data-dismiss\",\"modal\"],[10,\"type\",\"button\"],[8],[0,\"Cerrar\"],[9],[0,\"\\n\\n            \"],[9],[0,\"\\n\\n        \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[13,1],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/human-talent-components/h-t-preview.hbs"
    }
  });

  _exports.default = _default;
});