define("comunidades-nafin/templates/cursos-offline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/bdVUQcg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/cursos.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\\n\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[6,\"main\"],[10,\"id\",\"panel\"],[10,\"class\",\"panel\"],[8],[0,\"\\n\"],[14,\"loading\",[]],[0,\"\\n\\n\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n            \"],[1,[20,\"offline/lista-cursos\"],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \\n\"],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/cursos-offline.hbs"
    }
  });

  _exports.default = _default;
});