define("comunidades-nafin/components/human-talent-components/h-t-chart", ["exports", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/themes/animated"], function (_exports, am4core, am4charts, _animated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  am4core.useTheme(_animated.default);

  var _default = Ember.Component.extend({
    chart: null,
    chartID: null,
    data: null,
    metodo: '',
    clase: '',
    params: {},
    title: '',

    /**
     *
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.chartID = this.generateChartID();
      this.set('chartID', this.chartID);
    },

    /**
     *
     */
    didInsertElement: function didInsertElement() {
      this.initChart(); // Ejecutamos la instanciación del objeto de tipo gráfica.

      this.loadData(); // Insertamos el data como propiedad del componente. Ya sea de origen local ó remote.

      if (this.parseData) {
        this.parseData(); // En caso de requerir, para cada tipo de gráfica realizamos la conversión (parser) del data.
        //  Para adecaurse al tipo de gráfica especifico.
      }

      this.setData(); // Seteamos la propiedad data del objeto this hacia el objeto char.

      this.setupChart(); // Función privada para ajustar las diferentes configuraciones disponibles para cada tipo de gráfica.
    },

    /**
     *
     */
    willDestroyElement: function willDestroyElement() {
      if (this.chart) {
        this.chart.dispose();
      }

      this._super.apply(this, arguments);
    },

    /**
     *
     */
    generateChartID: function generateChartID() {
      return 'div-chart-' + Ember.generateGuid();
    },
    setParams: function setParams(params) {
      this.params = params;
    },

    /**
     *
     * loadData - Función utilitaria que permite cargar el data correspondiente a la gráfica.
     *
     */
    loadData: function loadData() {
      var self = this;
      var data;

      if (String(this.metodo).length > 0 && String(this.clase).length > 0) {
        $.ajax({
          url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
          type: "post",
          async: false,
          data: this.params,
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              self.data = response.data;
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      } else if (this.data) {
        this.data;
      } else {
        this.data = [];
      }
    },

    /**
     *
     */
    parseData: null,

    /**
     *
     */
    getTypeChart: function getTypeChart() {
      return null;
    },

    /**
     *
     *
     *
     */
    initChart: function initChart() {
      this.chart = am4core.create(this.get('chartID'), this.getTypeChart());
    },

    /**
     * setData - Función utilitaria
     *
     *
     *
     */
    setData: function setData() {
      this.chart.data = this.data;
    },

    /**
     * setupChart -
     *
     *
     */
    setupChart: function setupChart() {
      if (String(this.title).length > 0) {
        this.setTitle(String(this.title));
      }
    },

    /**
     * setTitle - Función utilitaria para generar titulo de gráfica.
     *
     *
     *
     */
    setTitle: function setTitle() {
      var title_string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var title = this.chart.titles.create();
      title.text = title_string;
    },

    /**
     * actions
     *
     *
     */
    actions: {
      load: function load() {
        this.loadData();
      },
      reload: function reload() {
        this.loadData();
      }
    }
  });

  _exports.default = _default;
});