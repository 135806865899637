define("comunidades-nafin/routes/administracion-cursos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * administracion-cursos - Route corresondiente a admistación de cusros
   *
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
   *
   *
   * @author      [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date:       2018-10-04
   * @extend:     Route.extend
   */
  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },

    /**
     * setupController - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   controller controller referencia al controlador de notificaciones.
     * @return  void
     */
    setupController: function setupController(controller) {
      // Call _super for default behavior
      this._super(controller);

      controller.set("start_int", 0);
      controller.set("store_cursos", []);
      controller.set("searchActive", false);
      controller.set("filtro_string", '');
      this.loadStore(controller);
    },

    /**
     * didInsertElement - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    loadStore: function loadStore(controller) {
      mask();

      var _self = this;

      var _controller = controller;

      var _searchActive = controller.get("searchActive");

      var start_int = _searchActive ? 0 : controller.get("start_int");
      var limit_int = _searchActive ? constant.PAGESIZE : constant.PAGESIZE;
      var filtro_string = controller.get("filtro_string");
      var params = _searchActive ? JSON.stringify({
        'nombre': filtro_string
      }) : '{}';
      this.disableButttonLoadMore();
      Ember.$.ajax({
        url: constant.APIURL + 'lms/raprendizaje/listar',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data: 'start=' + start_int + '&limit=' + limit_int + '&params=' + params,
        xhrFields: {
          withCredentials: true
        },
        success: function success(response_text) {
          var data = JSON.parse(response_text);
          /*
           * En caso de que no haya elementos que mostrar, salimos de la función sin mayor complicación.
           */

          if (data.data.length == 0) {
            Ember.$('#button-show-more-cursos').text('Ups! No hay mas cursos que mostrar.');
            unmask();
            return;
          }

          var lista_cursos = _controller.get("store_cursos");

          data.data.forEach(function (_curso_object) {
            /*
             * @todo: Trasladar este negocio a una función <<evaluatereglasNegocio>>
             */

            /*
             *  Iteramos sobre el store local, para identificar sí _curso_object ya esta o no almcenado.
             */
            var index_found = lista_cursos.length == 0 ? -1 : lista_cursos.reduce(function (vAnterior, vActual, indice, vector) {
              if (Number.isInteger(vAnterior)) {
                return vAnterior;
              }

              if (vActual.ra_k == vAnterior.ra_k) {
                return indice;
              }

              if (indice == vector.length - 1) {
                return -1;
              }

              return _curso_object;
            }, _curso_object);
            /*
             * Sí el registro no esta en el store local, se agrega (al store local).
             */

            if (index_found == -1) {
              _curso_object.ruta_imagen = solveImageURL(_curso_object.ruta_imagen);
              /*
               * Se evaluan reglas de filtro, en caso de que el usuario haya escrito algo en el filtro de bisqueda.
               */

              if (_searchActive) {
                if (_curso_object.nombre.trim().toLowerCase().indexOf(filtro_string.trim().toLowerCase()) == -1) {
                  Ember.set(_curso_object, "is_visible", false);
                } else {
                  Ember.set(_curso_object, "is_visible", true);
                }
              } else {
                /*
                 *  En caso de que no este activa la busqueda, se habilita la visualización del registro sin mas.
                 */
                _curso_object.is_visible = true;
              }
              /*
               *  Agregamos el elemento al store de cursos
               */


              lista_cursos.pushObject(_curso_object);
            }
          });
          /*
           * El ordenamiendo de registros esta habilitado ( Por el momento siempre los estará )
           */

          if (true) {
            lista_cursos.sort(function (a, b) {
              return b.ra_k - a.ra_k;
            });
          }
          /*
           * Re-seteamos el store de cursos.
           */


          _controller.set("store_cursos", lista_cursos);
          /*
           *	Posterior a la ejecución, se incrementa el limite del para la próxima petición a servidor. Esto aplica solo sí la busqueda esta activa.
           */


          if (!controller.get("searchActive")) {
            if (data.data.length > 0) {
              var start_int = _controller.get("start_int");

              start_int = start_int + constant.PAGESIZE;

              _controller.set("start_int", start_int);
            }
          }

          _self.enableButttonLoadMore();

          unmask();
        },
        error: function error(e) {
          unmask();

          _self.enableButttonLoadMore();

          showModalError();
          console.log(e);
        }
      });
    },

    /**
     * disableButttonLoadMore - Función utilitaria local. Deshabilita el botón de <<cargar mas cursos>> de manera local.
     *
     *
     * Deshabilita el botón de <<cargar mas cursos>> de manera local.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    disableButttonLoadMore: function disableButttonLoadMore() {
      Ember.$('#button-show-more-cursos').attr("disabled", "disabled");
    },

    /**
     * enableButttonLoadMore - Función utilitaria local. Habilita el botón de <<cargar mas cursos>> de manera local.
     *
     *
     * Habilita el botón de <<cargar mas cursos>> de manera local.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    enableButttonLoadMore: function enableButttonLoadMore() {
      Ember.$('#button-show-more-cursos').removeAttr('disabled');
    },

    /*
     * Actions - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     */
    actions: {
      /*
       * loadMoreCursos - Acción responsable de cargar mas cursos en el Store local de cursos.
       *
       *
       * Acción responsable de cargar mas cursos en el Store local de cursos.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */
      loadMoreCursos: function loadMoreCursos() {
        this.loadStore(this.controller);
      },

      /*
       * clearSearchInStore - Deshabilita el estado general de busqueda por filtro.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */
      clearSearchInStore: function clearSearchInStore(store_cursos) {
        this.controller.set("searchActive", false);
        this.controller.set("filtro_string", '');
        store_cursos.forEach(function (_curso_object) {
          Ember.set(_curso_object, "is_visible", true);
        });
      },

      /*
       * searchInStore - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */
      searchInStore: function searchInStore() {
        var filtro_string = this.controller.get("filtro_string");
        this.controller.set("searchActive", true);
        var store_cursos = this.controller.get("store_cursos");
        store_cursos.forEach(function (_curso_object) {
          if (_curso_object.nombre.trim().toLowerCase().indexOf(filtro_string.trim().toLowerCase()) == -1) {
            Ember.set(_curso_object, "is_visible", false);
          } else {
            Ember.set(_curso_object, "is_visible", true);
          }
        });
        /*
         *  Solo si existe algo que buscar, se realiza la busqueda en servidor re-loading el store.
         */

        if (filtro_string.trim().toLowerCase() == '') {
          this.controller.set("searchActive", false);
        } else {
          this.loadStore(this.controller);
        }
      },

      /*
       * Lorem ipsum - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */
      showModalNewLearningPath: function showModalNewLearningPath() {
        /*
         * Por medio de una referencia al scope del componente cursos-admin-cursos-scope, hacemos el llamando a  la función de reseteo de registro del formulario
         * y mostramos la ventana.
         */
        this.cursosAdminCursosScope.resetNewLearningPath();
        Ember.$('#modalFormNewLearningPath').modal('show');
      },

      /*
       * afterSaveLearningPath - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */
      afterSaveLearningPath: function afterSaveLearningPath(callback_function) {
        /*
         * Conociendo que el servidor devuelve los registros de cursos dando prioridad a los últimos creados. aprovechamos esto
         * para refrescar el stote en modo <<busqueda activa>> con un filtro de cadena vacía. para asi agregar el último elemneto 
         * agregado.
         */

        /*
         * Guardamos las variables que se tienen en <<searchActive>> y <<filtro_string>>
         */
        var _searchActive = this.controller.get("searchActive");

        var filtro_string = this.controller.get("filtro_string");
        /* 
         * Seteamos variables y hacemos el re-loading de cursos.
         */

        this.controller.set("searchActive", true);
        this.controller.set("filtro_string", "");
        this.loadStore(this.controller);
        /*
         * En caso de haber recidibo desde el una función de callback, la ejecutamos.
         */

        if (isFunction(callback_function)) {
          callback_function();
        }
        /*
          * Recuperamos las variables que se tenían en <<searchActive>> y <<filtro_string>>
          */


        this.controller.set("searchActive", _searchActive);
        this.controller.set("filtro_string", filtro_string);
      },

      /*
       * bindScopeToCursosAdminCursosComponent - Crear un enlace (bind) hacia el compomenten <cursos-admin-cursos>>.
       *
       *
       * Crear un enlace (bind) hacia el compomenten <cursos-admin-cursos>>, por medio de una propiedad en el contexto this.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   scope _scope_cursos_admin_cursos_component Scope del componente <cursos-admin-cursos>>.
       * @return  void
       */
      bindScopeToCursosAdminCursosComponent: function bindScopeToCursosAdminCursosComponent(_scope_cursos_admin_cursos_component) {
        this.cursosAdminCursosScope = _scope_cursos_admin_cursos_component;
      }
    }
  });

  _exports.default = _default;
});