define("comunidades-nafin/components/events-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      var d = new Date();
      var day = d.getDate();
      var month = d.getMonth();

      if (month < 10) {
        month = '0' + month;
      }

      var year = d.getFullYear();
      var date = new Date();
      var newDate = new Date(date.setDate(date.getDate() + 30));
      var newDay = newDate.getDate();
      var newYear = newDate.getFullYear();
      var newMonth = newDate.getMonth();
      $.ajax({
        url: constant.APIURL + 'calendario/listarProximosEventos',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: 'params={"start":"' + year + '-' + month + '-' + day + '","end":"' + newYear + '-' + newMonth + '-' + newDay + '"}',
        success: function success(response) {
          var data = JSON.parse(response);

          if (data.data != undefined && data.data.length > 5) {
            data.data = data.data.slice(0, 5);
          }

          self.formatter(data.data, self);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    setEvent: function setEvent(r) {
      console.log(r + " from setEvent");
      this.set('eventos', r.data);
    },
    //Dar formato deseado a mes y fechas
    formatter: function formatter(data, self) {
      if (data != undefined) {
        Object.keys(data).forEach(function (idx) {
          var date = data[idx].start.split('-');
          var month = date[1];
          data[idx].yearFormath = date[0];
          data[idx].monthFormath = self.monthFormatter(month);
          data[idx].dayFormath = date[2];
          var dateEnd = data[idx].end.split('-');
          var monthEnd = dateEnd[1];
          data[idx].yearFormathEnd = dateEnd[0];
          data[idx].monthFormathEnd = self.monthFormatter(monthEnd);
          data[idx].dayFormathEnd = dateEnd[2];
          data[idx].formatt_hora = data[idx].hora.substr(0, 5);
          data[idx].formatt_hora_fin = data[idx].hora_fin.substr(0, 5);
        });
      }

      self.setMonth(data, self);
    },
    // Asignar el més en el que nos encontramos
    monthFormatter: function monthFormatter(month) {
      if (month === "01") {
        return "Enero";
      } else if (month === "02") {
        return "Febrero";
      } else if (month === "03") {
        return "Marzo";
      } else if (month === "04") {
        return "Abril";
      } else if (month === "05") {
        return "Mayo";
      } else if (month === "06") {
        return "Junio";
      } else if (month === "07") {
        return "Julio";
      } else if (month === "08") {
        return "Agosto";
      } else if (month === "09") {
        return "Septiembre";
      } else if (month === "10") {
        return "Octubre";
      } else if (month === "11") {
        return "Noviembre";
      } else {
        return "Diciembre";
      }
    },
    setMonth: function setMonth(data, self) {
      self.set('event', data);

      if (!data || !data.length) {
        $('#bloque_eventos').hide();
        $('#bloque_noticias').attr("class", 'col-12');
      } else {
        $('.img-fluid').removeClass('img-noticias-inicio').addClass("img-noticias-inicio-eventos");
      }
    }
  });

  _exports.default = _default;
});