define("comunidades-nafin/helpers/dateformat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateformat = dateformat;
  _exports.default = void 0;

  function dateformat(params
  /*, hash*/
  ) {
    var day = params.toArray()[0];
    var month = params.toArray()[1];
    var year = params.toArray()[2];
    return window.dateFormat(day, month, year);
  }

  var _default = Ember.Helper.helper(dateformat);

  _exports.default = _default;
});