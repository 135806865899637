define("comunidades-nafin/templates/cdv-generic-nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4N5LkxNf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"nav\",\"leftButton\",\"text\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[3,\"action\",[[21,0,[]],\"leftButton\"]],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"nav\",\"leftButton\",\"icon\"]]],null,{\"statements\":[[0,\"      \"],[6,\"i\"],[11,\"class\",[27,[\"icon \",[22,[\"nav\",\"leftButton\",\"icon\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[22,[\"nav\",\"leftButton\",\"text\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"nav\",\"title\",\"text\"]]],null,{\"statements\":[[0,\"  \"],[6,\"h1\"],[8],[0,\"\\n    \"],[1,[22,[\"nav\",\"title\",\"text\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"nav\",\"rightButton\",\"text\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[3,\"action\",[[21,0,[]],\"rightButton\"]],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"nav\",\"rightButton\",\"icon\"]]],null,{\"statements\":[[0,\"      \"],[6,\"i\"],[11,\"class\",[27,[\"icon \",[22,[\"nav\",\"rightButton\",\"icon\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[22,[\"nav\",\"rightButton\",\"text\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/cdv-generic-nav-bar.hbs"
    }
  });

  _exports.default = _default;
});