define("comunidades-nafin/components/course-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reglasEvaluacion: {},
    bus: Ember.inject.service('evaluacion-curso'),
    zips: [],
    listen: function () {
      this.get('bus').on('setearReglas', this, 'setearReglasEvaluacion');
    }.on('init'),
    setearReglasEvaluacion: function setearReglasEvaluacion(data) {
      this.reglasEvaluacion = data;
    },
    progressiveUpdate: function progressiveUpdate(id, usuario, score_courses_resume) {
      var self = this;
      setTimeout(function () {
        lazyLoad.update();
      }, 1000);
      setInterval(function () {
        if (document.location.href.indexOf('cursos') != -1) {
          if (document.location.href.split('cursos/').length == 2) {
            self.updateData(id, usuario, score_courses_resume);
          }
        }
      }, 10000);
    },
    updateData: function updateData(id, usuario, score_courses_resume) {
      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'lms/alumno/listarrutas/listarmisras',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: 'params={"ra_k":' + id + '}',
        success: function success(r) {
          var response = JSON.parse(r);
          var ra;
          Object.keys(response.data).forEach(function (idx) {
            ra = response.data[idx].intento_ra;
          });
          Ember.$.ajax({
            url: constant.APIURL + 'lms/raprendizaje/listarProgreso',
            type: 'POST',
            xhrFields: {
              withCredentials: true
            },
            contentType: 'application/x-www-form-urlencoded',
            data: 'usuario_k=' + usuario.usuario_k + '&llave_propietario_k=' + id + '&intento_ra=' + ra + '&cat=is null',
            success: function success(r) {
              var respons = JSON.parse(r);
              self.set("porcentaje_avance", parseInt(respons.ra.porcentaje_avance));
              respons.ra.formattAvance = parseInt(respons.ra.porcentaje_avance);
              respons.ra.porcentaje_avance_css = Ember.String.htmlSafe('width:' + respons.ra.porcentaje_avance + '%');
              respons.ra.avance = {
                datasets: [{
                  data: [respons.ra.formattAvance, 100 - respons.ra.formattAvance],
                  backgroundColor: ['#38bc62', '#dddddd'],
                  borderWidth: 0
                }],
                labels: [respons.ra.formattAvance + '% completado', parseInt(100 - respons.ra.formattAvance) + '% pendiente']
              };

              if (score_courses_resume.data) {
                respons.ra.ranking_raprendizaje = score_courses_resume.data[respons.ra.ra_k];

                if (respons.ra.ranking_raprendizaje) {
                  respons.ra.ranking_raprendizaje_score = Math.round(respons.ra.ranking_raprendizaje.rate);
                } else {
                  respons.ra.ranking_raprendizaje_score = 0;
                }
              } else {
                respons.ra.ranking_raprendizaje_score = 0;
              }

              Ember.$("#ranking").text(respons.ra.ranking_raprendizaje_score);
              respons.ra.estatus_string = constant.LMS.CURSOS.ESTATUS[parseInt(respons.ra.estatus)];
              respons.ra.imagen_raprendizaje = solveImageURL(respons.ra.imagen_raprendizaje);
              respons.ra.is_certificado_imprimible = parseInt(respons.ra.certificado_imprimible) === 1 ? true : false;
              respons.ra.is_dc3_imprimible = false;

              if (window.instanciaConfiguracion.lms_boton_descargar_dc3_habilitar == true) {
                respons.ra.is_dc3_imprimible = parseInt(respons.ra.dc3_imprimible) === 1 ? true : false;
              }

              self.setCursos(respons.ra, self);
              window.notScored = true;
              self.zips = [];
              Object.keys(respons.data).forEach(function (idx) {
                if (respons.data[idx].nombre_zip != null && respons.data[idx].nombre_zip != undefined) self.zips.push(respons.data[idx]);

                if (respons.data[idx].estatus == 8 && respons.data[idx].habilitar_calificar_ra == 1 && notScored) {
                  Ember.$.ajax({
                    url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
                    contentType: 'application/x-www-form-urlencoded',
                    xhrFields: {
                      withCredentials: true
                    },
                    type: 'POST',
                    data: {
                      method: "user_already_score",
                      data: {
                        "id_user": respons.ra.usuario_k,
                        "id_course": respons.ra.ra_k
                      }
                    },
                    success: function success(response_text) {
                      var r = JSON.parse(response_text);

                      if (!r.alredyScored) {
                        Ember.$('#modal_grade').modal({
                          backdrop: 'static',
                          keyboard: false
                        });
                        Ember.$('#modal_grade').on('hidden.bs.modal', function () {
                          document.location.reload();
                        });
                        Ember.$('#modal_grade').modal();
                      }

                      {
                        notScored = false;
                      }
                    },
                    error: function error(err) {
                      console.error(err);
                    }
                  });
                }
              });
            },
            error: function error(e) {
              console.log(e);
            }
          });
        },
        error: function error(e) {
          console.error(e);
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('chartype', 'doughnut');
      this.set('chartoptions', {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function label(tooltipItem, data) {
              var label = data.labels[tooltipItem.index] || '';
              return ' ' + label + ' ';
            }
          }
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      var url_page = window.location.href.split("/");
      var id = url_page[url_page.length - 1]; //url_page.substring(url_page.lastIndexOf('/') + 1);

      var welcome_message = '';
      self.set('welcome_message', '');
      welcome_message = window.translate("comunidades.comunidad_especifica.msg.msg_mensaje_bienvenida_comunidad.valor", '');

      if (welcome_message.trim() != '') {
        welcome_message = welcome_message.replace('@userData.nombre@', this.session.userData.nombre);
        self.set('welcome_message', welcome_message);
      }

      Ember.$('#loading').fadeIn('slow');
      setTimeout(function () {
        Ember.$(".text-cursos").addClass("selected-option-menu");
      }, 1000);
      self.set("nombre_comunidad", localStorage.getItem('nombre_comunidad'));
      var usuario = window.datosPersonales;

      if (window.instanciaConfiguracion.general_compatir_cursos_en_facebook_habilitar == false) {
        self.set("mostrar_compartir_facebook", false);
      } else {
        self.set("mostrar_compartir_facebook", true);
      }

      if (window.instanciaConfiguracion.general_compatir_cursos_en_twitter_habilitar == false) {
        self.set("mostrar_compartir_twitter", false);
      } else {
        self.set("mostrar_compartir_twitter", true);
      }

      if (window.instanciaConfiguracion.general_compatir_cursos_en_linkedin_habilitar == false) {
        self.set("mostrar_compartir_linkedin", false);
      } else {
        self.set("mostrar_compartir_linkedin", true);
      }

      Ember.$.ajax({
        url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
        contentType: 'application/x-www-form-urlencoded',
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: {
          "method": "score_courses_resume"
        },
        success: function success(response_text) {
          var score_courses_resume = JSON.parse(response_text);
          self.updateData(id, usuario, score_courses_resume);
          self.progressiveUpdate(id, usuario, score_courses_resume);
        }
      });
    },
    setCursos: function setCursos(r, self) {
      if (r.estatus == 1) {
        r.status = true;
      } else {
        r.status = false;
      } // Guardamos en session para almacenar en localstorage


      localStorage.setItem("curso", JSON.stringify(r));
      if (r.porcentaje_avance > 0) r.porcentaje_avance = r.porcentaje_avance.toString().substring(0, 5);
      self.set('curso', r);
    },
    numOfTimes: Ember.computed('times', function () {
      return [1, 2, 3, 4, 5];
    }),
    actions: {
      /**
       * printCertificate - Construye la URL para generar el certificado correspondiente a la RA.
      *
      *
      * Construye la URL para generar el certificado correspondiente a la RA.
      *
      *
       * @author  	[YRM] Maurilio Cruz Morales
      *	              maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
      *  	            Tel. Oficina + 52 (55) 4333 3433 Ext. 107
      *      	        Tel. celular (55) 6702 625
      *	@action		printCertificate
      ** @date   	2018-08-27
       *  @param   	void
       *  @return  	void
      *
      */
      printCertificate: function printCertificate() {
        var _curso = this.get('curso');

        var location = constant.APIURL + 'lms/curso/certificado_pdf/' + _curso.certificado_k + '/' + _curso.fecha_fin;
        window.open(location, '_blank');
        /*
               *  @todo:		Agregar fomateo de la variable _curso.fecha_fin, para garantizar que la fecha enviada al imprimir
                  *  			el certificado, contenga el formato  'Y-m-d', considerando la siguiente línea de origen:
                  *
                  * location.href	= constant.APIURL + 'lms/curso/certificado_pdf/' + _curso.certificado_k + '/' + Ext.util.Format.date( _curso.fecha_fin, 'Y-m-d' );
                  */
      },

      /**
       * printDC3 - Construye la URL para generar el DC3 de la RA
       *
       *
       * Construye la URL para generar el DC3 de la RA
       *
       *
       * @author  	[YRM] Maurilio Cruz Morales
       *	              maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *  	            Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *      	        Tel. celular (55) 6702 625
       *	@action		printCertificate
       ** @date   	2018-08-27
       *  @param   	void
       *  @return  	void
       *
       */
      printDC3: function printDC3() {
        var _curso = this.get('curso');

        console.dir(_curso);
        var params = {
          grupo_k: _curso.grupo_k,
          ra_k: _curso.ra_k,
          usuario_k: this.session.userData.usuario_k
        };
        Ember.$.ajax({
          url: constant.APIURL + 'lms/dc3/generardc3',
          type: 'POST',
          data: params,
          xhrFields: {
            withCredentials: true
          },
          success: function success(r) {
            var response = JSON.parse(r);

            if (response.success == false) {
              console.dir('Error');
              return;
            }

            window.open(response.path, '_blank');
          },
          error: function error(err) {
            console.error(err);
          }
        });
      },
      printCertificateMovil: function printCertificateMovil() {
        var _curso = this.get('curso');

        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'lms/curso/obtenerURLCertificado/' + _curso.certificado_k + '/' + _curso.fecha_fin,
          type: 'POST',
          dataType: 'json',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          success: function success(response) {
            if (response.success) {
              var fileTransfer = new FileTransfer();
              var uri = encodeURI(response.data.url_certificado);
              var recurso = Ember.$("#cursoNombre").text() + ".pdf";
              var pathDownloads = "";
              var localPath = "temporal";

              if (device.platform == "Android") {
                pathDownloads = cordova.file.dataDirectory + "downloads/" + localPath;
              } else {
                pathDownloads = cordova.file.documentsDirectory + "downloads/" + localPath;
              }

              var fileURL = pathDownloads + "/" + recurso;
              fileTransfer.download(uri, fileURL, function (entry) {
                cordova.plugins.fileOpener2.open(entry.toURL(), // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
                'application/pdf', {
                  error: function error(e) {
                    console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                  },
                  success: function success() {
                    console.log('file opened successfully');
                  }
                });
              }, function (error) {
                console.log("Ha ocurrido un error durante la descompresion, por favor intente mas tarde...");
                console.log("download error source " + error.source);
                console.log("download error target " + error.target);
                console.log("download error code" + error.code);
              });
            }
          },
          error: function error(e) {
            console.log(e);
          }
        }); //
      },

      /**
       * goFacebook - goFacebook.
      *
      *
      * goFacebook.
      *
       *
       */
      goBack: function goBack() {
        Ember.$("#loading").show();
        history.back();
      },
      goFacebook: function goFacebook() {
        this._super();

        var self = this;
        var url = window.location.href;
        var arrayUrl = url.split('/');
        var ra_k = arrayUrl[arrayUrl.length - 1];
        shareFB(constant.SHAREURL + 'curso/' + ra_k);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_CURSO, ra_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_FACEBOOK);
      },
      goTwitter: function goTwitter(e) {
        this._super();

        var self = this;
        var elementoPadreDom = Ember.$(e.target).parent().parent().parent().parent().parent().parent().children()[1];
        var elementoPadreEmberComponente = Ember.$(e.target).parent().parent().parent().parent().parent().parent().parent().parent().parent().parent().children()[3];
        var twitterMsm = 'Se parte del curso ' + Ember.$(elementoPadreEmberComponente).children().children().text();
        var url = window.location.href;
        var arrayUrl = url.split('/');
        var ra_k = arrayUrl[arrayUrl.length - 1];
        url = constant.SHAREURL + 'curso/' + ra_k;
        var width = 575,
            height = 400,
            left = (Ember.$(window).width() - width) / 2,
            top = (Ember.$(window).height() - height) / 2,
            url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
            opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
        window.open(url, 'twitter', opts);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_TWITTER_COMPARTIR_CURSO, ra_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_TWITTER);
      },
      descargar: function descargar() {
        var self = this;
        var zips = self.zips;
        Ember.$.each(zips, function (i, v) {
          Ember.$.ajax({
            url: window.constant.APIURL + 'lms/raprendizaje/validarDescargaZip',
            type: "post",
            data: {
              zip: v.nombre_zip
            },
            async: false,
            dataType: "json",
            xhrFields: {
              withCredentials: true
            },
            success: function success(response) {
              console.log(response);
            }
          });
        });
      },
      linkedin: function linkedin() {
        this._super();

        var self = this;
        var url = window.location.href;
        var arrayUrl = url.split('/');
        var ra_k = arrayUrl[arrayUrl.length - 1];
        shareLI(constant.SHAREURL + 'curso/' + ra_k);
        window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_CURSO, ra_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_LINKEDIN);
      },
      grade: function grade(event) {
        var numberStart = Ember.$(event.target).parent().attr('id');

        if (numberStart === 'uno_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>'); //console.log('1 estrella');
        } else if (numberStart === 'dos_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>'); //console.log('2 estrellas');
        } else if (numberStart === 'tres_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>'); //console.log('3 estrellas');
        } else if (numberStart === 'cuatro_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="far fa-star"></i>'); //console.log('4 estrellas');
        } else if (numberStart === 'cinco_estrellas') {
          Ember.$('#uno_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas').html('<i class="fas fa-star"></i>');
          Ember.$('#cinco_estrellas').html('<i class="fas fa-star"></i>'); //console.log('5 estrellas');
        } else {
          console.warn(numberStart);
        }
      },
      grade_platform: function grade_platform(event) {
        var numberStart = Ember.$(event.target).parent().attr('id');

        if (numberStart === 'uno_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>'); //$('#modal_grade').modal();
        } else if (numberStart === 'dos_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>'); //$('#modal_grade').modal();
        } else if (numberStart === 'tres_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="far fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>'); //$('#modal_grade').modal();
        } else if (numberStart === 'cuatro_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="far fa-star"></i>'); //$('#modal_grade').modal();
        } else if (numberStart === 'cinco_estrellas_pltf') {
          Ember.$('#uno_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#dos_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#tres_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cuatro_estrellas_pltf').html('<i class="fas fa-star"></i>');
          Ember.$('#cinco_estrellas_pltf').html('<i class="fas fa-star"></i>'); //$('#modal_grade').modal();
        } else {
          console.warn(numberStart);
        }
      },
      send_grade: function send_grade() {
        var url_page = window.location.href;
        var id = url_page.substring(url_page.lastIndexOf('/') + 1);
        var self = this;
        var score_course = Ember.$('#modal_grade .modal-body').find('center').first().find('.fas').length;
        var score_platform = Ember.$('#modal_grade .modal-body').find('center').last().find('.fas').length;

        if (score_platform == 0 || score_course == 0) {
          Ember.$('#modal-avisos .modal-header').text('Aviso');
          Ember.$('#modal-avisos .modal-body').text('Por favor valora el curso y la plataforma, nos ayudará a mejorar tu experiencia.');
          Ember.$('#modal-avisos').modal('show');
          return false;
        } else {
          Ember.$.ajax({
            url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
            contentType: 'application/x-www-form-urlencoded',
            xhrFields: {
              withCredentials: true
            },
            type: 'POST',
            data: {
              method: "score_course",
              data: {
                id_course: parseInt(id),
                id_user: parseInt(self.session.userData.usuario_k),
                score: parseInt(score_course)
              }
            },
            success: function success(response) {
              Ember.$.ajax({
                url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
                contentType: 'application/x-www-form-urlencoded',
                xhrFields: {
                  withCredentials: true
                },
                type: 'POST',
                data: {
                  method: "score_platform",
                  data: {
                    id_user: parseInt(self.session.userData.usuario_k),
                    score: parseInt(score_platform)
                  }
                },
                success: function success(r) {
                  Ember.$('#modal_grade').modal('hide');
                  Ember.$.ajax({
                    url: constant.APIURL + 'usuario/datospersonales/',
                    type: 'POST',
                    xhrFields: {
                      withCredentials: true
                    },
                    contentType: 'application/x-www-form-urlencoded',
                    success: function success(r) {
                      var usuario = JSON.parse(r);
                      Ember.$.ajax({
                        url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
                        contentType: 'application/x-www-form-urlencoded',
                        xhrFields: {
                          withCredentials: true
                        },
                        type: 'POST',
                        data: {
                          "method": "score_courses_resume"
                        },
                        success: function success(response_text) {
                          var score_courses_resume = JSON.parse(response_text);
                          self.updateData(id, usuario, score_courses_resume);
                          self.progressiveUpdate(id, usuario, score_courses_resume);
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});