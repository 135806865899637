define("comunidades-nafin/components/course-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      var url_page = window.location.href;
      var id = url_page.substring(url_page.lastIndexOf('/') + 1);
      Ember.$.ajax({
        url: constant.APIURL + '/documento/listarElementosComunidadesRa',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: 'ra_k=' + id,
        success: function success(r) {
          var response = JSON.parse(r);
          var data = response.data;
          Object.keys(data).forEach(function (idx) {
            if (data[idx].fecha_creacion) {
              var splitDate = data[idx].fecha_creacion.split('-');
              var year = splitDate[0];
              var month = splitDate[1];
              var day = splitDate[2];
              data[idx].fecha_creacion = day + '/' + month + '/' + year;
              data[idx].ruta = constant.BASEURL + data[idx].ruta;
            }
          });
          console.log(data);
          self.setDocuments(data, self);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    setDocuments: function setDocuments(r, self) {
      self.set('documentos', r);
      setTimeout(function () {
        if (r.length === 0) {
          Ember.$('#table').attr('hidden', true);
          Ember.$('#legend').removeAttr('hidden');
        }
      }, 0);
    },
    actions: {
      abrirArchivo: function abrirArchivo(ruta) {
        window.open(ruta, '_system');
      }
    }
  });

  _exports.default = _default;
});