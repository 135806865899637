define("comunidades-nafin/components/nom-035-stps/reportes/charts/gauge-with-bands-chart", ["exports", "comunidades-nafin/components/human-talent-components/charts/gauge-with-bands-chart"], function (_exports, _gaugeWithBandsChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _gaugeWithBandsChart.default.extend({});

  _exports.default = _default;
});