define("comunidades-nafin/helpers/translate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.translate = translate;
  _exports.default = void 0;

  function translate(params
  /*, hash*/
  ) {
    if (params.toArray().length < 2) {
      return 'Parametros incompletos';
    }

    return window.translate(params.toArray()[0], params.toArray()[1]);
  }

  var _default = Ember.Helper.helper(translate);

  _exports.default = _default;
});