define("comunidades-nafin/routes/nom-035-stps/reportes/administrador/audiencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('dataChart1', {
        clase: 'nom035stps/reporteadministrador',
        metodo: 'reporteporaudienciaporempresa'
      });
    }
  });

  _exports.default = _default;
});