define("comunidades-nafin/components/omni-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('placeHolder', this.placeHolder ? this.placeHolder : 'Buscar...');
      this.set('done', 'Hecho');
      this.set('editLong', 'Habilitar Edición');
      this.set('editShort', '');
      this.set('newLong', this.newLong ? this.newLong : 'Nuevo Elemento');
      this.set('newShort', '');
      this.set('clearShort', '');
      this.set('clearLong', 'Limpiar Filtro');
      this.set('searchValue', this.searchValue ? this.searchValue : '');
      this.set('isEditing', this.isEditing ? this.isEditing : false);
      this.set('isSearching', this.isSearching ? this.isSearching : false);
      this.set('hiddeButtonNew', this.hiddeButtonNew ? this.hiddeButtonNew : false);
    },
    clearFilter: function clearFilter() {
      clearTimeout(this.timeOut);
      this.set('searchValue', '');
      this.set('isSearching', false);
      this.filter('');
      this.onUpdateFilter('');
    },
    updateFilter: function updateFilter(searchValue) {
      var self = this;
      this.set('isSearching', true);
      this.onUpdateFilter(searchValue);

      if (searchValue.length == 0) {
        this.clearFilter();
        return;
      }

      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(function () {
        self.filter(searchValue);
      }, 350);
    },
    filter: function filter(searchValue) {
      if (searchValue.length == 0) {
        return;
      }

      this.set('isSearching', true);
      this.onFilter(searchValue);
    },
    actions: {
      toogleEdition: function toogleEdition() {
        if (this.get('isEditing')) {
          this.set('isEditing', false);
        } else {
          this.set('isEditing', true);
        }
      },
      showCreateModal: function showCreateModal() {
        if (!this.onShowCreateModal) {
          return;
        }

        this.onShowCreateModal();
      },
      clearFilter: function clearFilter() {
        this.clearFilter();
      },
      updateFilter: function updateFilter(searchValue) {
        this.updateFilter(searchValue);
      },
      filter: function filter(searchValue) {
        this.filter(searchValue);
      },
      __updateFilter: function __updateFilter(searchValue) {
        var self = this;
        /*
         * Sì se borró la cadena por completo, no se hace la busqueda.
         */

        if (searchValue.length == 0) {
          clearTimeout(this.timeOut);
          this.set('isSearching', false);
          return;
        }

        this.set('isSearching', true);
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(function () {
          self.filter(searchValue);
        }, 375);
      }
    }
  });

  _exports.default = _default;
});