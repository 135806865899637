define("comunidades-nafin/routes/respuestas-predefinidas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },
    setupController: function setupController(controller) {
      //post();
      $.ajax({
        url: constant.APIURL + 'consulta/listarrespuestaspredefinidas',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: 'start=0&limit=20',
        contentType: 'application/x-www-form-urlencoded',
        success: function success(r) {
          var respons = JSON.parse(r),
              categos = [],
              actual,
              indice = -1; //armar el arreglo como lo requiere ember

          respons.data.forEach(function (element, index) {
            if (actual != element.categoria_k) {
              indice++;
              actual = element.categoria_k;
              categos.push({
                categoria_k: element.categoria_k,
                nombre: element.categoria,
                cuenta: 0,
                cuentaString: 'Registro',
                childrens: []
              });
            }

            categos[indice].childrens.push(element);
            categos[indice].cuenta++;
            categos[indice].cuentaString = categos[indice].cuenta == 1 ? 'Registro' : ' Registros';
          });
          console.log(categos);
          controller.set('categorias', categos);
        }
      });
    },
    actions: {
      agregarTipo: function agregarTipo(a, f) {
        alert(a);
        alert(f);
      }
    }
  });

  _exports.default = _default;
});