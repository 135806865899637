define("comunidades-nafin/models/nom-035-stps/acontecimientos-traumaticos-severos/evidencia", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    evidencia_k: _emberData.default.attr('number'),
    nombre_usuario: _emberData.default.attr('string'),
    titulo: _emberData.default.attr('string'),
    descripcion: _emberData.default.attr('string'),
    evidencia_ruta_documento: _emberData.default.attr('string'),
    evidencia_nombre_documento: _emberData.default.attr('string'),
    fecha_registro: _emberData.default.attr('string'),
    activo: _emberData.default.attr('number', {
      defaultValue: function defaultValue() {
        return 1;
      }
    }),
    isActivo: function () {
      return this.get('activo') == 1 ? true : false;
    }.property('activo'),

    /* Atributos utilitarios */
    filtro: _emberData.default.attr('string'),
    isVisible: function () {
      /* Si no esta definida la variable mostramos sin mas*/
      if (this.get('filtro') == undefined) {
        return true;
      }

      if (this.get('nombre_usuario').toLowerCase().indexOf(this.get('filtro').toLowerCase()) > -1) {
        return true;
      }

      return false;
    }.property('filtro')
  });

  _exports.default = _default;
});