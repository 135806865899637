define("comunidades-nafin/components/communities-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarMisComunidades/',
        type: 'GET',
        contentType: 'application/x-www-form-urlencoded',
        data: 'start=20&limit=20&user=false',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r); //Fix Images path

          if (respons.data != undefined) {
            respons.data.forEach(function (item, idx) {
              if (respons.data[idx].img.indexOf('http') != 0) {
                respons.data[idx].img = solveImageURL(respons.data[idx].img);
              }

              if (respons.data[idx].descripcion.split(' ').length > 22) {
                respons.data[idx].descripcion = respons.data[idx].descripcion.split(' ').slice(0, 22).join(' ') + '...';
              }
            });
            self.setComunidades(respons.data);
            setTimeout(function () {
              Ember.$('#carousel_communities').carousel({
                interval: 5000
              });
              window.loadnewcarousel();
            }, 0);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    didRender: function didRender() {},
    setComunidades: function setComunidades(r) {
      this.set('comunidades', r);
    },
    actions: {
      comunidadEspecifica: function comunidadEspecifica(comunidad_k) {
        var url = "/#/comunidades/" + comunidad_k;
        sessionStorage.setItem("referrerComunidad", window.location.href);
        window.location.href = url;
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});