define("comunidades-nafin/routes/notificacion/envio-general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.resetNewEvent(this);
    },
    setupController: function setupController(controller) {}
  });

  _exports.default = _default;
});