define("comunidades-nafin/templates/faqs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JhbdCUK2",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,\"menu-header\"],false],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 text-center\"],[8],[0,\"\\n        \"],[6,\"h2\"],[8],[0,\"Preguntas Frecuentes\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[26,\"eq\",[[22,[\"theme\"]],\"nafin\"],null]],null,{\"statements\":[[0,\"\\n        \"],[1,[20,\"faqs/faqs-nafin\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,\"eq\",[[22,[\"theme\"]],\"universidad3m\"],null]],null,{\"statements\":[[0,\"\\n            \"],[1,[20,\"faqs/faqs-universidad-3m\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,\"eq\",[[22,[\"theme\"]],\"grupomexico\"],null]],null,{\"statements\":[[0,\"\\n                \"],[1,[20,\"faqs/faqs-grupo-mexico\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,\"eq\",[[22,[\"theme\"]],\"bmv\"],null]],null,{\"statements\":[[0,\"\\n                    \"],[1,[20,\"faqs/faqs-bmv\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,\"eq\",[[22,[\"theme\"]],\"aforebanamex\"],null]],null,{\"statements\":[[0,\"\\n                        \"],[1,[20,\"faqs/faqs-default\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[0,\"                        \"],[1,[20,\"faqs/faqs-default\"],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\\n\\n\\n\\n\\n\\n\\n\\n\\n\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/faqs.hbs"
    }
  });

  _exports.default = _default;
});