define("comunidades-nafin/components/notificacion/general/form-envio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this;
      $("#loading").hide();
      $('#ids_comunidad').select2({
        placeholder: "Selecciona",
        allowClear: true
      });
      $('#ids_comunidad').on('select2:unselect', function (e) {
        if ($("#todas").is(":checked")) {
          $("#todas").prop("checked", false);
        }
      });
      $("#todas").click(function () {
        if ($("#todas").is(':checked')) {
          $("#ids_comunidad > option").prop("selected", "selected"); // Select All Options

          $("#ids_comunidad").trigger("change"); // Trigger change to select 2
        } else {
          $("#ids_comunidad > option").removeAttr("selected");
          $("#ids_comunidad").trigger("change"); // Trigger change to select 2
        }
      });
      self.comunidades();
    },
    comunidades: function comunidades() {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'comunidad/listar',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        dataType: "json",
        async: false,
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          if (r.data) {
            console.log(r.data);
            var a = r.data.sort(function (a, b) {
              a = a.nombre.toLowerCase();
              b = b.nombre.toLowerCase();
              return a < b ? -1 : a > b ? 1 : 0;
            });
            console.log(a);
            self.set("comunidades", a);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      historico: function historico() {
        $("#historico").removeClass("d-none");
        $("#form-contenedor").addClass("d-none");
      },
      enviar: function enviar() {
        var error = false;
        var tipo = $("input[name='tipo']:checked").val();
        var comunidades = $("#ids_comunidad").val();
        var self = this;

        if ($("#title").val() == "" || $("#body").val() == "") {
          error = true;
          $('#modal-avisos .modal-body').text("Por favor indíque título, descripción y comunidades destino");
        } else if (comunidades == null) {
          error = true;
          $('#modal-avisos .modal-body').text("Por favor seleccione las comunidades destino");
        }

        if (!error) {
          $('#modal-avisos .modal-header').text('Aviso');
          $('#modal-avisos .modal-body').text("Enviando notificación, por favor espere");
          $('.modal-footer').hide();
          $('#modal-avisos').modal('show');
          $.ajax({
            url: constant.APIURL + 'pushnotification/enviarnotificaciongeneral',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType: "json",
            async: false,
            data: {
              title: $("#title").val(),
              body: $("#body").val(),
              comunidades: comunidades
            },
            xhrFields: {
              withCredentials: true
            },
            success: function success(r) {
              if (r.success) {
                $('#modal-avisos .modal-body').text("La notificación se ha enviado con éxito");
                $('.modal-footer').show();
                self.send("historico");
                $("#listarTodas").click().click();
              }
            },
            error: function error(e) {
              console.log(e);
            }
          });
        } else {
          $('#modal-avisos .modal-header').text('Aviso');
          $('#modal-avisos').modal('show');
        }
      }
    }
  });

  _exports.default = _default;
});