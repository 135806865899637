define("comunidades-nafin/services/cordova", ["exports", "ember-cli-cordova/services/cordova"], function (_exports, _cordova) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* jshint esnext:true */
  var _default = _cordova.default.extend({});

  _exports.default = _default;
});