define("comunidades-nafin/router", ["exports", "comunidades-nafin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // RUTAS PARA USUARIOS
    this.route('nom035/reportes', {
      path: '/administracion/reportes'
    });
    this.route('inicio', {
      path: '/'
    }); //this.route('inicio');

    this.route('comunidades');
    this.route('comunidades-movil');
    this.route('comunidades-offline');
    this.route('cursos-offline');
    this.route('ruta-offline');
    this.route('scorm-offline');
    this.route('registro');
    this.route('comunidades/comunidad-especifica', {
      path: '/comunidades/:comunidadid'
    });
    this.route('cursos');
    this.route('cursos/curso-especifico', {
      path: '/cursos/:cursoid'
    });
    this.route('diagnostico-empresarial');
    this.route('diagnostico-empresarial', {
      path: '/diagnostico-empresarial/:mis_diagnosticos'
    });
    this.route('webinars');
    this.route('noticias');
    this.route('eventos');
    this.route('foros');
    this.route('foros/foro-especifico', {
      path: '/foros/:foroid'
    });
    this.route('mediateca');
    this.route('consultas'); //this.route('consultas/consulta-especifica', { path: '/consultas/:consultaid' });

    this.route('consultas/consulta-especifica', {
      path: '/consultas/consulta-especifica'
    });
    this.route('directorio');
    this.route('resultados-busqueda');
    this.route('notificaciones');
    this.route('logros');
    this.route('perfil');
    this.route('cambiar-contrasenia');
    this.route('buscador'); // RUTAS PARA ADMINISTRADORES

    this.route('dashboard');
    this.route('catalogo-cursos');
    this.route('catalogo-evaluaciones');
    this.route('vista-previa-evaluacion');
    this.route('catalogo-evaluaciones-especifico');
    this.route('catalogo-certificado');
    this.route('catalogo-encuestas-especifico');
    this.route('rutas-aprendizaje-especifico', function () {
      this.route('rutas-objetivo');
      this.route('rutas-alumnos');
      this.route('rutas-agrupamiento');
      this.route('rutas-documentos');
    });
    this.route('administracion-comunidades');
    this.route('administracion-logros');
    this.route('administracion-diccionario');
    this.route('administracion-usuarios');
    this.route('nueva-comunidad');
    this.route('comunidad-detalle');
    this.route('repositorio-preguntas');
    this.route('ruta-apredizaje');
    this.route('catalogo-encuestas');
    this.route('respuestas-predefinidas');
    this.route('tipos-de-respuesta');
    this.route('usuarios');
    this.route('reportes');
    this.route('examples'); //this.route('contactos');

    this.route('historico', {
      path: '/historico/:historicoid'
    });
    this.route('historico');
    this.route('preguntas-diagnostico');
    this.route('preguntas-diagnostico', {
      path: '/preguntas-diagnostico/:preguntasdiagnosticoid'
    });
    this.route('preguntas-new');
    this.route('preguntas-new', {
      path: '/preguntas-new/:preguntasdiagnosticoid'
    });
    this.route('aviso-privacidad');
    this.route('admin-nuevousuario');
    this.route('experiments', function () {
      this.route('testing-area');
    });
    this.route('administracion-cursos');
    this.route('administracion-cursos-curso-especifico', {
      path: '/administracion-cursos/:cursoid'
    });
    this.route('recurso-no-disponible');
    this.route('faqs');
    this.route('human-talent', function () {
      this.route('sandbox');
    });
    this.route('notificacion', function () {
      this.route('envio-general');
    });
    this.route('nom-035-stps', function () {
      this.route('reportes', function () {
        this.route('administrador', function () {
          this.route('audiencia');
          this.route('acontecimientos-traumaticos-severos');
          this.route('entorno-organizacional');
          this.route('factores-riesgo-psicosocial');
        });
      });
      this.route('acontecimientos-traumaticos-severos', function () {
        this.route('administrador', function () {
          this.route('seguimiento');
          this.route('evidencias', function () {
            this.route('guias-aplicadas');
            this.route('politicas-de-riesgo');
            this.route('distribucion-guias');
            this.route('estatus-aplicacion-guias');
            this.route('colaboradores-con-ats');
            this.route('bienvenida');
            this.route('acciones-internas');
            this.route('guias-colaboradores-ats');
            this.route('evidencias-usuarios-ats');
            this.route('capacitaciones-certificados');
            this.route('plan-de-trabajo');
            this.route('resultados-guia-2');
            this.route('resultados-guia-3');
            this.route('estatus-de-capacitacion');
            this.route('consultas');
          });
          this.route('acciones-internas', function () {
            this.route('evidencias');
          });
          this.route('evidencias-bienvenida');
        });
        this.route('acciones-internas', function () {});
      });
    });
    this.route('higienizacion-covid19', function () {
      this.route('reportes', function () {
        this.route('administrador', function () {
          this.route('resultados-generales');
        });
      });
    });
    this.route('preguntas-test');
    this.route('comunidades_nafin', function () {
      this.route('app', function () {
        this.route('templates', function () {
          this.route('nom-035-stps', function () {
            this.route('acontecimientos-traumaticos-severos', function () {
              this.route('acciones-internas', function () {});
            });
          });
        });
      });
    });
  });
  Router.reopen({
    location: 'hash'
  });
  var _default = Router;
  _exports.default = _default;
});