define("comunidades-nafin/react-component", ["exports", "react-dom"], function (_exports, _reactDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * We don't need a template since we're only creating a
     * wrapper for our React component
     **/
    layout: '',

    /**
     * Renders a react component as the current ember element
     * @param {React.Component} reactComponent. e.g., <HelloWorld />
     */
    reactRender: function reactRender(reactComponent) {
      _reactDom.default.render(reactComponent, this.element);
    },

    /**
     * Removes a mounted React component from the DOM and
     * cleans up its event handlers and state.
     */
    unmountReactElement: function unmountReactElement() {
      _reactDom.default.unmountComponentAtNode(this.element);
    },

    /**
     * Cleans up the rendered react component as the ember
     * component gets destroyed
     */
    willDestroyComponent: function willDestroyComponent() {
      this._super();

      this.unmountReactElement();
    }
  });

  _exports.default = _default;
});