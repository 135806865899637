define("comunidades-nafin/templates/components/examples-components/react-imported-example", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/5xePE9o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[13,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/examples-components/react-imported-example.hbs"
    }
  });

  _exports.default = _default;
});