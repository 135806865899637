define("comunidades-nafin/templates/components/video-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pMZEUrZb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[13,1],[0,\"\\n\\n    \"],[2,\" Modal \"],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"modal fade\"],[10,\"id\",\"tutorialModalCenter\"],[10,\"tabindex\",\"-1\"],[10,\"role\",\"dialog\"],[10,\"aria-labelledby\",\"tutorialModalCenterTitle\"],[10,\"aria-hidden\",\"true\"],[8],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",\"modal-dialog modal-dialog-centered\"],[10,\"role\",\"document\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"modal-content\"],[8],[0,\"\\n          \"],[6,\"div\"],[10,\"class\",\"modal-header\"],[8],[0,\"\\n            \"],[6,\"h5\"],[10,\"class\",\"modal-title\"],[10,\"id\",\"tutorialModalCenterLongTitle\"],[8],[0,\"Tutorial\"],[9],[0,\"\\n            \"],[6,\"button\"],[10,\"class\",\"close\"],[10,\"data-dismiss\",\"modal\"],[10,\"aria-label\",\"Close\"],[10,\"type\",\"button\"],[8],[0,\"\\n              \"],[6,\"span\"],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[6,\"div\"],[10,\"class\",\"modal-body\"],[8],[0,\"\\n            \"],[6,\"div\"],[10,\"id\",\"tutorialModalCenterBody\"],[8],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[6,\"div\"],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n            \"],[6,\"button\"],[10,\"class\",\"btn btn-secondary\"],[10,\"data-dismiss\",\"modal\"],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"cerrar\"]],[8],[0,\"Cerrar\"],[9],[0,\"\\n            \"],[6,\"button\"],[10,\"class\",\"btn btn-danger\"],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"ocultar\"]],[8],[0,\"No volver a mostrar\"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/video-tutorial.hbs"
    }
  });

  _exports.default = _default;
});