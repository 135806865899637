define("comunidades-nafin/templates/aviso-privacidad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tCnx6geU",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,\"menu-header\"],false],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/aviso-privacidad.hbs"
    }
  });

  _exports.default = _default;
});