define("comunidades-nafin/components/offline/scorm-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bus: Ember.inject.service('evaluacion-curso'),
    didInsertElement: function didInsertElement() {
      var self = this;
      document.addEventListener("deviceready", onDeviceReady, false);

      function onDeviceReady() {
        self.inicializar();
      }
    },
    inicializar: function inicializar() {
      var self = this;
      Ember.$('#loading').fadeOut('slow');
      Ember.$("#mainNav").addClass("d-none");
      Ember.$("body").css("padding-top", "0px");
      Ember.$(".menu-mobile").removeClass("show-standalone").addClass("hidden-standalone");

      if (device.platform == "Android") {
        Ember.$(".volver-curso-ios").remove();
        Ember.$("#SCO").addClass("frame-curso-android");
        Ember.$("#finishContent").addClass("frame-curso-android");
      } else {
        Ember.$(".volver-curso-android").remove();
        Ember.$("#SCO").addClass("frame-curso-ios");
        Ember.$("#finishContent").addClass("frame-curso-ios");
      }
    },
    actions: {
      volverAviso: function volverAviso() {
        Ember.$("#modal-aviso-actualizacion").modal("show");
      },
      volver: function volver() {
        // Validamos si existe 
        Ember.$("#modal-aviso-actualizacion").modal("hide");
        Ember.$("#loading").show();
        var url = window.location.href.split("#")[0] + "#/ruta-offline";
        window.location.href = url;
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});