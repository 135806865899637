define("comunidades-nafin/components/higienizacion-covid19/reportes/reports/resultados-generales-por-usuario-reporte", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    scopeParent: null,
    title: 'Detalle de resultado por usuario',
    clase: 'higienizacioncovid19/reporteadministrador',
    //'lms/raprendizaje',
    metodo: 'resultadosgeneralesdetalleporusuario',
    reporte: 'higienizacioncovid19/export/resultadosgeneralesdetalleporusuario',
    headers: [{
      dataIndex: 'nombre',
      title: 'Nombre'
    }, {
      dataIndex: 'apellido_paterno',
      title: 'Apellido Paterno'
    }, {
      dataIndex: 'apellido_materno',
      title: 'Apellido Materno'
    }, {
      dataIndex: 'email',
      title: 'Correo Electrónico'
    }, {
      dataIndex: 'genero',
      title: 'Género'
    }, {
      dataIndex: 'departamento',
      title: 'Departamento'
    }, {
      dataIndex: 'puesto',
      title: 'Puesto'
    }, {
      dataIndex: 'raprendizaje',
      title: 'Curso'
    }, {
      dataIndex: 'category',
      title: 'Estatus'
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.scopeParent !== null) {
        this.scopeParent.setScopeReporteDetallePorUsuario(this);
      }
    },
    setParamsRutaAprendizajeK: function setParamsRutaAprendizajeK(ra_k) {
      this.params.ra_k = ra_k;
    },
    setParamsDepartamentoK: function setParamsDepartamentoK(departamento_k) {
      this.params.departamento_k = departamento_k;
    }
  });

  _exports.default = _default;
});