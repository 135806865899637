define("comunidades-nafin/templates/components/nom-035-stps/reportes/charts/gauge-with-bands-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8TOFzX3o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[11,\"id\",[27,[\"chartdiv-\",[20,\"id\"]]]],[10,\"style\",\"width: 100%; height: 500px;\"],[8],[9],[0,\"\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/nom-035-stps/reportes/charts/gauge-with-bands-chart.hbs"
    }
  });

  _exports.default = _default;
});