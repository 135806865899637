define("comunidades-nafin/components/human-talent-components/h-t-reporte", ["exports", "comunidades-nafin/components/human-talent-components/h-t-component"], function (_exports, _hTComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTComponent.default.extend({
    clase: '',
    metodo: '',
    reporte: null,
    start: 0,
    pageSize: constant.PAGESIZE,
    total_records: 0,
    title: '',
    params: {},
    buttonPrevIsDisabled: true,
    buttonNextIsDisabled: true,
    buttonDownloadIsDisabled: false,
    data: null,
    headers: null,

    /**
     *
     **/
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('title', this.title);
      this.set('total_records', this.total_records);
      this.set('dataTable', this.data);
      this.set('headersTable', this.headers);
      this.set('buttonPrevIsDisabled', this.buttonPrevIsDisabled);
      this.set('buttonNextIsDisabled', this.buttonNextIsDisabled);
      this.set('buttonDownloadIsDisabled', this.buttonDownloadIsDisabled);

      if (this.reporte !== null) {
        this.set('buttonDownloadIsVisible', true);
      } else {
        this.set('buttonDownloadIsVisible', false);
      }
    },

    /**
     *
     **/
    didInsertElement: function didInsertElement() {
      this.loadData();
    },

    /**
     *
     */
    loadData: function loadData() {
      var self = this;

      if (String(this.metodo).length > 0 && String(this.clase).length > 0) {
        $.ajax({
          url: constant.APIURL + String(this.clase) + '/' + String(this.metodo),
          type: "post",
          data: {
            params: this.params,
            start: this.start
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: "json",
          success: function success(response) {
            if (response.success == true) {
              self.set('dataTable', response.data);
              self.set('total_records', response.numFilas);

              if (self.start + self.pageSize > self.total_records) {
                self.set('buttonNextIsDisabled', true);
              } else {
                self.set('buttonNextIsDisabled', false);
              }
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    },

    /**
     *
     */
    actions: {
      load: function load() {
        this.loadData();
      },
      reload: function reload() {
        this.loadData();
      },
      loadPrev: function loadPrev() {
        /* Hack para resolver error: Aun deshabilitado el botón permite hacer la carga de datos de la página anterior*/
        if (this.buttonPrevIsDisabled) {
          return;
        }
        /* Reducimos (de ser posible) en this.pageSize el paginador*/


        if (this.start - this.pageSize <= 0) {
          this.start = 0;
        } else {
          this.start -= this.pageSize;
        }
        /* Gestionamos la visibilidad de los botones de navegación */


        if (this.start == 0) {
          this.set('buttonPrevIsDisabled', true);
        } else {
          this.set('buttonPrevIsDisabled', false);
        }

        if (this.total_records > this.pageSize) {
          this.set('buttonNextIsDisabled', false);
        } else {
          this.set('buttonNextIsDisabled', true);
        }
        /* Aplicamos el load del data */


        this.loadData();
      },
      loadNext: function loadNext() {
        /* Hack para resolver error: Aun deshabilitado el botón permite hacer la carga de datos de la página anterior*/
        if (this.buttonNextIsDisabled) {
          return;
        }
        /* Aumentamos (de ser posible) en this.pageSize el paginador*/


        if (this.start + this.pageSize < this.total_records) {
          this.start += this.pageSize;
        } else {
          /* Do nothing. We keeo the same value*/
          this.start = this.start;
        }
        /* Gestionamos la visibilidad de los botones de navegación */


        if (this.start >= this.pageSize) {
          this.set('buttonPrevIsDisabled', false);
        } else {
          this.set('buttonPrevIsDisabled', true);
        }

        if (this.start + this.pageSize > this.total_records) {
          this.set('buttonNextIsDisabled', true);
        } else {
          this.set('buttonNextIsDisabled', false);
        }

        this.loadData();
      },
      downloadReporte: function downloadReporte() {
        var self = this;
        this.set('buttonDownloadIsDisabled', true);
        document.location.href = constant.APIURL + String(this.reporte) + '/' + JSON.stringify(this.params);
        setTimeout(function () {
          self.set('buttonDownloadIsDisabled', false);
        }, 3500);
      }
    }
  });

  _exports.default = _default;
});