define("comunidades-nafin/templates/components/wizard-achievement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YXynooGa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"img\"],[10,\"src\",\"https://dummyimage.com/700x150/fff/000.jpg&text=componente_dinámico\"],[10,\"class\",\"box_shado full_width\"],[8],[9],[0,\"\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/wizard-achievement.hbs"
    }
  });

  _exports.default = _default;
});