define("comunidades-nafin/templates/components/admin-components/boton-admin-eventos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bfdBifgF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[13,1],[0,\"\\n\\n\"],[6,\"button\"],[10,\"class\",\"btn btn-success btn_fullwidth\"],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"editarEventos\"]],[8],[0,\" \"],[6,\"i\"],[10,\"class\",\"fa fa-edit\"],[8],[9],[0,\" Editar eventos\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/admin-components/boton-admin-eventos.hbs"
    }
  });

  _exports.default = _default;
});