define("comunidades-nafin/components/offline/lista-comunidades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bus: Ember.inject.service('evaluacion-curso'),
    didInsertElement: function didInsertElement() {
      var self = this;
      document.addEventListener("deviceready", onDeviceReady, false);

      function onDeviceReady() {
        self.inicializar();
      }
    },
    inicializar: function inicializar() {
      var self = this;
      var comunidades = localStorage.getItem("comunidades_ras");
      Ember.$('#loading').fadeOut('slow');
      Ember.$("body").addClass("panel-gris");
      Ember.$("#panel").addClass("panel-gris");
      Ember.$(".menu-mobile .online").addClass("d-none");
      Ember.$(".menu-mobile .offline").removeClass("d-none");

      if (comunidades != null) {
        var comunidadesObjeto = JSON.parse(comunidades);
        var ra_descargadas = localStorage.getItem('ra_descargadas');
        var nuevoObjetoComunidad = [];
        var pathDownloads = "";
        if (typeof device === 'undefined') window.location.reload();

        if (device.platform == "Android") {
          pathDownloads = cordova.file.dataDirectory;
        } else {
          pathDownloads = cordova.file.documentsDirectory;
        }

        if (ra_descargadas != null) {
          var rutas = JSON.parse(ra_descargadas);

          for (var i = 0; i < comunidadesObjeto.length; i++) {
            var mostrarComunidad = false;

            for (var j = 0; j < rutas.length; j++) {
              if (rutas[j][0].comunidades.includes(comunidadesObjeto[i].comunidad_k)) {
                mostrarComunidad = true;
              }
            }

            if (mostrarComunidad) {
              comunidadesObjeto[i].imagen = window.Ionic.WebView.convertFileSrc(pathDownloads + comunidadesObjeto[i].imagen).replace("undefined", "");

              if (comunidadesObjeto[i].imagen.search("null") >= 0) {
                window.location.reload();
              }

              nuevoObjetoComunidad.push(comunidadesObjeto[i]);
            }
          }
        }

        self.set("comunidades-cards", nuevoObjetoComunidad);
      }
    },
    actions: {
      cargarRuta: function cargarRuta(comunidad_k) {
        Ember.$("#loading").show();
        localStorage.setItem("comunidad_k", comunidad_k);
        var secciones = document.location.href.split("index");
        secciones = document.location.href.split("#");
        window.location.href = secciones[0] + "#/cursos-offline";
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});