define("comunidades-nafin/templates/higienizacion-covid19/reportes/administrador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K2XiBzRM",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/reportes-administrador-nom-035.css\"],[8],[9],[0,\"\\n\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\n\\n    \"],[6,\"div\"],[10,\"class\",\"accordion\"],[10,\"id\",\"accordionExample\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n             \\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"row mb-2\"],[8],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\" \"],[9],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"row mb-2\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n            \"],[1,[20,\"outlet\"],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\"\\n\"],[1,[20,\"sub-footer\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/higienizacion-covid19/reportes/administrador.hbs"
    }
  });

  _exports.default = _default;
});