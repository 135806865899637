define("comunidades-nafin/templates/cursos/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LM8Nxa6J",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"id\",\"loading\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"id\",\"loader\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"id\",\"shadow\"],[8],[9],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"id\",\"box\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\t\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/cursos/loading.hbs"
    }
  });

  _exports.default = _default;
});