define("comunidades-nafin/routes/logros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 3000);
      });
      return promise;
    },
    loadGeneralData: function loadGeneralData(controller) {
      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
        contentType: 'application/x-www-form-urlencoded',
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: {
          'method': 'get_catalog'
        },
        success: function success(response_text) {
          var data = JSON.parse(response_text);
          controller.set('general', data);
          self.loadSpecificData(controller);
        }
      });
    },
    loadRankingData: function loadRankingData(controller) {
      var self = this;
      controller.set('usuario', self.session.userData.usuario_k);
      Ember.$.ajax({
        url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
        contentType: 'application/x-www-form-urlencoded',
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: {
          'method': 'get_user_ranking',
          data: {
            id_usuario: parseInt(self.session.userData.usuario_k)
          }
        },
        success: function success(response_text) {
          var data = JSON.parse(response_text);
          controller.set('ranking', data.data);
          setTimeout(function () {
            Ember.$('.selected').parent().addClass('selected');
          }, 1000);
          Ember.$('#loading').fadeOut('slow');
        },
        error: function error() {
          Ember.$('#loading').fadeOut('slow');
        }
      });
    },
    loadSpecificData: function loadSpecificData(controller) {
      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
        contentType: 'application/x-www-form-urlencoded',
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: {
          'method': 'get_user_data',
          data: {
            id_usuario: parseInt(self.session.userData.usuario_k)
          }
        },
        success: function success(response_text) {
          var data = JSON.parse(response_text);
          controller.set('userdata', data);

          if (data.data && data.data.nivel) {
            self.createWizard(parseInt(data.data.nivel.id_nivel) - 1);
          } else {
            self.createWizard(0);
          }

          self.checkForModalAlerts(controller);
        }
      });
    },
    createWizard: function createWizard(level) {
      setTimeout(function () {
        //window.loadnewwizard()
        Ember.$(".icons-tab-steps").steps({
          headerTag: "h6",
          bodyTag: "fieldset",
          transitionEffect: "fade",
          titleTemplate: '<span class="step">#index#</span> #title#',
          labels: {
            finish: "Submit"
          },
          onFinished: function onFinished(e, t) {},
          startIndex: level,
          enableCancelButton: false,
          enableFinishButton: false,
          enablePagination: false,
          enableAllSteps: false
        });
      }, 0);
    },
    checkForModalAlerts: function checkForModalAlerts(controller) {
      var self = this;
      var general = controller.get('general');
      var userdata = controller.get('userdata'); //Nivel

      if (userdata && userdata && userdata.nivel && userdata.nivel.nuevo) {
        var html = '';
        html += '<div clas="text-center">';
        html += '<img src="' + userdata.nivel.img_nivel + '"> <br> <br>';
        html += '<h3>¡FELICIDADES!</h3> <br> <br>';
        html += '<h4>Has alcanzado el nivel ' + userdata.nivel.nombre_nivel + '</h4>';
        html += '<h5>SOBRESALIENTE</h5>';
        html += '<br><br><br><br><br>';
        html += '</div>';
        Ember.$('#modalLogrosContent').html(html);
        Ember.$('#modalLogros').fadeIn('slow');
        userdata.nivel.nuevo = false;
        controller.set('userdata', userdata);
        Ember.$.ajax({
          url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
          contentType: 'application/x-www-form-urlencoded',
          xhrFields: {
            withCredentials: true
          },
          type: 'POST',
          data: {
            'method': 'checked_user_level',
            data: {
              id_usuario: parseInt(self.session.userData.usuario_k)
            }
          }
        });
      } else if (userdata && userdata && userdata.insignias) {
        var flag = false;
        Object.keys(userdata.insignias).forEach(function (key) {
          if (userdata.insignias[key] && userdata.insignias[key].nuevo && flag == false) {
            var _html = '';
            _html += '<div clas="text-center">';
            _html += '<img src="' + userdata.insignias[key].img_insignia + '"> <br> <br>';
            _html += '<h3>¡FELICIDADES!</h3> <br> <br>';
            _html += '<h4>Has obtenido la insignia</h4>';
            _html += '<h5>' + userdata.insignias[key].nombre_insignia.toUpperCase() + '</h5>';
            _html += '<br><br><br><br><br>'; //html += '<button class="btn btn-default btn-bg" onclick="(function (){ jQuery(\'#modalLogros\').fadeOut(\'slow\');jQuery(\'#modalLogrosContent\').html(\'\'); })(); return false;">Cerrar</button>'; 

            _html += '</div>';
            Ember.$('#modalLogrosContent').html(_html);
            Ember.$('#modalLogros').fadeIn('slow');
            userdata.insignias[key].nuevo = false;
            controller.set('userdata', userdata);
            flag = true;
            Ember.$.ajax({
              url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
              contentType: 'application/x-www-form-urlencoded',
              xhrFields: {
                withCredentials: true
              },
              type: 'POST',
              data: {
                'method': 'checked_user_insignia',
                data: {
                  id_usuario: parseInt(self.session.userData.usuario_k),
                  usuario_k: parseInt(self.session.userData.usuario_k),
                  id_insignia: key,
                  insignia_k: userdata.insignias[key].insignia_k
                }
              }
            });
          }
        });
      }
    },
    setupController: function setupController(controller) {
      this.loadGeneralData(controller);
      this.loadRankingData(controller);
      controller.set("user_photo", this.session.userData.foto);
      controller.set("img_niveles_principiante", window.solveImageURL('uploads/logros/img_niveles_principiante.png'));
    },
    actions: {
      showModalPuntos: function showModalPuntos() {
        Ember.$('#modalPuntos').modal();
      },
      closeModalLogros: function closeModalLogros() {
        Ember.$('#modalLogros').fadeOut('slow');
        Ember.$('#modalLogrosContent').html('');
        this.checkForModalAlerts(this.controller);
      }
    }
  });

  _exports.default = _default;
});