define("comunidades-nafin/templates/components/examples-components/file-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ff9VbRe2",
    "block": "{\"symbols\":[\"dropzone\",\"queue\",\"&default\"],\"statements\":[[4,\"file-dropzone\",null,[[\"name\"],[\"imagenes\"]],{\"statements\":[[4,\"if\",[[21,1,[\"active\"]]],null,{\"statements\":[[4,\"if\",[[21,1,[\"valid\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",\"upload-container\"],[8],[0,\"\\n          Arrastra hasta aquí tus imagenes\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      No válido\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[21,2,[\"files\",\"length\"]]],null,{\"statements\":[[0,\"    Subiendo \"],[1,[21,2,[\"files\",\"length\"]],false],[0,\" archivos. (\"],[1,[21,2,[\"progress\"]],false],[0,\"%)\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"h4\"],[8],[0,\"Subir Imagenes\"],[9],[0,\"\\n    \"],[6,\"h4\"],[8],[0,\"\\n\"],[4,\"if\",[[21,1,[\"supported\"]]],null,{\"statements\":[[0,\"          Arrastra hasta aquí tus imagenes o\\n\"]],\"parameters\":[]},null],[4,\"file-upload\",null,[[\"name\",\"for\",\"accept\",\"multiple\",\"onfileadd\"],[\"imagenes\",\"upload-image\",\"image/*\",false,[26,\"action\",[[21,0,[]],\"uploadImage\"],null]]],{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"style\",\"color: #0056b3; font-size: 13px\"],[10,\"tabindex\",\"0\"],[8],[0,\"Selecciona una imagén.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2]},null],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"imageUploaded\"]]],null,{\"statements\":[[0,\"  \"],[6,\"p\"],[10,\"style\",\"font-size: 10px; margin-top: 20px;\"],[8],[0,\"Se ha subido la imagen \"],[1,[22,[\"imageUploaded\",\"client_name\"]],false],[9],[0,\"\\n  \"],[6,\"img\"],[10,\"class\",\"banner_upload\"],[11,\"src\",[27,[[22,[\"imageUploaded\",\"path\"]]]]],[10,\"id\",\"imagen_seleccionada\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[13,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/examples-components/file-uploader.hbs"
    }
  });

  _exports.default = _default;
});