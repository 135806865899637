define("comunidades-nafin/templates/historico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lEkjtwsk",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/diagnostico-empresarial.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\" \\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"row margin_ud_10px\"],[8],[0,\"\\n            \"],[1,[26,\"outlet\",[\"preguntas\"],null],false],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n                \"],[6,\"button\"],[10,\"class\",\"btn btn-light\"],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"goBack\"]],[8],[1,[26,\"fa-icon\",[\"angle-left\"],[[\"prefix\"],[\"fas\"]]],false],[9],[0,\"\\n                \"],[6,\"span\"],[10,\"id\",\"tipoRegreso\"],[8],[9],[6,\"strong\"],[10,\"class\",\"text-muted\"],[10,\"id\",\"seccionAnterior\"],[8],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[1,[20,\"historic-table\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/historico.hbs"
    }
  });

  _exports.default = _default;
});