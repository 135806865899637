define("comunidades-nafin/components/human-talent-components/charts/simple-pie-chart", ["exports", "comunidades-nafin/components/human-talent-components/h-t-chart"], function (_exports, _hTChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTChart.default.extend({
    pieSeries: null,

    /**
     *
     */
    getTypeChart: function getTypeChart() {
      return am4charts.PieChart;
    },

    /**
     * setupChart -
     *
     *
     */
    setupChart: function setupChart() {
      this._super.apply(this, arguments);

      this.setSeries();
    },

    /**
     *
     */
    setSeries: function setSeries() {
      var pieSeries = this.chart.series.push(new am4charts.PieSeries());

      if (this.pieSeries && this.pieSeries.dataFields && this.pieSeries.dataFields.value) {
        pieSeries.dataFields.value = this.pieSeries.dataFields.value;
      } else {
        pieSeries.dataFields.value = 'value';
      }

      if (this.pieSeries && this.pieSeries.dataFields && this.pieSeries.dataFields.category) {
        pieSeries.dataFields.category = this.pieSeries.dataFields.category;
      } else {
        pieSeries.dataFields.category = 'category';
      }

      pieSeries.slices.template.tooltipText = "{tooltip}";
    }
  });

  _exports.default = _default;
});