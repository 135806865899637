define("comunidades-nafin/components/forums-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // http://www.lmshumantalent.com/develop_28082018/index.php/foro/listarForosPreguntas
    // http://www.lmshumantalent.com/develop_28082018/index.php/foro/listarPreguntasRecientes
    socket: {},
    comunidadK: "",
    arrayColor: ['000', '9C27B0', 'b0272b', 'ff9800', '009688', 'ff5722'],
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
    },
    iniciar_socket: function iniciar_socket() {
      var self = this._super.apply(this, arguments);

      self.socket.socket.emit('switchRoom', "ForosListadoGenerales"); // Detectamos la desconexion

      self.socket.socket.on('disconnect', function () {}); // Detectamos la conexion

      self.socket.socket.on('connect', function () {
        self.socket.socket.emit('switchRoom', "ForosListadoGenerales");
      });
      self.socket.socket.on('nuevaPregunta', function (data, success, parametrosAdicionales, updateforoPreguntaAgregar) {
        if (success) {
          var comunidades = self.get("comunidades").toArray();

          for (var i = 0; i < comunidades.length; i++) {
            if (comunidades[i].comunidad_k == parametrosAdicionales.comunidad_k) Ember.set(comunidades[i], "numero_preguntas_foro", parseInt(comunidades[i].numero_preguntas_foro) + 1);
          }

          if (self.comunidadK == "") {
            self.cargarPreguntas();
          } else if (self.comunidadK == parametrosAdicionales.comunidad_k) {
            var preguntas = parseInt(Ember.$("#comunidad_" + self.comunidadK).find("span.badge").text() != "" ? Ember.$("#comunidad_" + self.comunidadK).find("span.badge").text() : 0);
            Ember.$("#comunidad_" + self.comunidadK).find("span.badge").text(preguntas + 1);
            self.send('listarPreguntas', self.comunidadK);
          }
        } else {
          if (self.session.userData.usuario_k == data.usuario_creacion) {
            Ember.$('#msg_usuario_bloqueado').modal({
              keyboard: false,
              backdrop: 'static'
            });
          }
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      self.set("activarRecientes", true);
      self.set("titulo_foro", "Temas recientes");
      this.iniciar_socket();
      this.cargarPreguntas();
    },
    cargarPreguntas: function cargarPreguntas() {
      var self = this;
      var endpoint;

      if (self.session.isAdmin) {
        endpoint = 'comunidad/listar';
      } else {
        endpoint = 'comunidad/listarMisComunidades';
      }

      var url_page = window.location.href;
      var id = url_page.substring(url_page.lastIndexOf('/') + 1);
      Ember.$.ajax({
        url: constant.APIURL + endpoint,
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        success: function success(response) {
          var r = JSON.parse(response); // Cargamos las comunidades

          self.set('seeMore', 10);
          self.set('comunidades', r.data);
          var requests = []; //Preparamos las multiples peticiones AJAX

          Object.keys(r.data).forEach(function (idx) {
            requests.push(Ember.$.ajax({
              url: constant.APIURL + 'foro/listarPreguntasRecientes',
              type: 'POST',
              xhrFields: {
                withCredentials: true
              },
              contentType: 'application/x-www-form-urlencoded',
              data: 'params={"comunidad_k":"' + r.data[idx].comunidad_k + '"}'
            }));
          }); //Atenderemos la respuesta de todas las peticiones solo hasta que se ejecuten todas

          var defCalls = function defCalls() {
            var def = Ember.$.Deferred();
            Ember.$.when.apply(Ember.$, requests).then(function () {
              def.resolve(arguments);
            });
            return def.promise();
          };

          defCalls().done(function (arr) {
            var questions = [];

            var _loop = function _loop() {
              var tmp = arr[i][0];

              try {
                tmp = JSON.parse(tmp).data;
                Object.keys(tmp).forEach(function (idx) {
                  questions.push(tmp[idx]);
                });
              } catch (ex) {
                console.log(ex);
              }
            };

            for (var i = 0; i < arr.length; i++) {
              _loop();
            } //Formato de fechas


            Object.keys(questions).forEach(function (idx) {
              var date_time = questions[idx].timestamp_creacion.split(' ');
              var date = date_time[0].split('-');

              if (questions[idx].pregunta == "undefined") {
                questions[idx].pregunta = 'No hay pregunta asociada';
              }

              questions[idx].dayFormath = date[2];
              questions[idx].monthFormath = date[1];
              questions[idx].yearFormath = date[0];
              var secondColor = Math.floor(Math.random() * 6);

              if (questions[idx].foto.split('/')[0] === 'uploads') {
                questions[idx].foto = solveImageURL(questions[idx].foto);
              } else {
                var nombre = questions[idx].full_name.split('');
                var img = nombre[0].toUpperCase();
                questions[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
              }
            }); //Ordenamiento de fechas				  			

            var arr_fecha = [];
            var arr_temp = [];
            Object.keys(questions).forEach(function (idx) {
              var fecha_completa = questions[idx].fecha_orden; //.split(' ');

              questions[idx].fecha_completa = fecha_completa;
              arr_fecha.push(questions[idx]);
            });
            arr_fecha.sort(function (a, b) {
              return a.fecha_completa < b.fecha_completa ? 1 : b.fecha_completa < a.fecha_completa ? -1 : 0;
            });
            self.set('all_forum', arr_fecha);
            self.set('limit_forum', 12);

            for (var index = 0; index < self.get('limit_forum'); index++) {
              var element = arr_fecha[index];
              arr_temp.push(element);
            }

            Object.keys(arr_temp).forEach(function (idx) {
              if (arr_temp[idx] == null) {
                self.set('foro_reciente', '');
              } else {
                self.set('foro_reciente', arr_temp);
              }
            });
            Ember.$('#loading').fadeOut('slow');
          });
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    didUpdate: function didUpdate() {
      Ember.$('#terms_foro').change(function () {
        if (this.checked) {
          Ember.$('#send_foro').removeAttr('disabled');
        } else {
          Ember.$('#send_foro').attr("disabled", "disabled");
        }
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setStoreForo(this.storeForo ? this.storeForo : this.setStoreForo());
    },
    setStoreForo: function setStoreForo(comunidad) {
      var id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      var data_string = 'params={"comunidad_k":}';

      var _self = this;

      Ember.$.ajax({
        url: constant.APIURL + 'foro/listar',
        type: 'POST',
        async: false,
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: data_string,
        success: function success(response_text) {
          var data = JSON.parse(response_text);
          /* if( data.data.length == 0 ){
           	_self.set( 'storeForo',	[] 					);
           }else{
          let foro_object = data.data[0];
          foro_object.ruta =  solveResourceURL(foro_object.ruta);
           	_self.set( 'storeForo',	foro_object );
           }*/

          _self.set('storeForo', []);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    setForosRecientes: function setForosRecientes(r) {
      this.set('foro_reciente', r);
    },
    getFormObject: function getFormObject() {
      var unindexed_array = Ember.$('#formaddpregunta').serializeArray();
      var indexed_array = {};
      Ember.$.map(unindexed_array, function (n, i) {
        if (n['value'] != 'xxxx') indexed_array[n['name']] = n['value'];
      });
      return indexed_array;
    },
    actions: {
      cambioComunidad: function cambioComunidad(val) {
        var self = this;
        Ember.set(self.storeForo, "ruta", solveResourceURL(Ember.$("#filtrov").find("option:selected").data("ruta")));
      },
      finalmenteEditarPregunta: function finalmenteEditarPregunta() {
        var obj = this.getFormObject();
        var comunidad = Ember.$('#filtrov').find('option:selected').val();
        var nombre_comunidad = Ember.$('#filtrov').find('option:selected').text();
        var comunidad_k = Ember.$('#filtrov').find('option:selected').data('comunidad');
        var errorPregunta = false;

        if (comunidad == "") {
          errorPregunta = true;
          Ember.$("#tituloErrorPregunta").text("Alerta");
          Ember.$("#bodyErrorPregunta").text("Por favor seleccione la comunidad");
        } else if (Ember.$('#text_edit_pregunta').val() == "") {
          errorPregunta = true;
          Ember.$("#tituloErrorPregunta").text("Alerta");
          Ember.$("#bodyErrorPregunta").text("Por favor escriba la pregunta");
        } else if (!Ember.$('#terms_foro')[0].checked) {
          errorPregunta = true;
          Ember.$("#tituloErrorPregunta").text("Alerta");
          Ember.$("#bodyErrorPregunta").text("Por favor indique la opción de aceptar las reglas del foro");
        }

        if (errorPregunta) {
          Ember.$('#modal_validar_pregunta').modal({
            keyboard: false,
            backdrop: 'static'
          });
          return false;
        } else if (!validateDictionary(Ember.$('#text_edit_pregunta').val())) {
          return false;
        }

        Ember.$('#loading').fadeIn('slow');
        var self = this;
        var msg = {
          foro_k: obj.comunidad_k,
          pregunta: obj.text_pregunta,
          usuario_creacion: this.session.userData.usuario_k
        };
        var msg2 = {
          nombreEnviado: this.session.userData.nombre,
          token_foro: "",
          habilitar_notificacion_foro: "0",
          ruta_foro: "uploads/comunidades/foros/reglasynormas/reglasynormasforos.pdf",
          comunidad_k: comunidad_k,
          nombre: nombre_comunidad
        };

        if (Ember.$('#filtrov').val() != 'xxxx' && Ember.$('#text_edit_pregunta').val() != "") {
          self.socket.socket.emit('sendForoPregunta', msg, msg2);
          Ember.$('#text_edit_pregunta').val('');
          Ember.$('#filtrov').val('xxxx');
          Ember.$('#terms_foro').prop("checked", false);
          Ember.$('#window_add_pregunta').modal('toggle');
          Ember.$('#loading').fadeOut('slow');
        } else {}
      },
      //@joshua 18 oct 18
      agregarPregunta: function agregarPregunta() {
        Ember.$('#window_add_pregunta').modal({
          keyboard: false,
          backdrop: 'static'
        });
      },
      recent_foro: function recent_foro(listaComunidad) {
        var self = this;
        Ember.$("#seeMoreForum").removeClass("d-none");
        listaComunidad.forEach(function (comunidadEmber) {
          Ember.set(comunidadEmber, "token_foro", true);
        });
        self.set("activarRecientes", true);
        var endpoint = 'comunidad/listarMisComunidades';

        if (self.session.isAdmin) {
          endpoint = 'comunidad/listar';
        }

        Ember.$.ajax({
          url: constant.APIURL + endpoint,
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(response) {
            var r = JSON.parse(response);
            var requests = []; //Preparamos las multiples peticiones AJAX

            Object.keys(r.data).forEach(function (idx) {
              requests.push(Ember.$.ajax({
                url: constant.APIURL + 'foro/listarPreguntasRecientes',
                type: 'POST',
                xhrFields: {
                  withCredentials: true
                },
                contentType: 'application/x-www-form-urlencoded',
                data: 'params={"comunidad_k":"' + r.data[idx].comunidad_k + '"}'
              }));
            }); //Atenderemos la respuesta de todas las peticiones solo hasta que se ejecuten todas

            var defCalls = function defCalls() {
              var def = Ember.$.Deferred();
              Ember.$.when.apply(Ember.$, requests).then(function () {
                def.resolve(arguments);
              });
              return def.promise();
            };

            defCalls().done(function (arr) {
              var questions = [];

              var _loop2 = function _loop2() {
                var tmp = arr[i][0];

                try {
                  tmp = JSON.parse(tmp).data;
                  Object.keys(tmp).forEach(function (idx) {
                    questions.push(tmp[idx]);
                  });
                } catch (ex) {
                  console.log(ex);
                }
              };

              for (var i = 0; i < arr.length; i++) {
                _loop2();
              } //Formato de fechas


              Object.keys(questions).forEach(function (idx) {
                var date_time = questions[idx].timestamp_creacion.split(' ');
                var date = date_time[0].split('-');

                if (questions[idx].pregunta == "undefined") {
                  questions[idx].pregunta = 'No hay pregunta asociada';
                }

                questions[idx].dayFormath = date[2];
                questions[idx].monthFormath = date[1];
                questions[idx].yearFormath = date[0];
                var secondColor = Math.floor(Math.random() * 6);

                if (questions[idx].foto.split('/')[0] === 'uploads') {
                  questions[idx].foto = solveImageURL(questions[idx].foto);
                } else {
                  var nombre = questions[idx].full_name.split('');
                  var img = nombre[0].toUpperCase();
                  questions[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
                }
              }); //Ordenamiento de fechas				  			

              var arr_fecha = [];
              Object.keys(questions).forEach(function (idx) {
                var fecha_completa = questions[idx].fecha_orden.split(' ');
                questions[idx].fecha_completa = fecha_completa[0];
                arr_fecha.push(questions[idx]);
              });
              arr_fecha.sort(function (a, b) {
                return a.fecha_completa < b.fecha_completa ? 1 : b.fecha_completa < a.fecha_completa ? -1 : 0;
              });
              self.set('foro_reciente', arr_fecha);
              Ember.$('#loading').fadeOut('slow');
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      change_foro: function change_foro(listaComunidad, comunidad) {
        var self = this;
        self.comunidadK = comunidad.comunidad_k;
        Ember.$("#seeMoreForum").addClass("d-none");
        Ember.$('#loading').fadeIn('slow');
        self.set("activarRecientes", false);
        listaComunidad.forEach(function (comunidadEmber) {
          Ember.set(comunidadEmber, "token_foro", true);
        });
        Ember.set(comunidad, "token_foro", false);
        self.set("titulo_foro", comunidad.nombre);
        self.send('listarPreguntas', comunidad.comunidad_k);
      },
      listarPreguntas: function listarPreguntas(comunidad_k) {
        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'foro/listarPreguntasRecientes',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params={"comunidad_k":"' + comunidad_k + '"}',
          success: function success(r) {
            r = JSON.parse(r);
            Object.keys(r.data).forEach(function (idx) {
              var date_time = r.data[idx].timestamp_creacion.split(' ');
              var date = date_time[0].split('-');
              var month = date[1];
              r.data[idx].dayFormath = date[2];
              r.data[idx].monthFormath = date[1];
              r.data[idx].yearFormath = date[0];
              var secondColor = Math.floor(Math.random() * 6);
              /*if (r.data[idx] != 'foto') {
              	var nombre = r.data[idx].full_name.split('');
              	var img = nombre[0].toUpperCase();
              	r.data[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
              }*/

              if (r.data[idx].foto.split('/')[0] === 'uploads') {
                r.data[idx].foto = solveImageURL(r.data[idx].foto);
              } else {
                var nombre = r.data[idx].full_name.split('');
                var img = nombre[0].toUpperCase();
                r.data[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
                r.data[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
              }
            });
            self.set('foro_reciente', r.data);
            Ember.$('#loading').fadeOut('slow');
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      seeMore: function seeMore() {
        Ember.$('#loading').fadeIn('slow');
        var arr_temp = [];
        var self = this;
        var limit = self.get('limit_forum') + 12;
        var new_limit = self.set('limit_forum', limit);
        var all_forum = self.get('all_forum');

        if (new_limit >= all_forum.length) {
          new_limit = all_forum.length;
          Ember.$('#seeMoreForum').hide();
        }

        for (var index = 0; index < new_limit; index++) {
          var element = all_forum[index];
          arr_temp.push(element);
        }

        self.set('foro_reciente', arr_temp);
        Ember.$('#loading').fadeOut('slow');
      }
    }
  });

  _exports.default = _default;
});