define("comunidades-nafin/templates/webinars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XQGc6OgL",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/cms.css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/webinars-cards.css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"https://use.fontawesome.com/releases/v5.2.0/css/all.css\"],[10,\"integrity\",\"sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ\"],[10,\"crossorigin\",\"anonymous\"],[8],[9],[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[6,\"main\"],[10,\"id\",\"panel\"],[10,\"class\",\"panel\"],[8],[0,\"\\n\"],[14,\"loading\",[]],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[10,\"id\",\"\"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-12 pt-2\"],[10,\"id\",\"\"],[8],[0,\"\\n              \"],[1,[20,\"webinars-cards\"],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \\n\"],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/webinars.hbs"
    }
  });

  _exports.default = _default;
});