define("comunidades-nafin/routes/administracion-diccionario", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.reloadwords();
    },
    reloadwords: function reloadwords() {
      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
        contentType: 'application/x-www-form-urlencoded',
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: {
          method: 'get_dictionary'
        },
        success: function success(response_text) {
          var response = JSON.parse(response_text);
          window.diccionario = [];
          Object.keys(response.data).forEach(function (key) {
            window.diccionario.push(key);
          });
          self.controller.set('diccionario', response.data);
        },
        error: function error(_error) {
          Ember.$('#add_restricted_word').modal('hide');
        }
      });
    },
    actions: {
      add_restricted_word: function add_restricted_word() {
        Ember.$('#add_restricted_word').modal('show');
      },
      addRestrictedWord: function addRestrictedWord(word) {
        var self = this;
        var word = Ember.$('#restricted-word').val().trim();

        if (word) {
          Ember.$.ajax({
            url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
            contentType: 'application/x-www-form-urlencoded',
            xhrFields: {
              withCredentials: true
            },
            type: 'POST',
            data: {
              method: 'add_dictionary_word',
              data: {
                word: word
              }
            },
            success: function success(response) {
              Ember.$('#add_restricted_word').modal('hide');
              self.reloadwords();
            },
            error: function error(_error2) {
              Ember.$('#add_restricted_word').modal('hide');
            }
          });
        }
      },
      deleteword: function deleteword(word, wordObject) {
        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'ranking/ranking/firebasealternativeservice',
          contentType: 'application/x-www-form-urlencoded',
          xhrFields: {
            withCredentials: true
          },
          type: 'POST',
          data: {
            method: 'remove_dictionary_word',
            data: {
              word: word,
              palabra_k: wordObject.palabra_k
            }
          },
          success: function success(response) {
            self.reloadwords();
          },
          error: function error(_error3) {
            console.log(_error3);
          }
        });
      }
    }
  });

  _exports.default = _default;
});