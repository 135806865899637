define("comunidades-nafin/components/events-calendar", ["exports", "npm:tui-calendar"], function (_exports, _npmTuiCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
        * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
        *
        *
        * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
        *
        *
        * @author  [YRM] Maurilio Cruz Morales
        *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
        *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
        *              Tel. celular (55) 6702 6252
        *
        * @date    2018-10-04
        * @param   void
        * @return  void
        */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.bindScopeToParent(this);
    },

    /*
     * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    bindScopeToParent: function bindScopeToParent(_scope) {
      this.onBindScopeToParent(_scope);
    },

    /*
     * propagationAfterCreateEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    propagationAfterCreateEvent: function propagationAfterCreateEvent(data_object) {
      this.createEvent(data_object.storeEvent);
    },

    /*
     * deleteEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @date    2018-10-11
     * @param   void
     * @return  void
     */
    createEvent: function createEvent(event_object) {
      var calendar = this.get('calendar');
      var _schedule_object = {
        id: event_object.calendario_k,
        calendarId: '1',
        title: event_object.nombre,
        category: 'time',
        dueDateClass: String(event_object.descripcion),
        start: event_object.fecha + 'T' + event_object.hora,
        end: event_object.fecha_fin + 'T' + event_object.hora_fin,
        location: event_object.lugar
      };
      calendar.createSchedules([_schedule_object]);
    },

    /*
     * propagationAfterUpdateEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    propagationAfterUpdateEvent: function propagationAfterUpdateEvent(data_object) {
      this.updateEvent(data_object.storeEvent);
    },

    /*
     * updateEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @date    2018-10-11
     * @param   void
     * @return  void
     */
    updateEvent: function updateEvent(event_object) {
      var calendar = this.get('calendar');
      var schedule_id = event_object.calendario_k;
      var schedule_calendar_id = '1';
      var schedule_object = {
        title: event_object.nombre,
        dueDateClass: String(event_object.descripcion),
        start: event_object.fecha + 'T' + event_object.hora,
        end: event_object.fecha_fin + 'T' + event_object.hora_fin,
        location: event_object.lugar
      };
      calendar.updateSchedule(schedule_id, schedule_calendar_id, schedule_object);
      /*
       * De momento no tenemos un elemento/variables que nos permita asegurar que el elementos modificado este en el card de detalle, por ello
       * lo ocultamos con cualuier edición del elemento.
       */

      Ember.$("#cardCurrentElement").attr('hidden', 'hidden');
    },

    /*
     * propagationAfterDeleteEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    propagationAfterDeleteEvent: function propagationAfterDeleteEvent(data_object) {
      this.deleteEvent(data_object.storeEvent);
    },

    /*
     * deleteEvent - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    deleteEvent: function deleteEvent(event_object) {
      var calendar = this.get('calendar');
      calendar.deleteSchedule(event_object.calendario_k, "1", false);
      /*
       * De momento no tenemos un elemento/variables que nos permita asegurar que el elementos modificado este en el card de detalle, por ello
       * lo ocultamos con cualuier edición del elemento.
       */

      Ember.$("#cardCurrentElement").attr('hidden', 'hidden');
    },

    /*
     * createCalendar - Devuelve un objecto de la Clase Calendar con configuraciones iniciales para este proyecto.
     *
     *
     * Devuelve un objecto de la Clase Calendar con configuraciones iniciales para este proyecto.
     *
     *
     * @author  [Artur] Arturo Amador Paulino
     *				amadorassassin@gmail.com
     *                  
     *
     * @date    2018-09-##
     * @param   void
     * @return  Calendar instancia del calendario
     */
    createCalendar: function createCalendar() {
      var _calendar = new _npmTuiCalendar.default('#calendar_event_page', {
        defaultView: 'month',
        taskView: true,
        scheduleView: true,
        template: {
          milestone: function milestone(schedule) {
            return '<span style="color:red;"><i class="fa fa-flag"></i> ' + schedule.title + '</span>';
          },
          milestoneTitle: function milestoneTitle() {
            return 'Milestone';
          },
          task: function task(schedule) {
            return '&nbsp;&nbsp;#' + schedule.title;
          },
          taskTitle: function taskTitle() {
            return '<label><input type="checkbox" />Task</label>';
          },
          allday: function allday(schedule) {
            return schedule.title + ' <i class="fa fa-refresh"></i>';
          },
          alldayTitle: function alldayTitle() {
            return 'All Day';
          },
          time: function time(schedule) {
            return schedule.title;
          }
        },
        month: {
          daynames: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
          startDayOfWeek: 0,
          narrowWeekend: true
        },
        week: {
          daynames: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
          startDayOfWeek: 0,
          narrowWeekend: true
        }
      });

      _calendar.setCalendarColor('1', {
        color: '#ffffff',
        bgColor: window.theme.eventos.calendario.evento.backgroundColor,
        borderColor: window.theme.eventos.calendario.evento.borderColor
      });

      return _calendar;
    },

    /*
        * setEventsToCalendar - Asigna acciones a los eventos necesarios del calendario.
        *
        *
        * Asigna acciones a los eventos necesarios del calendario.
        *
        * @author  [Artur] Arturo Amador Paulino
        *				amadorassassin@gmail.com
        *
        * @date    2018-09-##
        * @param   Calendar calendar Instancia de calendario al que agregar acciones.
        * @return  Calendar Calendario con las acciones integradas en los eventos.
        */
    setEventsToCalendar: function setEventsToCalendar(calendar) {
      var _this = this;

      calendar.on('clickDayname', function (event) {
        if (calendar.getViewName() === 'week') {
          calendar.setDate(new Date(event.date));
          calendar.changeView('day', true);
        }
      });
      calendar.on('clickSchedule', function (event) {
        Ember.$('#facebook_share_actual').data('event', event.schedule.id);
        Ember.$('#twitter_share_actual').data('event', event.schedule.id);
        Ember.$('#linkedin_share_actual').data('event', event.schedule.id);
        var schedule = event.schedule;
        var startDate = schedule.start._date;
        var endDate = schedule.end._date;
        var descripcion = schedule.dueDateClass;

        var arrayHoraFechaInicio = _this.formatCard(String(startDate));

        var arrayHoraFechaFin = _this.formatCard(String(endDate));

        Ember.$("#cardCurrentElement").removeAttr('hidden');
        Ember.$('#actualBigDay').html(arrayHoraFechaInicio[0].split(' ')[0]);
        Ember.$('#actualMonthBig').html(arrayHoraFechaInicio[0].split(' ')[2]);
        Ember.$('#titleCurrentEvent').html(schedule.title);
        Ember.$('#titleCurrentEvent').data('id', event.schedule.id);
        Ember.$('#currentEventStartEndDate').html('<i class="far fa-calendar"></i> ' + arrayHoraFechaInicio[0] + ' al ' + arrayHoraFechaFin[0]);
        Ember.$('#currentEventStartEndTime').html('<i class="far fa-clock"></i> ' + arrayHoraFechaInicio[1].substring(0, 5) + ' a ' + arrayHoraFechaFin[1].substring(0, 5));
        Ember.$('#locationCurrentEvent').html('<i class="fas fa-map-marker-alt"></i> ' + schedule.location);
        Ember.$('#currentEventDescription').html(descripcion);
        setTimeout(function () {
          Ember.$('#facebook_share_actual').unbind("click");
          Ember.$('#facebook_share_actual').click(function () {
            var evento_k = Ember.$('#facebook_share_actual').data('event');
            shareFB(constant.SHAREURL + 'evento/' + evento_k);
            window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_EVENTO, evento_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_FACEBOOK);
          });
          Ember.$('#twitter_share_actual').unbind("click");
          Ember.$('#twitter_share_actual').click(function (e) {
            // Selector al card del evento
            var elementoPadreDom = Ember.$(e.target).parent().parent().parent().parent().parent().parent().children(); // Arreglo con los elementos titulo, fecha, hora, lugar del card

            var detalleEventoDom = elementoPadreDom[1]; // Selector a tag ul de los elementos en detalle evento

            var dataEventoDom = Ember.$(detalleEventoDom).children()[0]; // Arreglo con elementos li con datos de titulo, fecha, hora, lugar del card

            var arregloDatosEventoDetalle = Ember.$(dataEventoDom).children();
            var titulo = arregloDatosEventoDetalle[0];
            var fecha = arregloDatosEventoDetalle[1];
            var hora = arregloDatosEventoDetalle[2];
            var lugar = arregloDatosEventoDetalle[3]; // obtenemos los strings generados en cada elemento

            var strTitulo = Ember.$(titulo).text();
            var strFecha = Ember.$(fecha).text();
            var strHora = Ember.$(hora).text();
            var strLugar = Ember.$(lugar).text();
            var evento_k = Ember.$('#twitter_share_actual').data('event');
            var twitterMsm = strTitulo + ' del ' + strFecha + ' de ' + strHora + ' en ' + strLugar;
            var url = constant.SHAREURL + 'evento/' + evento_k;
            var width = 575,
                height = 400,
                left = (Ember.$(window).width() - width) / 2,
                top = (Ember.$(window).height() - height) / 2,
                url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
                opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
            window.open(url, 'twitter', opts);
            window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_TWITTER_COMPARTIR_EVENTO, evento_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_TWITTER);
          });
          Ember.$('#linkedin_share_actual').unbind("click");
          Ember.$('#linkedin_share_actual').click(function (e) {
            var evento_k = Ember.$('#linkedin_share_actual').data('event');
            shareLI(constant.SHAREURL + 'evento/' + evento_k);
            window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_EVENTO, evento_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_LINKEDIN);
          });
        }, 100);
      });
      return calendar;
    },

    /*
     * didInsertElement - Called when the element of the view has been inserted into the DOM. Override this function to do any set up that requires an element in the document body.
     *
     *
     * Called when the element of the view has been inserted into the DOM. Override this function to do any set up that requires an element in the document body.
     *
     * @author  [Artur] Arturo Amador Paulino
     *				amadorassassin@gmail.com
     *
     * @date    2018-09-##
     * @param   void
     * @return  void
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _self = this;

      var params = new URLSearchParams(window.location.search);
      var d = new Date();
      var day = d.getDate();
      var month = d.getMonth() < 10 ? '0' + d.getMonth() : d.getMonth();
      var year = d.getFullYear();
      var date = new Date();
      var newDate = new Date(date.setDate(date.getDate() + 30));
      var newDay = newDate.getDate();
      var newYear = newDate.getFullYear();
      var newMonth = newDate.getMonth();
      var calendar = this.createCalendar();
      this.formatDate(calendar);
      calendar.toggleTaskView(false);
      Ember.$.ajax({
        url: constant.APIURL + 'calendario/listarEventosPorUsuario',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: 'params={"start":"' + year + '-' + month + '-' + day + '","end":"' + newYear + '-' + newMonth + '-' + newDay + '"}',
        success: function success(response) {
          var data = JSON.parse(response);
          Object.keys(data.data).forEach(function (idx) {
            /*
             * @todo: Modificar para utilizar el metodo <<createEvent>> dentro de <<events-calendar>> de manera como se muestra a continuación.
             *
             * _self.createEvent([ data.data[idx] ]);
             */
            var _schedule_object = {
              id: data.data[idx].calendario_k,
              calendarId: '1',
              title: data.data[idx].nombre,
              category: 'time',
              dueDateClass: String(data.data[idx].descripcion),
              start: data.data[idx].fecha + 'T' + data.data[idx].hora,
              end: data.data[idx].fecha_fin + 'T' + data.data[idx].hora_fin,
              location: data.data[idx].lugar
            };
            calendar.createSchedules([_schedule_object]);
          });
          var scheduleId = params.get('evento_k');

          if (scheduleId !== undefined && scheduleId !== null && scheduleId.trim() !== '') {
            var event = calendar.getSchedule(scheduleId, '1');

            if (event !== undefined && event !== null) {
              calendar.setDate(event.start._date);
              var schedule = event;
              var startDate = schedule.start._date;
              var endDate = schedule.end._date;
              var descripcion = schedule.dueDateClass;

              var arrayHoraFechaInicio = _self.formatCard(String(startDate));

              var arrayHoraFechaFin = _self.formatCard(String(endDate));

              Ember.$("#cardCurrentElement").removeAttr('hidden');
              Ember.$('#actualBigDay').html(arrayHoraFechaInicio[0].split(' ')[0]);
              Ember.$('#actualMonthBig').html(arrayHoraFechaInicio[0].split(' ')[2]);
              Ember.$('#titleCurrentEvent').html(schedule.title);
              Ember.$('#currentEventStartEndDate').html('<i class="far fa-calendar"></i> ' + arrayHoraFechaInicio[0] + ' al ' + arrayHoraFechaFin[0]);
              Ember.$('#currentEventStartEndTime').html('<i class="far fa-clock"></i> ' + arrayHoraFechaInicio[1].substring(0, 5) + ' a ' + arrayHoraFechaFin[1].substring(0, 5));
              Ember.$('#locationCurrentEvent').html('<i class="fas fa-map-marker-alt"></i> ' + schedule.location);
              Ember.$('#currentEventDescription').html(descripcion);
            }
          }
        },
        error: function error(e) {
          console.error(e);
        }
      });
      calendar = this.setEventsToCalendar(calendar);
      this.set('calendar', calendar);
    },

    /*
        * getCurrentDate - Función utilitaria.
        *
        *
        * Función utilitaria.
         *
        * @author  [Artur] Arturo Amador Paulino
        *				amadorassassin@gmail.com
        *
        * @date    2018-09-##
        * @param   
        * @return  
        */
    getCurrentDate: function getCurrentDate() {
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1;
      return month;
    },

    /*
        * getCurrentYear - Función utilitaria.
        *
        *
        * Función utilitaria.
         *
        * @author  [Artur] Arturo Amador Paulino
        *				amadorassassin@gmail.com
        *
        * @date    2018-09-##
        * @param   
        * @return  
        */
    getCurrentYear: function getCurrentYear() {
      var dateObj = new Date();
      var year = dateObj.getUTCFullYear();
      return year;
    },

    /*
        * formatDate - Función utilitaria.
        *
        *
        * Función utilitaria.
         *
        * @author  [Artur] Arturo Amador Paulino
        *				amadorassassin@gmail.com
        *
        * @date    2018-09-##
        * @param   
        * @return  
        */
    formatDate: function formatDate(calendar) {
      var date = calendar.getDate();
      var dateUTC = date.toUTCString().split(' ');
      var month = dateUTC[2];
      var year = dateUTC[3];

      if (month === 'Jan') {
        month = 'Ene';
      } else if (month === 'Apr') {
        month = 'Abr';
      } else if (month === 'Aug') {
        month = 'Ago';
      } else if (month === 'Dec') {
        month = 'Dic';
      }

      this.set('month_year', month + ' ' + year);
    },

    /*
        * formatCard - Función utilitaria.
        *
        *
        * Función utilitaria.
         *
        * @author  [Artur] Arturo Amador Paulino
        *				amadorassassin@gmail.com
        *
        * @date    2018-09-##
        * @param   
        * @return  
        */
    formatCard: function formatCard(date) {
      var array = date.split(' ');
      var mes;
      var fomatHora = array[4];

      if (array[1] === 'Jan') {
        mes = 'Enero';
      } else if (array[1] === 'Feb') {
        mes = 'Febrero';
      } else if (array[1] === 'Mar') {
        mes = 'Marzo';
      } else if (array[1] === 'Apr') {
        mes = 'Abril';
      } else if (array[1] === 'May') {
        mes = 'Mayo';
      } else if (array[1] === 'Jun') {
        mes = 'Junio';
      } else if (array[1] === 'Jul') {
        mes = 'Julio';
      } else if (array[1] === 'Aug') {
        mes = 'Agosto';
      } else if (array[1] === 'Sep') {
        mes = 'Septiembre';
      } else if (array[1] === 'Oct') {
        mes = 'Octubre';
      } else if (array[1] === 'Nov') {
        mes = 'Noviembre';
      } else {
        mes = 'Diciembre';
      }

      date = array[2] + ' de ' + mes + ' del ' + array[3];
      return [date, fomatHora];
    },

    /*
        * actions - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
        *
        *
        * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
        *
        *
        * @author  [Artur] Arturo Amador Paulino
        *				amadorassassin@gmail.com
        *
        * @date    2018-09-##
        * @param   
        * @return  
        */
    actions: {
      next: function next() {
        var calendar = this.get('calendar');
        calendar.next();
        this.formatDate(calendar);
      },
      prev: function prev() {
        var calendar = this.get('calendar');
        calendar.prev();
        this.formatDate(calendar);
      },
      day: function day() {
        var calendar = this.get('calendar');
        calendar.changeView('day', true);
      },
      month: function month() {
        var calendar = this.get('calendar');
        calendar.changeView('month', true);
      },
      week: function week() {
        var calendar = this.get('calendar');
        calendar.changeView('week', true);
      },
      today: function today() {
        var calendar = this.get('calendar');
        calendar.today();
        this.formatDate(calendar);
      }
    }
  });

  _exports.default = _default;
});