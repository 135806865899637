define("comunidades-nafin/components/offline/lista-cursos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bus: Ember.inject.service('evaluacion-curso'),
    didInsertElement: function didInsertElement() {
      var self = this;
      document.addEventListener("deviceready", onDeviceReady, false);

      function onDeviceReady() {
        self.inicializar();
      }
    },
    inicializar: function inicializar() {
      var self = this;
      var arrayComunidades = [];
      Ember.$('#loading').fadeOut('slow');
      var comunidades = JSON.parse(localStorage.getItem("comunidades_ras"));
      var rutas = JSON.parse(localStorage.getItem("ra_descargadas"));
      self.combinarComunidadesRutas(comunidades, rutas);
      Ember.$("body").addClass("panel-gris");
      Ember.$("#panel").addClass("panel-gris");
      Ember.$(".menu-mobile .online").addClass("d-none");
      Ember.$(".menu-mobile .offline").removeClass("d-none");
    },
    combinarComunidadesRutas: function combinarComunidadesRutas(comunidades, rutas) {
      var self = this;
      var comunidadesRutas = [];
      var comunidad_k = localStorage.getItem("comunidad_k");
      var pathDownloads = "";
      if (typeof device === 'undefined') window.location.reload();

      if (device.platform == "Android") {
        pathDownloads = cordova.file.dataDirectory;
      } else {
        pathDownloads = cordova.file.documentsDirectory;
      }

      Ember.$.each(comunidades, function (i, v) {
        if (v.comunidad_k == comunidad_k) {
          var comunidad = v;
          comunidad.rutas = [];
          Ember.$.each(rutas, function (j, w) {
            if (w[0].comunidades.includes(v.comunidad_k)) {
              w[0].imagen_ra = window.Ionic.WebView.convertFileSrc(pathDownloads + w[0].imagen_ra).replace("undefined", "");

              if (w[0].imagen_ra.search("null") >= 0) {
                window.location.reload();
              }

              comunidad.rutas.push(w[0]);
            }
          }); // SI EXISTEN RUTAS EN COMUNIDAD

          if (comunidad.rutas.length > 0) comunidadesRutas.push(comunidad);
        }
      });
      self.set("comunidadesRutas", comunidadesRutas);
    },
    numOfTimes: Ember.computed('times', function () {
      return [1, 2, 3, 4, 5];
    }),
    actions: {
      regresarAComunidades: function regresarAComunidades() {
        Ember.$("#loading").show();
        window.location.href = window.location.href.split("#")[0] + "#/comunidades-offline";
        window.location.reload();
      },
      rutaAprendizaje: function rutaAprendizaje(ruta) {
        Ember.$("#loading").show();
        localStorage.setItem("ruta-especifica", JSON.stringify(ruta));
        localStorage.setItem("ruta-detalles", JSON.stringify(ruta));
        window.location.href = window.location.href.split("#")[0] + "#/ruta-offline";
        window.location.reload();
      },
      eliminarRuta: function eliminarRuta(ruta) {
        var self = this;
        self.set("rutaTemporal", ruta);
        Ember.$("#modal-confirmacion-descarga").modal("show");
      },
      confirmarEliminar: function confirmarEliminar() {
        var self = this;
        var ra_descargadas = localStorage.getItem('ra_descargadas'); // != null ? localStorage.getItem('ra_descargadas'): "{}";

        var rutasDescargadasNuevos = [];
        var ruta = self.get("rutaTemporal");

        if (ra_descargadas != null) {
          var arrayRutas = JSON.parse(ra_descargadas);

          for (var i = 0; i < arrayRutas.length; i++) {
            if (arrayRutas[i][0].ra_k != ruta.ra_k) rutasDescargadasNuevos.push(arrayRutas[i]);else {
              // Borramos avances de objetos
              var objetos = arrayRutas[i][0].objetos;

              for (var j = 0; j < objetos.length; j++) {
                localStorage.removeItem("avanceCurso_" + objetos[j].propietario_recurso_k);
              } //  Borramos objetos a sincronizar


              var jsonObjetosSincronizacion = localStorage.getItem("objetosSincronizacion");
              var nuevaSincronizacion = [];

              if (jsonObjetosSincronizacion != null) {
                var objetosSincronizacion = JSON.parse(jsonObjetosSincronizacion);

                for (var k = 0; k < objetosSincronizacion.length; k++) {
                  // Sincronizamos solo si el objeto no se ha sincronizando
                  if (objetosSincronizacion[k].ra_k != ruta.ra_k) {
                    nuevaSincronizacion.push(objetosSincronizacion[k]);
                  }
                }

                localStorage.setItem("objetosSincronizacion", JSON.stringify(nuevaSincronizacion));
              }
            }
          }

          localStorage.setItem('ra_descargadas', JSON.stringify(rutasDescargadasNuevos));
          window.location.reload();
        }
      }
    }
  });

  _exports.default = _default;
});