define("comunidades-nafin/templates/nom-035-stps/reportes/administrador/audiencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rGh7rMh3",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"accordion\"],[8],[0,\"\\n    \"],[1,[26,\"nom-035-stps/reportes/charts/simple-pie-chart\",null,[[\"clase\",\"metodo\",\"title\"],[[22,[\"dataChart1\",\"clase\"]],[22,[\"dataChart1\",\"metodo\"]],\"Gráfica de resultados\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row mb-2\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"accordion\"],[8],[0,\"\\n    \"],[1,[20,\"nom-035-stps/reportes/reports/audiencia-distribucion-cuestionarios-reporte\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row mb-2\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"accordion\"],[8],[0,\"\\n    \"],[1,[20,\"nom-035-stps/reportes/reports/audiencia-resumen-por-trabajador-reporte\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/reportes/administrador/audiencia.hbs"
    }
  });

  _exports.default = _default;
});