define("comunidades-nafin/helpers/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sum = sum;
  _exports.default = void 0;

  function sum(params
  /*, hash*/
  ) {
    return params.reduce(function (a, b) {
      return a + b;
    });
  }

  var _default = Ember.Helper.helper(sum);

  _exports.default = _default;
});