define("comunidades-nafin/routes/notificaciones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * notificaciones - Route corresondiente a notificaciones.
   *
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
   *
   *
   * @author      [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date:       2018-10-04
   * @extend:     Route.extend
   */
  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },

    /**
     * setupController - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   controller controller referencia al controlador de notificaciones.
     * @return  void
     */
    setupController: function setupController(controller) {
      controller.set('configurationComponentNotifications', {
        'showMoreNotificationsButton': true,
        'showDeleteNotificationsButton': true
      });
      setTimeout(function () {
        Ember.$('#loading').fadeOut('slow');
      }, 3000);
    }
  });

  _exports.default = _default;
});