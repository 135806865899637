define("comunidades-nafin/routes/administracion-comunidades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 1000);
      });
      return promise;
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('searchResults', false);
    },
    normalizaComunidades: function normalizaComunidades(comunidades) {
      comunidades.forEach(function (comm) {
        if (comm.img.split('/')[0] === 'uploads') {
          comm.img = constant.BASEURL + comm.img;
        }
      });
    },
    actions: {}
  });

  _exports.default = _default;
});