define("comunidades-nafin/components/course-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      this.set("course_detail", true);
    },
    actions: {
      course_detail: function course_detail() {
        this.set("course_detail", true);
      },
      fullcourse: function fullcourse() {
        this.set("course_detail", false);
      },
      evaluation: function evaluation() {
        this.set("course_detail", false);
        this.set("fullcourse", false);
      }
    }
  });

  _exports.default = _default;
});