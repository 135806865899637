define("comunidades-nafin/components/menu-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;

      if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
        // Opciones en Footer - Menu
        self.set("menu_footer", window.permisos_navegacion.general_navegacion_menu_lateral_app);
        self.set("webinar_footer", window.permisos_navegacion.general_navegacion_webinars_visible);
        self.set("cursos_footer", window.permisos_navegacion.general_navegacion_cursos_visible);

        if (window.permisos_navegacion.general_navegacion_menu_lateral_app === true) {
          var slideout = new Slideout({
            'panel': document.getElementById('panel'),
            'menu': document.getElementById('menu'),
            'side': 'left',
            'tolerance': 0
          });
          document.querySelector('.js-slideout-toggle').addEventListener('click', function () {
            slideout.toggle();
          });
          document.querySelector('.slideout-off').addEventListener('click', function () {
            slideout.toggle();
          });
          document.querySelector('.menu').addEventListener('click', function (eve) {
            if (eve.target.nodeName === 'A') {
              slideout.close();
            }
          });
          Ember.$("#menu").removeAttr("style");
        } else {
          Ember.$(".js-slideout-toggle").remove();
        }
      }
    },
    actions: {
      iniciarSesion: function iniciarSesion() {
        document.location.href = window.location.href.split("#")[0] + "#" + ' /comunidades-movil';
        window.location.reload();
      },
      goCourses: function goCourses() {
        this._super.apply(this, arguments);

        var currentRoute = this.get('router').get('currentRouteName');

        if (currentRoute != 'cursos') {
          this.get('router').transitionTo('cursos');
        } else {
          document.location.href = '/cursos';
        }

        document.location.href = window.location.href.split("#")[0] + "#" + ' /comunidades-movil';
        window.location.reload();
      },
      goComunidadesMovil: function goComunidadesMovil() {
        document.location.href = window.location.href.split("#")[0] + "#" + '/comunidades-movil';
        window.location.reload();
      },
      goWebinars: function goWebinars() {
        this.get('router').transitionTo('webinars');
      }
    }
  });

  _exports.default = _default;
});