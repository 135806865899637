define("comunidades-nafin/routes/higienizacion-covid19/reportes/administrador/resultados-generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    scopeThis: null,
    reporteDetallePorUsuarioScope: null,
    reporteAgrupadoPorEstatusScope: null,
    setScopeReporteDetallePorUsuario: function setScopeReporteDetallePorUsuario() {
      var reporteDetallePorUsuarioScope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.reporteDetallePorUsuarioScope = reporteDetallePorUsuarioScope;
    },
    setScopeReporteAgrupadoPorEstatus: function setScopeReporteAgrupadoPorEstatus() {
      var reporteAgrupadoPorEstatusScope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.reporteAgrupadoPorEstatusScope = reporteAgrupadoPorEstatusScope;
      console.log('reporteAgrupadoPorEstatusScope');
      console.dir(reporteAgrupadoPorEstatusScope);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('dataChart1', {
        clase: 'higienizacioncovid19/reporteadministrador',
        metodo: 'resultadosgeneralesgraficaderesultados'
      });
      controller.set('inputGroupSelectvalue', null);
      this.scopeThis = this;
      controller.set('scopeThis', this.scopeThis);
      /*
       * Workaround. cargamos dependencias de combo
       */

      $.ajax({
        url: constant.APIURL + 'publico/higienizacioncovid19/listardepartamentos',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set('inputGroupSelectDepartamentoData', response.data);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      /*
       * Workaround. cargamos dependencias de combo
       */

      $.ajax({
        url: constant.APIURL + 'publico/higienizacioncovid19/listarcursos',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set('inputGroupSelectRutaAprendizajeData', response.data);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      onSelectDepartamento: function onSelectDepartamento() {
        /*
        this.reporteDetallePorUsuarioScope.setParamsDepartamentoK( $('#inputGroupSelectDepartamento').val() );
        this.reporteDetallePorUsuarioScope.loadData();
         this.reporteAgrupadoPorEstatusScope.setParamsDepartamentoK( $('#inputGroupSelectDepartamento').val() );
        this.reporteAgrupadoPorEstatusScope.reload();
        */
      },
      onSelectRutaAprendizaje: function onSelectRutaAprendizaje() {
        /*
        this.reporteDetallePorUsuarioScope.setParamsRutaAprendizajeK( $('#inputGroupSelectRutaAprendizaje').val() );
        this.reporteDetallePorUsuarioScope.loadData();
         this.reporteAgrupadoPorEstatusScope.setParamsRutaAprendizajeK( $('#inputGroupSelectRutaAprendizaje').val() );
        this.reporteAgrupadoPorEstatusScope.reload();
        */
      },
      filterAndReload: function filterAndReload() {
        this.reporteDetallePorUsuarioScope.setParamsDepartamentoK($('#inputGroupSelectDepartamento').val());
        this.reporteDetallePorUsuarioScope.setParamsRutaAprendizajeK($('#inputGroupSelectRutaAprendizaje').val());
        this.reporteDetallePorUsuarioScope.loadData();
        this.reporteAgrupadoPorEstatusScope.setParamsDepartamentoK($('#inputGroupSelectDepartamento').val());
        this.reporteAgrupadoPorEstatusScope.setParamsRutaAprendizajeK($('#inputGroupSelectRutaAprendizaje').val());
        this.reporteAgrupadoPorEstatusScope.reload();
      }
    }
  });

  _exports.default = _default;
});