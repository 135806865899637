define("comunidades-nafin/templates/notificaciones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4JlPnp30",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\\n\"],[14,\"loading\",[]],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\n            \"],[6,\"div\"],[10,\"class\",\"col-12 margin_ud_10px\"],[8],[0,\"\\n               \\n                \"],[6,\"h1\"],[10,\"class\",\"text_center\"],[8],[0,\"Notificaciones\"],[9],[0,\"\\n               \\n            \"],[9],[0,\"\\n\\n        \"],[9],[0,\"\\n\\n        \"],[1,[26,\"notifications-list\",null,[[\"configurations\"],[[22,[\"configurationComponentNotifications\"]]]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\"\\n\\n\"],[1,[20,\"sub-footer\"],false]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/notificaciones.hbs"
    }
  });

  _exports.default = _default;
});