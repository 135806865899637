define("comunidades-nafin/routes/recurso-no-disponible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('url_imagen', solveImageURL('img/imageConstruc/working.png'));
    },
    actions: {
      goNotifications: function goNotifications() {
        this.transitionTo('notificaciones');
      }
    }
  });

  _exports.default = _default;
});