define("comunidades-nafin/templates/components/examples-components/ember-example", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VnnGx0id",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h2\"],[8],[1,[20,\"title\"],false],[9],[0,\"\\n\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/examples-components/ember-example.hbs"
    }
  });

  _exports.default = _default;
});