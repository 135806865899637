define("comunidades-nafin/components/notificacion/general/historico-envio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this;
      self.eventosJquery();
      self.comunidades();
      self.send("cargarhistorico", 10);
    },
    eventosJquery: function eventosJquery() {
      var self = this;
      $("#listarTodas").click(function () {
        if ($(this).is(":checked")) self.send("cargarhistorico", false);else self.send("cargarhistorico", 10);
      });
    },
    comunidades: function comunidades() {
      var self = this;
      var comunidades = [];
      $.ajax({
        url: constant.APIURL + 'comunidad/listar',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        dataType: "json",
        async: false,
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          $.each(r.data, function (i, v) {
            comunidades[v.comunidad_k] = v.nombre;
          });
          self.set("comunidades", comunidades);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      nuevaNotificacion: function nuevaNotificacion() {
        $("#historico").addClass("d-none");
        $("#form-contenedor").removeClass("d-none");
      },
      cargarhistorico: function cargarhistorico(registros) {
        var self = this;
        var limit = registros ? true : false;
        $.ajax({
          url: constant.APIURL + 'notificaciones/listarHistoricoNotificacionesGenerales',
          type: 'POST',
          data: {
            limit: limit
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          dataType: "json",
          async: false,
          xhrFields: {
            withCredentials: true
          },
          success: function success(r) {
            if (r.data) {
              var historico = [];
              $.each(r.data, function (i, v) {
                var params = JSON.parse(v.params);
                var paramComunidades = params.comunidades != null && params.comunidades != undefined ? params.comunidades : [];
                var comunidades = "";
                var arrayComunidades = self.get("comunidades");
                var usuarios = params.usuarios != null && params.usuarios != undefined ? params.usuarios : 0;

                for (var _i = 0; _i < paramComunidades.length; _i++) {
                  comunidades += arrayComunidades[paramComunidades[_i]] + "<br>";
                }

                var registro = {
                  usuarios: usuarios,
                  titulo: params.title,
                  mensaje: params.body,
                  fecha: v.fecha_hora_creacion,
                  comunidades: comunidades
                };
                historico.push(registro);
              });
              self.set("historico", historico);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});