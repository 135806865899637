define("comunidades-nafin/initializers/monitoring-online", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    if (!window.hasOwnProperty("cordova")) {
      window.heyOffline = new Heyoffline({
        noStyles: true
      });
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});