define("comunidades-nafin/components/admin-components/consultas-nuevo-respuesta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
   *
   *
   * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
   *
   *
   * @author  [YRM] Maurilio Cruz Morales
   *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
   *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
   *              Tel. celular (55) 6702 6252
   *
   * @date    2018-10-04
   * @param   void
   * @return  void
   */
  var _default = Ember.Component.extend({
    /*
     * didReceiveAttrs - Runs after init, and it also runs on subsequent re-renders, which is useful for logic that is the same on all renders.
     *
     *
     * Se sobe-escribe el metodo didReceiveAttrs, para implementa recepción y guardado de propiedades enviadas al instanciar el compomnente. y 
     * ejecutar metodos de cargado de Store.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setCategoriasList();
      this.set('storePredefinedAnswer', this.storePredefinedAnswer);
    },

    /*
     * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', function () {
        unmask();
      });
    },

    /*
     * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    setCategoriasList: function setCategoriasList() {
      var categorias_object = [];

      var _self = this;

      var params_object = {
        "token": "consultasrespuestascategorias",
        "table": "consultas_respuestas_predefinidas",
        "key": "categoria_k"
      };
      $.ajax({
        url: constant.APIURL + 'categoria/listarmultiple',
        async: false,
        type: 'POST',
        scope: this,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data: 'params=' + JSON.stringify(params_object) + '&start=0&limit=' + constant.PAGESIZE,
        xhrFields: {
          withCredentials: true
        },
        success: function success(_response) {
          var respuesta_object = JSON.parse(_response);
          categorias_object = respuesta_object.data;
        },
        error: function error(e) {
          showModalError();
          console.log(e);
        }
      });
      this.set('store_categorias', categorias_object);
    },

    /*
     * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
     *
     *
     * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-04
     * @param   void
     * @return  void
     */
    actions: {
      /**
       * Lorem ipsum dolor sit - Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus.
       *
       *
       * Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus, egestas orci sociis tempus posuere consequat.
       *
       *
       * @author  [YRM] Maurilio Cruz Morales
       *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
       *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
       *              Tel. celular (55) 6702 6252
       *
       * @date    2018-10-04
       * @param   void
       * @return  void
       */
      savePredefinedAnswer: function savePredefinedAnswer(store_respuesta_predefinida) {
        mask();

        var _self = this;

        var _store_respuesta_predefinida = store_respuesta_predefinida;
        var _missing_value = '';
        var e = document.getElementById("respuesta-predefinida-categoria");
        var categoria_k = e.options[e.selectedIndex].value;
        Ember.set(store_respuesta_predefinida, "categoria_k", categoria_k);

        if (_missing_value == '' && store_respuesta_predefinida.nombre == '') {
          _missing_value = 'titulo de respuesta';
        }

        if (_missing_value == '' && store_respuesta_predefinida.categoria_k == '') {
          _missing_value = 'tipo de respuesta';
        }

        if (_missing_value == '' && store_respuesta_predefinida.descripcion == '') {
          _missing_value = 'descripcion de respuesta';
        }

        if (_missing_value != '') {
          Ember.set(store_respuesta_predefinida, "hasError", true);
          Ember.set(store_respuesta_predefinida, "errorMessage", 'El campo ' + _missing_value + ' es requerido.');
          unmask();
          return;
        }
        /*
         * Constuimos string de params para evnviar en petición.
         */


        var _data_string = 'i=' + '' + '&d=' + '[]' + '&tipo_elemento=' + '' + '&nombre=' + store_respuesta_predefinida.nombre + '&categoria_k=' + store_respuesta_predefinida.categoria_k + '&descripcion=' + store_respuesta_predefinida.descripcion;

        $.ajax({
          url: constant.APIURL + 'consulta/agregarrespuestapredefinida',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          data: _data_string,
          xhrFields: {
            withCredentials: true
          },
          success: function success() {
            $('#modalFormNewPredefinedAnswer').modal('hide');
            unmask();
            showModal({
              titulo: 'Respuesta agregada.',
              mensaje: 'La respuesta predefinida ' + _store_respuesta_predefinida.nombre + ' Se ha agregado correctamente.'
            });
          },
          error: function error(e) {
            $('#modalFormNewPredefinedAnswer').modal('hide');
            unmask();
            showModalError();
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});