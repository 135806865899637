define("comunidades-nafin/templates/foros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VIyUuMDq",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/foros.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12 margin_ud_10px\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"h2\"],[10,\"class\",\"text_center\"],[8],[0,\"Foros\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[6,\"div\"],[10,\"class\",\"row margin_ud_10px\"],[8],[0,\"\\n\\t\\t\"],[1,[20,\"forums-cards\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/foros.hbs"
    }
  });

  _exports.default = _default;
});