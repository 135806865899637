define("comunidades-nafin/components/human-talent-components/responsive-table", ["exports", "comunidades-nafin/components/human-talent-components/h-t-component"], function (_exports, _hTComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTComponent.default.extend({
    didReceiveAttr: function didReceiveAttr() {
      this._super.apply(this, arguments);
    },
    actions: {}
  });

  _exports.default = _default;
});