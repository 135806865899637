define("comunidades-nafin/templates/mediateca", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G6FJYDmr",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\\n\"],[1,[20,\"admin-components/mediateca\"],false],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/mediateca.hbs"
    }
  });

  _exports.default = _default;
});