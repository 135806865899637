define("comunidades-nafin/templates/components/forum-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xEo3oxo2",
    "block": "{\"symbols\":[\"comunidad\"],\"statements\":[[6,\"ul\"],[10,\"class\",\"no_list_style foro_buttons no_padding\"],[8],[0,\"\\t\\t\\t\\n\\t\"],[6,\"li\"],[10,\"class\",\"padding_ud_10px\"],[8],[0,\"\\n\\t\\t\"],[6,\"button\"],[11,\"class\",[27,[\"btn  buttons_styles \",[26,\"if\",[[22,[\"activarRecientes\"]],\"btn-primary\",\"btn-theme \"],null]]]],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"recent_foro\",[22,[\"comunidades\"]]]],[8],[6,\"div\"],[8],[0,\"Temas recientes\"],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"each\",[[22,[\"comunidadesList\"]]],null,{\"statements\":[[4,\"if\",[[21,1,[\"foro_k\"]]],null,{\"statements\":[[0,\"\\t\"],[6,\"li\"],[10,\"class\",\"padding_ud_10px\"],[8],[0,\"\\n\\t\\t\"],[6,\"button\"],[11,\"data-foro_k\",[27,[[21,1,[\"foro_k\"]]]]],[11,\"id\",[27,[\"comunidad_\",[21,1,[\"comunidad_k\"]]]]],[11,\"class\",[27,[\"btn buttons_styles  \",[26,\"if\",[[21,1,[\"token_foro\"]],\"btn-theme \",\"btn-primary\"],null]]]],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"change_foro\",[21,1,[\"comunidad_k\"]]]],[8],[6,\"div\"],[8],[1,[21,1,[\"nombre\"]],false],[6,\"span\"],[10,\"class\",\"pull-right badge  badge-size-foro\"],[8],[1,[21,1,[\"numero_preguntas_foro\"]],false],[9],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/forum-list.hbs"
    }
  });

  _exports.default = _default;
});