define("comunidades-nafin/routes/registro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('registroForm', "registro-components/" + window.constant.THEME);
    }
  });

  _exports.default = _default;
});