define("comunidades-nafin/templates/comunidades/comunidad-especifica", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PsMmTMMF",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"../css/comunidades.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"../css/foros.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"../css/news-cards.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"../css/events-list.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"../css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\\n\"],[14,\"loading\",[]],[0,\"\\n\\n\\t\"],[6,\"div\"],[10,\"class\",\"container-fluid margin_ud_10px\"],[8],[0,\"\\n\\t\\t\"],[1,[20,\"community-wall\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/comunidades/comunidad-especifica.hbs"
    }
  });

  _exports.default = _default;
});