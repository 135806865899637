define("comunidades-nafin/routes/diagnostico-empresarial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve({
            params: params
          });
        }, 1000);
      });
      return promise;
    },
    setupController: function setupController(controller, model) {
      if (model.params.mis_diagnosticos == 1) controller.set('mis_diagnosticos', model.params.mis_diagnosticos);
      setTimeout(function () {
        $('#loading').fadeOut('slow');
      }, 3000);
    }
  });

  _exports.default = _default;
});