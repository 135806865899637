define("comunidades-nafin/routes/inicio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    setupController: function setupController(controller) {
      if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
        if (document.location.href.split("#")[1] === undefined) {
          console.log("Session => ", controller.session);

          if (controller.session.isAuthenticated) {
            document.location.href = document.location.href.split("#")[0] + window.constant.APPURL;
          } //window.location.reload();

        }
      }
    },
    beforeModel: function beforeModel() {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(this, function () {
          resolve("ok");
        }, 0);
      });
      return promise;
    },
    actions: {
      goCourse: function goCourse() {
        this.get('router').transitionTo('cursos');
      },
      goNews: function goNews() {
        this.get('router').transitionTo('noticias');
      },
      goEvents: function goEvents() {
        this.get('router').transitionTo('eventos');
      },
      goCommunities: function goCommunities() {
        this.get('router').transitionTo('comunidades');
      }
    }
  });

  _exports.default = _default;
});