define("comunidades-nafin/templates/components/human-talent-components/h-t-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pHM2qT4e",
    "block": "{\"symbols\":[\"record\",\"index\",\"headerRecord\",\"headerRecord\",\"&default\"],\"statements\":[[6,\"table\"],[11,\"class\",[27,[\"table \",[26,\"if\",[[22,[\"isStriped\"]],\"table-striped\"],null]]]],[8],[0,\"\\n\\n    \"],[6,\"thead\"],[8],[0,\"\\n        \"],[6,\"tr\"],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"headers\"]]],null,{\"statements\":[[0,\"                \"],[6,\"th\"],[10,\"scope\",\"col\"],[8],[1,[21,4,[\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"tbody\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[22,[\"data\"]]],null,{\"statements\":[[0,\"            \"],[6,\"tr\"],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"headers\"]]],null,{\"statements\":[[0,\"                    \"],[6,\"td\"],[10,\"scope\",\"row\"],[8],[1,[26,\"human-talent-helpers/object/select-value-property-from-index\",[[21,1,[]]],[[\"property\"],[[21,3,[\"dataIndex\"]]]]],true],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"            \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"            \"],[6,\"tr\"],[8],[0,\" \"],[6,\"td\"],[10,\"scope\",\"row\"],[8],[9],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[13,5],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/human-talent-components/h-t-table.hbs"
    }
  });

  _exports.default = _default;
});