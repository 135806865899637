define("comunidades-nafin/components/forum-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // http://www.lmshumantalent.com/develop_28082018/index.php/foro/listarForosPreguntas
    // http://www.lmshumantalent.com/develop_28082018/index.php/foro/listarPreguntasRecientes
    socket: {},
    arrayColor: ['000', '9C27B0', 'b0272b', 'ff9800', '009688', 'ff5722'],
    didInsertElement: function didInsertElement() {
      var self = this;
      var endpoint = 'comunidad/listarMisComunidades';

      if (this.session.isAdmin) {
        endpoint = 'comunidad/listar';
      }

      Ember.$.ajax({
        url: constant.APIURL + endpoint,
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        success: function success(response) {
          var data = JSON.parse(response).data;
          self.set('comunidadesList', data); //self.iniciar_socket();

          Ember.$('#loading').fadeOut('slow');
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    iniciar_socket: function iniciar_socket() {
      var self = this;
      self.socket.socket.emit('switchRoom', "ForosListadoGenerales"); // Detectamos la desconexion

      self.socket.socket.on('disconnect', function () {}); // Detectamos la conexion

      self.socket.socket.on('connect', function () {
        self.socket.socket.emit('switchRoom', "ForosListadoGenerales");
      });
      self.socket.socket.on('nuevaPregunta', function (data, success, parametrosAdicionales, updateforoPreguntaAgregar) {
        console.log(data);

        if (success) {
          var comunidades = self.get("comunidadesList").toArray();

          for (var i = 0; i < comunidades.length; i++) {
            if (comunidades[i].comunidad_k == parametrosAdicionales.comunidad_k) Ember.set(comunidades[i], "numero_preguntas_foro", parseInt(comunidades[i].numero_preguntas_foro) + 1);
          }
        }
      });
    },
    actions: {
      goBackForum: function goBackForum() {
        history.back();
      },
      change_foro: function change_foro(comunidad_k) {
        Ember.$('#foro_recientes').fadeIn('slow').removeAttr('hidden');
        Ember.$('#foro_muro').fadeOut('slow');

        this._super.apply(this, arguments);

        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'foro/listarPreguntasRecientes',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params={"comunidad_k":"' + comunidad_k + '"}',
          success: function success(r) {
            r = JSON.parse(r);
            Object.keys(r.data).forEach(function (idx) {
              var date_time = r.data[idx].timestamp_creacion.split(' ');
              var date = date_time[0].split('-');
              var month = date[1];
              r.data[idx].dayFormath = date[2];
              r.data[idx].yearFormath = date[0];
              var secondColor = Math.floor(Math.random() * 6);

              if (r.data[idx] != 'foto') {
                var nombre = r.data[idx].full_name.split('');
                var img = nombre[0].toUpperCase();
                r.data[idx].foto = "https://dummyimage.com/600x400/" + self.arrayColor[secondColor] + "/fff.jpg&text=" + img;
              }
            });
            self.set('foro_reciente', r.data);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});