define("comunidades-nafin/components/examples-components/react-imported-example", ["exports", "comunidades-nafin/react-component", "comunidades-nafin/components/examples-components/react-imported-example/demo-modal"], function (_exports, _reactComponent, _demoModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reactComponent.default.extend({
    title: 'An awesome demo',
    onTextChange: function onTextChange(text) {
      Ember.set(this, 'title', text);
      this.renderModal();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.renderModal();
    },
    renderModal: function renderModal() {
      var _this = this;

      this.reactRender(React.createElement(_demoModal.default, {
        title: Ember.get(this, 'title'),
        onTextChange: function onTextChange(text) {
          return _this.onTextChange(text);
        }
      }));
    }
  });

  _exports.default = _default;
});