define("comunidades-nafin/templates/components/serch-buttons-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OinZ1Et2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/catalogo-cursos.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\"],[6,\"center\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"h2\"],[8],[1,[20,\"title\"],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-4 col-md-6 col-lg-4\"],[8],[9],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-12 col-md-6 col-lg-4\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"input-group mb-3\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"input\"],[10,\"class\",\"form-control\"],[10,\"placeholder\",\"\"],[10,\"aria-label\",\"Recipient's username\"],[10,\"aria-describedby\",\"basic-addon2\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"input-group-append\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[6,\"span\"],[10,\"class\",\"input-group-text\"],[10,\"id\",\"basic-addon2\"],[8],[1,[26,\"fa-icon\",[\"search\"],[[\"prefix\"],[\"fas\"]]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-6 col-md-3 col-lg-2\"],[8],[6,\"button\"],[10,\"class\",\"btn btn_nafin full_width\"],[10,\"type\",\"button\"],[8],[0,\"Categorias\"],[9],[9],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-6 col-md-3 col-lg-2\"],[8],[6,\"button\"],[10,\"class\",\"btn btn_success_nafin full_width\"],[10,\"type\",\"button\"],[8],[0,\"Agregar curso\"],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/serch-buttons-header.hbs"
    }
  });

  _exports.default = _default;
});