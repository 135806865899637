define("comunidades-nafin/components/examples-components/ember-example", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //On Initial Render
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    willRender: function willRender() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('title', 'Hello from Ember component');
    },
    didRender: function didRender() {},
    //On Re-Render
    didUpdateAttrs: function didUpdateAttrs() {},
    //didReceiveAttrs(){},
    willUpdate: function willUpdate() {},
    //willRender(){},
    didUpdate: function didUpdate() {},
    //didRender(){},
    //On Component Destroy
    willDestroyElement: function willDestroyElement() {},
    willClearRender: function willClearRender() {},
    didDestroyElement: function didDestroyElement() {},
    //ACTIONS
    actions: {}
  });

  _exports.default = _default;
});