define("comunidades-nafin/routes/human-talent/sandbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /*
     *  Para propsito de pruebas generamos las variables con los datos
     *  necesarios para ser enviados a cada uno de los componentes.
     */
    audienciaData: {
      total: 200,
      data: {
        aplicados: 130,
        restantes: 70,
        hombres: {
          total: 150,
          data: {
            aplicados: 100,
            restante: 50
          }
        },
        mujeres: {
          total: 50,
          data: {
            aplicados: 30,
            restante: 20
          }
        }
      }
    },
    // Data para grafica tipo Gauge
    audienciaDataGaugeChart: {
      score: 52.7,
      gradingData: [{
        title: "Nulo",
        color: "#00acff",
        lowScore: 0,
        highScore: 20
      }, {
        title: "Bajo",
        color: "#0f9747",
        lowScore: 20,
        highScore: 40
      }, {
        title: "Medio",
        color: "#f04922",
        lowScore: 40,
        highScore: 60
      }, {
        title: "Alto",
        color: "#fdae19",
        lowScore: 60,
        highScore: 80
      }, {
        title: "Muy alto",
        color: "#ee1f25",
        lowScore: 80,
        highScore: 100
      }]
    },
    // Data para grafica tipo Pie
    audienciaDataPieChart: {
      value: "values",
      category: "categories",
      data: [{
        "categories": "Lithuania",
        "values": 501.9
      }, {
        "categories": "Czechia",
        "values": 301.9
      }, {
        "categories": "Ireland",
        "values": 201.1
      }, {
        "categories": "Germany",
        "values": 165.8
      }, {
        "categories": "Australia",
        "values": 139.9
      }, {
        "categories": "Austria",
        "values": 128.3
      }, {
        "categories": "UK",
        "values": 99
      }, {
        "categories": "Belgium",
        "values": 60
      }, {
        "categories": "The Netherlands",
        "values": 50
      }]
    },
    //Data para tipo Grupo de barras
    groupData: {
      colors: {
        "Hombres": "#cfa",
        "Mujeres": "#CFF"
      },
      data: {
        "Accidentes": {
          "Hombres": 10,
          "Mujeres": 35,
          "quantity": 430
        },
        "Asaltos": {
          "Hombres": 15,
          "quantity": 210
        },
        "Actos violentos": {
          "Hombres": 11,
          "Mujeres": 25,
          "quantity": 265
        },
        "Secuestros": {
          "Hombres": 12,
          "Mujeres": 15,
          "quantity": 98
        }
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('audienciaDataPieChart', this.audienciaDataPieChart);
      controller.set('audienciaDataGaugeChart', this.audienciaDataGaugeChart);
      controller.set('audienciaData', this.audienciaData);
      controller.set('groupData', this.groupData);
    }
  });

  _exports.default = _default;
});