define("comunidades-nafin/routes/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/capacitaciones-certificados", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    reporteGeneracionMasivaReportesScope: null,
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
    },
    actions: {
      showFormRequestGenerateZip: function showFormRequestGenerateZip(record) {
        /**
         * [YURAM] HACK Begin
         *
         * Los cambios aplicados a continuación fueron aplicados a causa de una incompatibilidad entre componentes y elementos
         *
         */
        $("#compresoK").val(null);
        $("#inputEmail").val('');
        $('#requestFormMassiveUserReports').modal({});
        /**
         * [YURAM] HACK End
         */
      },
      bindScopeToReportAtsGeneracionMasivaReportesComponent: function bindScopeToReportAtsGeneracionMasivaReportesComponent(scope_reporte_ats_generacion_masiva_reportes) {
        this.reporteGeneracionMasivaReportesScope = scope_reporte_ats_generacion_masiva_reportes;
      },
      afterCreate: function afterCreate() {
        if (this.reporteGeneracionMasivaReportesScope) {
          if (isFunction(this.reporteGeneracionMasivaReportesScope.propagationAfterCreateEvent)) {
            this.reporteGeneracionMasivaReportesScope.propagationAfterCreateEvent();
          }
        }
      },
      afterUpdate: function afterUpdate() {}
    }
  });

  _exports.default = _default;
});