define("comunidades-nafin/components/higienizacion-covid19/reportes/charts/grafica-resultados-por-estatus-chart", ["exports", "comunidades-nafin/components/human-talent-components/charts/simple-pie-chart"], function (_exports, _simplePieChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simplePieChart.default.extend({
    scopeParent: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.scopeParent !== null) {
        this.scopeParent.setScopeReporteAgrupadoPorEstatus(this);
      }
    },
    setParamsRutaAprendizajeK: function setParamsRutaAprendizajeK(ra_k) {
      this.params.ra_k = ra_k;
    },
    setParamsDepartamentoK: function setParamsDepartamentoK(departamento_k) {
      this.params.departamento_k = departamento_k;
    },
    reload: function reload() {
      this.loadData(); // Insertamos el data como propiedad del componente. Ya sea de origen local ó remote.

      if (this.parseData) {
        this.parseData(); // En caso de requerir, para cada tipo de gráfica realizamos la conversión (parser) del data.
        //  Para adecaurse al tipo de gráfica especifico.
      }

      if (this.setData) {
        this.setData(); // Seteamos la propiedad data del objeto this hacia el objeto char.
      }
    }
  });

  _exports.default = _default;
});