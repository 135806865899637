define("comunidades-nafin/templates/vista-previa-evaluacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JLNgHCDn",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[1,[20,\"menu-header\"],false],[0,\"\\n\"],[6,\"script\"],[10,\"src\",\"js/evaluacion.js\"],[10,\"type\",\"text/javascript\"],[8],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\"],[6,\"center\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"h2\"],[8],[0,\"Vista Previa Evaluación\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"css/catalogo-cursos.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\"],[6,\"center\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"h2\"],[8],[1,[20,\"title\"],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\t\"],[6,\"div\"],[10,\"class\",\"row table-responsive scroll-overflow\"],[8],[0,\"\\n    \\n\"],[4,\"if\",[[22,[\"showLoad\"]]],null,{\"statements\":[[0,\"      \"],[6,\"img\"],[10,\"src\",\"/img/loading.gif\"],[10,\"class\",\"loading\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,[\"isEmpty\"]]],null,{\"statements\":[[0,\"        \"],[6,\"h2\"],[8],[0,\"No se encontraron evaluaciones\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n        \\n\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[6,\"br\"],[8],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/vista-previa-evaluacion.hbs"
    }
  });

  _exports.default = _default;
});