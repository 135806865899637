define("comunidades-nafin/routes/nom-035-stps/reportes/administrador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      $.ajax({
        url: constant.APIURL + 'nom035stps/reporteadministrador/obtenerInformacionCardsPrincipal',
        type: "post",
        //async:      false,
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            controller.set('data', response.data);
            console.dir(response.data);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    }
  });

  _exports.default = _default;
});