define("comunidades-nafin/components/login-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;
      this.set("logoModal", "lookandfeel/" + window.constant.THEME + "/img/logo.png");
      this.detectarModo();

      if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
        Ember.$(".offline").addClass("d-none");
        Ember.$(".online").addClass("d-none");
        Ember.$("#modal-conexion-online").modal("hide");

        if (navigator.connection != undefined) {
          var networkState = navigator.connection.type;

          if (networkState == "none") {
            Ember.$(".offline").removeClass("d-none");
          } else {
            onOnline();
            Ember.$(".online").removeClass("d-none");
          }
        } //document.addEventListener("pause", onPause, false);


        document.addEventListener("offline", onOffline, false);
        document.addEventListener("online", onOnline, false); // Si el usuario esta conectado a internet de cualquier modo, intentamos realizar commit y finish

        function onOffline() {
          var secciones = window.location.href.split("#");
          Ember.$(".offline").removeClass("d-none");
          Ember.$(".online").addClass("d-none");

          if (secciones.length > 1) {
            if (!window.constant.APPURLSOFFLINE.includes(secciones[1])) {
              Ember.$("#modal_login").modal('show');
              Ember.$(".modal").addClass("backgroundLogin");
            }
          }
        }

        function onOnline() {
          var secciones = window.location.href.split("#");
          Ember.$(".offline").addClass("d-none");
          Ember.$(".online").removeClass("d-none");

          if (Ember.$("#modal_login").data('bs.modal') != undefined && Ember.$("#modal_login").data('bs.modal')._isShown) {
            self.setLogin();
          }

          if (secciones.length > 1) {
            if (window.constant.APPURLSOFFLINE.includes(secciones[1])) {
              if (secciones[1] != "/scorm-offline" && secciones[1] != "/registro") {
                Ember.$("#modal-conexion-online").modal("show");
                setTimeout(function () {
                  Ember.$("#modal-conexion-online").modal("hide");
                  setTimeout(function () {
                    window.location.href = secciones[0] + window.constant.APPURL;
                  }, 500);
                }, 3000);
              }
            }
          }
        }
      }
    },
    detectarModo: function detectarModo() {
      var self = this;
      Ember.$("#modal_login").on('show.bs.modal', function () {
        self.setLogin();
      });
    },
    setLogin: function setLogin() {
      var self = this;

      if (navigator.connection != undefined) {
        var networkState = navigator.connection.type;

        if (networkState != "none") {
          var user = localStorage.getItem("userlogin");
          var pass = localStorage.getItem("pwdlogin");

          if (user != null && pass != null) {
            Ember.$("#modal_login").modal("hide");
            Ember.$("#modal-conexion-online").modal("show");
            Ember.$(".modal").addClass("backgroundLogin");
            Ember.$('#user').val(user);
            Ember.$('#pass').val(pass);
            Ember.$("#loginRequest").hide();
            Ember.$.ajax({
              statusCode: {
                500: function _() {
                  Ember.$('#modal-avisos .modal-header').text('Error');
                  Ember.$('#modal-avisos .modal-body').text("El servidor está temporalmente fuera de servicio por favor intenta mas tarde");
                  Ember.$('#modal-avisos').modal('show');
                }
              },
              url: window.constant.APIURL + 'autentication/au',
              type: 'POST',
              xhrFields: {
                withCredentials: true
              },
              contentType: 'application/x-www-form-urlencoded',
              data: 'wpw=' + user + md5(pass) + '&recaptcha_challenge_field=&recaptcha_response_field=',
              success: function success(params) {
                var response = JSON.parse(params);
                Ember.$("#loginRequest").show();

                if (response.success) {
                  Ember.$("body > *").text("");
                  localStorage.setItem("userlogin", user);
                  localStorage.setItem("pwdlogin", pass); //window.location.href =  //document.location.href.split("#")[0] + window.constant.APPURL;

                  window.location.reload();
                } else {
                  Ember.$("#errorLogin").removeClass("d-none").fadeIn(1500).fadeOut(6000);
                }
              },
              error: function error(e) {
                Ember.$('#modal-avisos .modal-header').text('Error');
                Ember.$('#modal-avisos .modal-body').text("No se ha podido establecer la conexión por favor intenta mas tarde");
                Ember.$('#modal-avisos').modal('show');
                Ember.$("#loginRequest").show();
                console.error(e);
              }
            });
          } else {}
        }
      }
    },
    actions: {
      irAMisDescargas: function irAMisDescargas() {
        window.location.href = window.location.href.split("#")[0] + "#/comunidades-offline";
        window.location.reload();
      },
      captureKey: function captureKey(e) {
        if (e.keyCode === 13) this.send("loginRequest");
      },
      goCourses: function goCourses() {
        this._super.apply(this, arguments);

        var currentRoute = this.get('router').get('currentRouteName');

        if (currentRoute != 'cursos') {
          this.get('router').transitionTo('cursos');
        } else {
          document.location.href = '/cursos';
        }
      },
      loginRequest: function loginRequest() {
        var user = Ember.$('#user').val();
        var pass = Ember.$('#pass').val();
        var self = this;
        Ember.$("#loginRequest").hide();
        localStorage.setItem("userlogin", user);
        localStorage.setItem("pwdlogin", pass);
        Ember.$.ajax({
          statusCode: {
            500: function _() {
              Ember.$('#modal-avisos .modal-header').text('Error');
              Ember.$('#modal-avisos .modal-body').text("El servidor está temporalmente fuera de servicio por favor intenta mas tarde");
              Ember.$('#modal-avisos').modal('show');
            }
          },
          url: window.constant.APIURL + 'autentication/au',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'wpw=' + user + md5(pass) + '&recaptcha_challenge_field=&recaptcha_response_field=',
          success: function success(params) {
            var response = JSON.parse(params);
            Ember.$("#loginRequest").show();

            if (response.success) {
              Ember.$("body > *").text("");
              localStorage.setItem("userlogin", user);
              localStorage.setItem("pwdlogin", pass); //window.location.href =  //document.location.href.split("#")[0] + window.constant.APPURL;

              window.location.reload();
            } else {
              Ember.$("#errorLogin").removeClass("d-none").fadeIn(1500).fadeOut(6000);
            }
          },
          error: function error(e) {
            Ember.$('#modal-avisos .modal-header').text('Error');
            Ember.$('#modal-avisos .modal-body').text("No se ha podido establecer la conexión por favor intenta mas tarde");
            Ember.$('#modal-avisos').modal('show');
            Ember.$("#loginRequest").show();
            console.error(e);
          }
        });
      },
      registro: function registro() {
        document.location = document.location.href.split("#")[0] + "#/registro";
        setTimeout(function () {
          window.location.reload();
        }, 200);
      },
      recuperarContrasena: function recuperarContrasena() {
        Ember.$(".modal").css("background", "var(--main-color)");
        Ember.$("#modal_reset_password").modal("show");
      },
      enviarCorreoResetPassword: function enviarCorreoResetPassword() {
        Ember.$("#modal_reset_password").find(".modal-footer").hide();
        Ember.$.ajax({
          url: window.constant.APIURL + "tarea/requestresetpasswordapp",
          type: "post",
          data: {
            "username": Ember.$("#username").val()
          },
          dataType: "json",
          success: function success(response) {
            if (response.success) {
              Ember.$("#modal_reset_password").modal("hide");
              Ember.$("#modal_reset_password").find(".modal-footer").show();
              Ember.$('#modal-avisos .modal-body').html(response.msg);
              Ember.$('#modal-avisos').modal('show');
            } else {
              Ember.$("#modal_reset_password").find(".modal-footer").show();
              Ember.$('#modal-avisos .modal-body').html(response.msg);
              Ember.$('#modal-avisos').modal('show');
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  });

  _exports.default = _default;
});