define("comunidades-nafin/templates/recurso-no-disponible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QNGXMLW/",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,\"menu-header\"],false],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n\\n\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12 padding_ud_10px padding_lr_10px\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"button\"],[10,\"class\",\"btn btn-light\"],[3,\"action\",[[21,0,[]],\"goNotifications\"]],[8],[1,[26,\"fa-icon\",[\"angle-left\"],[[\"prefix\"],[\"fas\"]]],false],[9],[0,\" Regresar\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12 text-center margin_ud_10px\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"h1\"],[8],[0,\"No pondemos encontrar el recurso que busca.\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"text-center\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[6,\"br\"],[8],[9],[0,\"\\n\\t\\t\\t\\tEl elemento que has seleccionado, ha sido deshabilitado o eliminado por un administrador; por lo que es imposible el acceso.\\n\\t\\t\\t\\t\"],[6,\"br\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[6,\"br\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\\n\\t\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-2\"],[8],[9],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"col-8\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[6,\"img\"],[10,\"class\",\"full_width\"],[11,\"src\",[27,[[20,\"url_imagen\"]]]],[10,\"alt\",\"Recurso no disponible\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"],[1,[20,\"body-footer\"],false],[0,\" \\n\"],[1,[20,\"sub-footer\"],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/recurso-no-disponible.hbs"
    }
  });

  _exports.default = _default;
});