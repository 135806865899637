define("comunidades-nafin/templates/components/nom-035-stps/reportes/charts/simple-pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k0V+14IJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"card\"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"card-header\"],[10,\"id\",\"headingOne\"],[8],[0,\"\\n        \"],[6,\"h2\"],[10,\"class\",\"mb-0\"],[8],[0,\"\\n            \"],[6,\"button\"],[10,\"class\",\"btn btn-link\"],[10,\"data-toggle\",\"collapse\"],[10,\"data-target\",\"#collapseOne\"],[10,\"aria-expanded\",\"true\"],[10,\"aria-controls\",\"collapseOne\"],[10,\"type\",\"button\"],[8],[0,\"\\n                \"],[1,[20,\"title\"],false],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"id\",\"collapseOne\"],[10,\"class\",\"collapse show\"],[10,\"aria-labelledby\",\"headingOne\"],[10,\"data-parent\",\"#accordionExample\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"card-body\"],[8],[0,\"\\n            \"],[6,\"div\"],[11,\"id\",[27,[[20,\"chartID\"]]]],[10,\"style\",\"width: 100%; height: 500px;\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/nom-035-stps/reportes/charts/simple-pie-chart.hbs"
    }
  });

  _exports.default = _default;
});