define("comunidades-nafin/components/nom-035-stps/reportes/reports/audiencia-resumen-por-trabajador-reporte", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Estatus de los cuestionarios por trabajador',
    clase: 'nom035stps/reporteadministrador',
    metodo: 'audienciaResumenPorTrabajador',
    reporte: 'nom035stps/export/audienciaReporteResumenPorTrabajador',
    headers: [{
      dataIndex: 'datos_trabajador',
      title: 'Datos de Trabajador'
    }, {
      dataIndex: 'centro_trabajo',
      title: 'Centro de Trabajo'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }, {
      dataIndex: 'url_reporte_pdf',
      title: 'Reporte de respuestas'
    }],
    setParamsCentroTrabajoK: function setParamsCentroTrabajoK(centro_trabajo_k) {
      this.params.centro_trabajo_k = centro_trabajo_k;
    },
    setParamsEstatus: function setParamsEstatus(estatus) {
      this.params.estatus = estatus;
    },
    setParamsUsuario: function setParamsUsuario(usuario) {
      this.params.nombre_usuario = usuario;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.loadComboData();
    },
    loadComboData: function loadComboData() {
      var self = this;
      /*
       * Workaround. cargamos dependencias de combo
       */

      $.ajax({
        url: constant.APIURL + 'nom035stps/reporteadministrador/listarcentrosdetrabajo',
        type: "post",
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.success == true) {
            self.set('inputGroupSelectCentrosDeTrabajoData', response.data);
            self.inputGroupSelectCentrosDeTrabajoData = response.data;
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      filterAndReload: function filterAndReload() {
        this.setParamsCentroTrabajoK($('#inputGroupSelectCentroTrabajoK').val());
        this.setParamsEstatus($('#inputGroupSelectEstatus').val());
        this.setParamsUsuario($('#inputGroupTextUsuario').val());
        this.loadData();
      }
    }
  });

  _exports.default = _default;
});