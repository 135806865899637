define("comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/consultas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6mJdukxR",
    "block": "{\"symbols\":[],\"statements\":[[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/detail-ticket.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/cms.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/consultas.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/css/modal.css\"],[10,\"type\",\"text/css\"],[8],[9],[0,\"\\n\"],[6,\"link\"],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"https://use.fontawesome.com/releases/v5.2.0/css/all.css\"],[10,\"integrity\",\"sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ\"],[10,\"crossorigin\",\"anonymous\"],[8],[9],[0,\"\\n\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"alert alert-info\"],[10,\"role\",\"alert\"],[8],[0,\"\\n            \"],[1,[26,\"translate\",[\"nom_035_stps.carpeta_evidencias.msg.msg_descripcion_consultas.valor\",\"En esta sección podrás consultar y descargar el detalle de las consultas generadas.\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"accordion\"],[8],[0,\"\\n    \"],[1,[20,\"nom-035-stps/reportes/reports/carpeta-evidencias-consultas\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row mb-2\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/acontecimientos-traumaticos-severos/administrador/evidencias/consultas.hbs"
    }
  });

  _exports.default = _default;
});